<template>
  <div class="card container py-4">
    <!-- Heading -->
    <div class="text-center mb-4">
      <h1 class="display-5 fw-bold">Latest Updates & News</h1>
    </div>

    <!-- Content Row -->
    <div class="row align-items-stretch">
      <!-- YouTube Livestream Embed -->
      <div class="col-lg-6 mb-4 d-flex">
        <iframe
            class="video"
            src="https://www.youtube.com/embed/wxryQn8AtuI"
            title="YouTube livestream"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
      </div>

      <!-- Social Media Buttons -->
      <div class="col-lg-6 mb-4 d-flex flex-column justify-content-center">
        <h3 class="text-center mb-3">Follow Us</h3>
        <ul class="social-list">
          <li>
            <a href="https://www.youtube.com/@b-bot-official" target="_blank" class="btn youtube-btn">
              <i class="fab fa-youtube"></i> YouTube
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/bbot.official/" target="_blank" class="btn instagram-btn">
              <i class="fab fa-instagram"></i> Instagram
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@bbot_official" target="_blank" class="btn tiktok-btn">
              <i class="fab fa-tiktok"></i> TikTok
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/YOUR_PROFILE" target="_blank" class="btn linkedin-btn">
              <i class="fab fa-linkedin"></i> LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsSection",
};
</script>

<style scoped>
/* Section Styling */

h1 {
  color: #333;
}

/* Video Styling */
.video {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: 1rem;
}

/* Social Media Button Styling */
.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.social-list li {
  margin: 8px 0;
  text-align: center;
}

.btn {
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  border-radius: 1rem;
  font-weight: bold;
  color: white;
  transition: all 0.3s ease;
}

.youtube-btn {
  background: #ff0000;
}
.youtube-btn:hover {
  background: #b30000;
}

.instagram-btn {
  background: #e1306c;
}
.instagram-btn:hover {
  background: #c82355;
}

.tiktok-btn {
  background: #000000;
}
.tiktok-btn:hover {
  background: #333;
}

.linkedin-btn {
  background: #0077b5;
}
.linkedin-btn:hover {
  background: #005f9e;
}

.fab {
  font-size: 1.5rem;
}
</style>

import { TrainingSessionRepository } from "@/classes/expert/expert_llm/training_session/TrainingSessionRepository";
import store from "@/store";

export default class TrainingSessionService {
    // Fetch all abilities
    static async getAllTrainingSessions() {
        return await TrainingSessionRepository.fetchTrainingSessions();
    }

    // Save (create or update) an ability
    static async saveTrainingSession(trainingSession) {
        console.log("SAVE ABILITY: ", trainingSession);
        if(!trainingSession.id){
            const newTrainingSession = await TrainingSessionRepository.createTrainingSession(trainingSession);
            console.log("CREATE TRAINING SESSION: ",newTrainingSession);
            return await store.dispatch('training/addTrainingSession', newTrainingSession);
        } else {
            const newTrainingSession = await TrainingSessionRepository.updateTrainingSession(trainingSession);
            console.log("UPDATE TRAINING SESSION: ",newTrainingSession);

            return await store.dispatch('training/updateTrainingSession', newTrainingSession);

        }
    }

    // Delete an ability by ID
    static async deleteTrainingSession(id) {
        return await TrainingSessionRepository.deleteTrainingSession(id);
    }

    // Update ability attributes
    static async updateTrainingSessionAttributes(ability) {
        return await TrainingSessionRepository.updateTrainingSession(ability);
    }

    // Fetch a specific ability by ID
    static async getTrainingSessionById(id) {
        return await TrainingSessionRepository.fetchTrainingSessionsById(id);
    }
}

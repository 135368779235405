<template>
  <div class="mb-5">
    <p class="text-center">Manage Expert Team Members</p>

    <div class="form-group">
      <label for="teamMembers">Available Experts</label>
      <div class="row">
        <div v-for="expert in availableExperts" :key="expert.id" class="col-md-4 mb-3">
          <div :class="'card shadow-sm'">
            <div class="card-body text-center">
              <img :src="expert.attributes.profile_picture?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                   class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;"/>
              <h5 class="card-title">{{ expert.attributes.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
            </div>
            <button @click="addExpertToTeam(expert)" class="btn btn-primary w-100 m-0">Add to Team</button>
          </div>
        </div>
      </div>
    </div>
    <h3>Current Team</h3>
    <div v-if="expertTeam" class="row mb-5">
      <div v-for="(expert, index) in expertTeam" :key="expert.id" class="col-md-4 mb-3">
        <div :class="'card shadow-sm'">
          <div class="card-body text-center">
            <img :src="expert.attributes?.profile_picture?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                 class="rounded-circle mb-3" :alt="expert.attributes?.name" style="width: 100px; height: 100px;"/>
            <h5 class="card-title">{{ expert.attributes?.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes?.profession }}</h6>
          </div>
          <button @click="removeTeamMember(index)" class="btn btn-danger w-100 m-0">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    experts: {
      type: Array,
      default: () => [], // Default value should be an empty array
    },
    expertTeam: {
      type: Array,
      default: () => [], // Default value should be an empty array
    },
  },
  computed: {
    availableExperts() {
      // Ensure expertTeam is an array
      if (!Array.isArray(this.expertTeam)) {
        console.warn('expertTeam is not an array:', this.expertTeam);
        return this.experts; // Return experts as fallback
      }
      return this.experts.filter(expert =>
          !this.expertTeam.some(teamMember => teamMember.id === expert.id)
      );
    },
  },
  methods: {
    addExpertToTeam(expert) {
      // Check if expert is valid and not already in the team
      if (expert && Array.isArray(this.expertTeam) && !this.expertTeam.some(teamMember => teamMember.id === expert.id)) {
        this.$emit('update:expertTeam', [...this.expertTeam, expert]);
      }
    },
    removeTeamMember(index) {
      if (Array.isArray(this.expertTeam)) {
        const newTeam = [...this.expertTeam];
        newTeam.splice(index, 1);
        this.$emit('update:expertTeam', newTeam);
      } else {
        console.warn('expertTeam is not an array:', this.expertTeam);
      }
    },
  },
};
</script>

<style scoped>
.expert-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
</style>

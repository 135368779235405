<template>
  <navbar btnBackground="bg-gradient-success" />
  <Renderer class="renderer-container" ref="renderer" style="position: fixed; width: 100vw; height: 100vh; z-index: 1" antialias resize shadow>
    <Camera :position="{ z: 10 }" />
    <Scene :background="backgroundColor">
      <PointLight :color="colorLight" :position="{ y: 50, z: 50 }" />
      <PointLight :color="colorLight" :intensity="1.5" :position="{ x: 0, y: 0, z: -50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: 0, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: -50, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: -25, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: 25, z: 50 }"/>
      <GltfModel
          ref="model"
          src="https://cdn.shopify.com/3d/models/5fe48776ceb5844d/logo.glb"
      >
      </GltfModel>
    </Scene>
    <EffectComposer>
      <RenderPass />
      <FXAAPass />
      <TiltShiftPass :gradient-radius="100" :start="{ x: 0, y: 20 }" :end="{ x: 100, y: 50 }" />
    </EffectComposer>
  </Renderer>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://cdn.shopify.com/s/files/1/0795/1653/3018/files/Your_B-Bot_Me.png?v=1704915784');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                  :class="'bg-gradient-' + color + ' shadow-' + color"
                class="border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Log in
                </h4>
                <p class="w-100 text-white text-center font-weight-bolder text-center mt-2 mb-0">
                  Get into the Magical world of B-Bot!
                </p>
                <div class="row mt-3 d-none">
                  <div class="col-2 text-center ms-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-facebook text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center px-1">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-github text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-google text-white text-lg"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <material-button
                  @click="loginWithAuth0"
                  class="my-4 mb-2"
                  variant="gradient"
                  :color="color"
                  fullWidth
              >
                Login
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}, made with
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a
                href="https://b-bot.ch/team"
                class="font-weight-bold text-white"
                target="_blank"
                >B-Bot Team</a
              >
              for Sustainable AI.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://b-bot.ch"
                  class="nav-link text-white"
                  target="_blank"
                  >Shop</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/about"
                  class="nav-link text-white"
                  target="_blank"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/blog"
                  class="nav-link text-white"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/impressum"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Impressum</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import {mapMutations, mapState} from "vuex";
import {authService} from "@/auth/authService";
import {throttle} from "lodash";
import {activateDarkMode, deactivateDarkMode} from "@/assets/js/dark-mode";

export default {
  name: "sign-in",
  components: {
    Navbar,
    MaterialButton,
  },
  data() {
    return {
      tiltRadius: 10000,
      tiltY: 100,
      colorLight: "#ffffff",
      backgroundColor: "#ffffff",
      isScrollListenerAdded: false,
    };
  },
  computed: {
    ...mapState([
      "isDarkMode",
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "isBackgroundLoading"
    ]),
  },
  beforeMount() {
    this.hideEveryDisplay();
    this.hideConfig();
  },
  beforeUnmount() {
    this.hideEveryDisplay();
    this.hideConfig();
    //this.toggleEveryDisplay();
    //this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["hideEveryDisplay", "hideConfig"]),
    async loginWithAuth0() {
      await authService.login();
    },
    handleScroll(model) {
      if (model) {
        const mainElement = document.querySelector('main');
        const scrollY = mainElement.scrollTop;
        const rotationSpeed = 0.002;
        model.o3d.rotation.x = scrollY * rotationSpeed;
      }
    },
    onWindowResize() {
      this.setRendererSize();
    },
    setRendererSize() {
      const renderer = this.$refs.renderer;
      if (renderer) {
        renderer.three.setSize(window.innerWidth, window.innerHeight);
        // Removed rendererElement as it is not used
      }
    }
  },
  mounted() {
    console.log("I AM HERE!")
    this.hideEveryDisplay();
    this.hideConfig();
    this.$store.state.showNavbar = false;
    const model = this.$refs.model;

    const renderer = this.$refs.renderer;
    const rendererElement = renderer.$el || renderer;
    if (rendererElement) {
      rendererElement.previousElementSibling.style.position = 'fixed';
      rendererElement.previousElementSibling.style.zIndex = "1";
      renderer.three.setSize(window.innerWidth, window.innerHeight);
    }
    console.log("rendererElement ",rendererElement);
    model.position.z = 0;
    model.position.y = 0;
    model.position.x = 0;
    model.rotation.y = 30;


    this.handleScrollThrottled = throttle(this.handleScroll, 27);

    renderer.onBeforeRender(() => {
      if (model) {
        model.rotation.x += 15; // Adjust the value for desired spin speed (smaller values = slower spin)

        if (model && !this.isScrollListenerAdded) { // Only add if not already added

        }
        // Remove existing event listener before adding a new one


        if (this.isDarkMode) {
          this.backgroundColor = "#000000";
          if (this.color === "dark") {
            this.colorLight = "#1b1b1b";
          } else if(this.color==="primary"){
            this.colorLight = "#220027";

          }else if(this.color==="info"){
            this.colorLight = "#000822";

          }else if(this.color==="success"){
            this.colorLight = "#062e00";

          }else if(this.color==="warning"){
            this.colorLight = "#3e3300";

          } else {
            this.colorLight = "#250000";
          }

        } else {
          this.backgroundColor = "#ffffff";
          if (this.color === "dark") {
            this.colorLight = "#e6e6e6";
          } else if(this.color==="primary"){
            this.colorLight = "#cbbfcd";

          }else if(this.color==="info"){
            this.colorLight = "#b8bbc6";

          }else if(this.color==="success"){
            this.colorLight = "#b9c8b7";

          }else if(this.color==="warning"){
            this.colorLight = "#c5c2b8";

          } else {
            this.colorLight = "#cab9b9";
          }
        }
        //mainElement.removeEventListener('scroll', this.handleScrollThrottled);

        this.isScrollListenerAdded = true; // Mark listener as added


      }

    });
    if (this.isDarkMode) {
      activateDarkMode();
    } else {
      deactivateDarkMode();
    }
  }

};
</script>
<style scoped>

.renderer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Ensure it's behind other content */
}
</style>

import store from "@/store";
import AbilityService from "@/classes/services/AbilityService";
import ExpertService from "@/classes/services/ExpertService";
import TemplateService from "@/classes/services/TemplateService";
import ExpertLLMService from "@/classes/services/ExpertLLMService";

export class Expert {
    constructor({id, attributes}) {
        this.id = id;
        this.attributes = attributes;
    }

    async clone() {
        return new Expert({
            id: undefined, // Cloned expert gets a new ID
            attributes: {...this.attributes},
        });
    }

    update({ attributes }) {
        console.log("attributes: ", attributes);
        if (attributes) {
            this.attributes = attributes;
        } else {
            console.error("Invalid attributes passed to update:", attributes);
        }
    }

    async save() {
        if (this.id) {
            return await ExpertService.saveExpert(this);
        } else {
            return await ExpertService.saveExpert(this);
        }
    }

    async addAbility(ability) {
        try {
            console.log("AbilityMODEL ADD ABILIITY BEFORE", ability)
            const abilityModel = await AbilityService.saveAbility(ability);
            console.log("AbilityMODEL ADD ABILIITY", abilityModel)
            return await store.dispatch('experts/addAbility', abilityModel);
        } catch (error) {
            console.error('Error adding ability:', error);
            throw error;
        }
    }

    async removeAbility(ability) {
        await AbilityService.deleteAbility(ability.id);
        await store.dispatch('experts/removeAbility', ability);

    }

    async addTemplate(template) {
        try {
            const templateModel = await TemplateService.saveTemplate(template);
            return await store.dispatch('experts/addTemplate', templateModel);
        } catch (error) {
            console.error('Error adding ability:', error);
            throw error;
        }
    }


    async removeTemplate(template) {
        await TemplateService.deleteTemplate(template.id);
        await store.dispatch('experts/removeTemplate', template);
    }

    async addProfilePicture(file) {
        const pictureModel = await ExpertService.addProfilePicture(this, file)
        console.log("PROFILE PICTURE IN MODEL", pictureModel)
        return await store.dispatch('experts/addProfilePicture', pictureModel);

    }

    async addModel(model){
        const newModel = await ExpertLLMService.saveExpertLLM(model);
        console.log("This is the models before we updated them", this.attributes.expert_llm_models);
        console.log("GET THE NEW MODEL IN ADD MODEL", newModel)
        this.attributes.expert_llm_models.push(newModel)
        let models = this.attributes.expert_llm_models;
        console.log("THIS is the models after we push the model to the models", models)
        this.update({
            attributes: {
                ...this.attributes,
                expert_llm_models: models,
            },
        })
        console.log("This is the models after we updated them", this.attributes.expert_llm_models);

        await this.save();
        console.log("This is the models before we saved them", this.attributes.expert_llm_models);

    }
}
import ExpertService from '@/classes/services/ExpertService';
import AbilityService from '@/classes/services/AbilityService';
import TemplateService from "@/classes/services/TemplateService";
import OpenAI from "@/assets/img/logos/OpenAI.png";
import ExpertLLMService from "@/classes/services/ExpertLLMService";

export default {
    namespaced: true,
    state: {
        experts: [],
        selectedExpert: null,
        selectedAbility: null,
        selectedModel: null,
        selectedTemplateText: '',
        models: [],
        selectedModelForAgentCreation: {
            src: OpenAI,
            alt: "OpenAI logo",
            type: "OpenAI-GPT-3.5-Turbo",
            providers: ["OpenAI", "Azure"],
            selectedProvider: "OpenAI",
            isDisabled: false
        },
        selectedProviderForAgentCreation: 'OpenAI',
    },
    mutations: {
        // Experts
        SET_EXPERTS(state, experts) {
            console.log('SET_EXPERTS', experts);
            state.experts = experts;
        },
        SET_SELECTED_EXPERT(state, expert) {
            state.selectedExpert = expert;
            console.log('SET_SELECTED_EXPERT', expert);
            console.log("get Models of Expert", expert?.attributes?.expert_llm_models?.slice(-1)[0])
            state.selectedModel = expert?.attributes?.expert_llm_models?.slice(-1)[0]
        },
        ADD_EXPERT(state, expert) {
            console.log("state.experts type:", typeof state.experts);
            console.log("state.experts value:", state.experts);

            // Ensure it's an array
            if (!Array.isArray(state.experts)) {
                state.experts = [];
            }
            state.experts.push(expert);
        },
        REMOVE_EXPERT(state, expertId) {
            state.experts = state.experts.filter(expert => expert.id !== expertId);
        },
        UPDATE_EXPERT(state, updatedExpert) {
            console.log("state.experts: ", state.experts);
            console.log("updatedExpert: ", updatedExpert);
            console.log("selectedExpert: ", state.selectedExpert);

            const index = state.experts.findIndex(expert => expert.id === updatedExpert.id);

            if (index !== -1) {
                state.experts.splice(index, 1, updatedExpert);
            }

            // Check if the selectedExpert id matches the updatedExpert id, and update it if needed
            if (state.selectedExpert && state.selectedExpert.id === updatedExpert.id) {
                state.selectedExpert = updatedExpert;
            }
        },
        // Abilities
        SET_SELECTED_ABILITY(state, ability) {
            state.selectedAbility = ability;
        },
        ADD_ABILITY(state, ability) {
            if (state.selectedExpert.attributes.abilities) {
                state.selectedExpert.attributes.abilities.push(ability);
            }
        },
        UPDATE_ABILITY(state, updatedAbility) {
            const abilities = state.selectedExpert?.attributes.abilities || [];
            const index = abilities.findIndex(ability => ability.id === updatedAbility.id);
            if (index !== -1) {
                abilities.splice(index, 1, updatedAbility);
            }
        },
        SET_EXPERT_ABILITIES(state, abilities) {
            state.selectedExpert.attributes.abilities = abilities;
        },
        REMOVE_ABILITY(state, abilityId) {
            if (state.selectedExpert?.attributes.abilities) {
                state.selectedExpert.attributes.abilities = state.selectedExpert.attributes.abilities.filter(
                    ability => ability.id !== abilityId
                );
            }
        },
        // Templates
        ADD_TEMPLATE(state, template) {
            console.log("Add template see if it is working: ", state.selectedExpert)
            if (state.selectedExpert.attributes.templates) {
                console.log("we arr adding the template", template, "to: ", state.selectedExpert.attributes.templates)
                state.selectedExpert.attributes.templates.push(template);
            }
            console.log("Add ability see if it is working after: ", state.selectedExpert)

        },
        UPDATE_TEMPLATE(state, updatedTemplate) {
            const templates = state.selectedExpert?.attributes.templates || [];
            const index = templates.findIndex(ability => ability.id === updatedTemplate.id);
            if (index !== -1) {
                templates.splice(index, 1, updatedTemplate);
            }
        },
        REMOVE_TEMPLATE(state, templateId) {
            if (state.selectedExpert?.attributes.templates) {
                state.selectedExpert.attributes.templates = state.selectedExpert.attributes.templates.filter(
                    template => template.id !== templateId
                );
            }
        },
        ADD_PROFILE_PICTURE(state, picture) {
            state.selectedExpert.attributes.profile_picture= picture;
        },
        SET_MODEL_FOR_AGENT_CREATION(state, model) {
            state.selectedModelForAgentCreation = model;
        },
        SET_PROVIDER_FOR_AGENT_CREATION(state, provider) {
            state.selectedProviderForAgentCreation = provider;
        },
        SET_SELECTED_MODEL(state, model) {
            console.log("setSelectedModel", model)
            state.selectedModel = model;
        },
        SET_MODELS(state, models) {
            state.models = models;
        },
        ADD_MODEL(state, model){
            state.models.push(model)
        }
    },
    actions: {
        // Expert Management
        async fetchExperts({ commit }) {
            try {
                const experts = await ExpertService.getAllExperts();
                console.log("API response experts:", experts);
                // Ensure experts is an array
                commit('SET_EXPERTS', Array.isArray(experts) ? experts : []);
            } catch (error) {
                console.error('Error fetching experts:', error);
                commit('SET_EXPERTS', []); // Initialize as empty array on error

            }
        },
        async createExpert({ commit }, expert) {
            try {
                await commit('ADD_EXPERT', expert);
                await commit('SET_SELECTED_EXPERT', expert);
                return expert;
            } catch (error) {
                console.error('Error creating expert:', error);
                throw error;
            }
        },
        async updateExpert({ commit }, expert) {
            try {
                commit('UPDATE_EXPERT', expert);
                return expert;
            } catch (error) {
                console.error('Error updating expert:', error);
                throw error;
            }
        },
        async deleteExpert({ commit }, expertId) {
            try {
                commit('REMOVE_EXPERT', expertId);
            } catch (error) {
                console.error('Error deleting expert:', error);
                throw error;
            }
        },

        // Ability Management for Selected Expert
        async fetchAbilities({ commit, state }) {
            try {
                if (!state.selectedExpert) return;
                const abilities = await AbilityService.getAllAbilities();
                const expertAbilities = abilities.filter(
                    ability => ability.expertId === state.selectedExpert.id
                );
                commit('SET_EXPERT_ABILITIES', expertAbilities);
            } catch (error) {
                console.error('Error fetching abilities:', error);
            }
        },
        async addAbility({ commit, state }, abilityModel) {
            try {
                if (!state.selectedExpert) return;
                commit('ADD_ABILITY', abilityModel);
                return abilityModel;
            } catch (error) {
                console.error('Error adding ability:', error);
                throw error;
            }
        },
        async updateAbility({ commit }, ability) {
            try {
                commit('UPDATE_ABILITY', ability);
                return ability;
            } catch (error) {
                console.error('Error updating ability:', error);
                throw error;
            }
        },
        async removeAbility({ commit }, ability) {
            try {
                await AbilityService.deleteAbility(ability.id);
                commit('REMOVE_ABILITY', ability.id);
            } catch (error) {
                console.error('Error deleting ability:', error);
                throw error;
            }
        },
        async cloneAbility({ commit, state }, abilityId) {
            try {
                const ability = state.selectedExpert?.abilities?.find(ab => ab.id === abilityId);
                if (!ability) throw new Error('Ability not found');
                const clonedAbility = await AbilityService.cloneAbility(ability);
                commit('ADD_ABILITY', clonedAbility);
                return clonedAbility;
            } catch (error) {
                console.error('Error cloning ability:', error);
                throw error;
            }
        },
        async addTemplate({ commit, state }, templateModel) {
            try {
                if (!state.selectedExpert) return;
                commit('ADD_TEMPLATE', templateModel);
                return templateModel;
            } catch (error) {
                console.error('Error adding template:', error);
                throw error;
            }
        },
        async updateTemplate({ commit }, template) {
            try {
                commit('UPDATE_TEMPLATE', template);
                return template;
            } catch (error) {
                console.error('Error updating template:', error);
                throw error;
            }
        },
        async removeTemplate({ commit }, template) {
            try {
                await TemplateService.deleteTemplate(template.id);
                commit('REMOVE_TEMPLATE', template.id);
            } catch (error) {
                console.error('Error deleting ability:', error);
                throw error;
            }
        },
        async cloneTemplate({ commit, state }, templateId) {
            try {
                const template = state.selectedExpert?.templates?.find(ab => ab.id === templateId);
                if (!template) throw new Error('Template not found');
                const clonedTemplate = await TemplateService.cloneTemplate(template);
                commit('ADD_TEMPLATE', clonedTemplate);
                return clonedTemplate;
            } catch (error) {
                console.error('Error cloning template:', error);
                throw error;
            }
        },
        async addProfilePicture({commit},picture){
            console.log("we are adding this profile picture", picture)
            commit('ADD_PROFILE_PICTURE', picture);
        },
        // Setters
        async setSelectedExpert({ commit }, expert) {
            console.log("WE SET THE SELECTED EXPERT", expert)
            await commit('SET_SELECTED_EXPERT', expert);
            //await dispatch('fetchAbilities');
        },
        async setSelectedAbility({ commit }, ability) {
            commit('SET_SELECTED_ABILITY', ability);
        },
        async setProviderForAgentCreation({ commit }, provider) {
            commit('SET_PROVIDER_FOR_AGENT_CREATION', provider);
        },
        async setModelForAgentCreation({ commit }, model) {
            console.log("SET_MODEL_FOR_AGENT_CREATION", model)
            commit('SET_MODEL_FOR_AGENT_CREATION', model);
        },
        async setSelectedModel({ commit }, model){
            commit('SET_SELECTED_MODEL', model);
        },
        async fetchModels({ commit }) {
            try {
                const llms = await ExpertLLMService.getAllExpertLLMs()
                // Store only the first author, if exists
                if (llms.length > 0) {
                    commit('SET_MODELS', llms);
                }

            } catch (error) {
                console.error('Fetching the author failed:', error);
            }
        },
        async addModel({commit}, model){
            commit('ADD_MODEL', model);
        }
    },
    getters: {
        experts(state) {
            return state.experts;
        },
        selectedExpert(state) {
            return state.selectedExpert;
        },
        abilities(state) {
            return state.selectedExpert?.abilities || [];
        },
        selectedAbility(state) {
            return state.selectedAbility;
        },
    },
};

import {DatacenterService} from '@/classes/services/DatacenterService';
//import store from "@/store";
import {AbilityFactory} from "@/classes/expert/ability/AbilityFactory";
import {ExpertFactory} from "@/classes/expert/ExpertFactory";
import {TemplateFactory} from "@/classes/expert/template/TemplateFactory";
import {PictureFactory} from "@/classes/expert/profile_picture/PictureFactory";
import {ExpertLLMFactory} from "@/classes/expert/expert_llm/ExpertLLMFactory";

export const datacenterService = new DatacenterService();

export class ExpertRepository {
    static async fetchExperts() {
        try {
            // Fetch all experts with populated data
            const rawExperts = await datacenterService.fetchObjects(
                'experts',
                `?populate=name,description,function_description,function_name,system_message,profession,createdAt,publishedAt,updatedAt,profile_picture,abilities,templates,expert_llm_models,expert_llm_models.training_sessions,expert_llm_models.previous_models,experts,apps,training_sessions,locale,localizations,&publicationState=preview`
            );

            //const user = store.state.user;

            // Process experts concurrently
            const experts = rawExperts.data.map(async (rawExpert) => {
                //const userId = `${user.sub}_exp_${rawExpert.id}`;

                // Fetch apps for the expert
                //const composioApps = await this.fetchAppsForExpert(userId);

                // Map abilities in a separate step to avoid unnecessary async calls in map
                const abilities = rawExpert.attributes.abilities.data.map(
                    (ability) => AbilityFactory.createAbility(ability)
                );

                const expert_llm_models = rawExpert.attributes.expert_llm_models.data.map(
                    (model) => ExpertLLMFactory.createExpertLLM(model)
                );

                const experts = rawExpert.attributes.experts.data.map(
                    (expert) => ExpertFactory.createExpert(expert)
                );
                // Map templates in a separate step to avoid unnecessary async calls in map
                const templates = rawExpert.attributes.templates.data.map(
                    (template) => TemplateFactory.createTemplate(template)
                );
                if(rawExpert.attributes.profile_picture.data != null) {
                    const picture = PictureFactory.createPicture(rawExpert?.attributes?.profile_picture?.data)
                    return ExpertFactory.createExpert({
                        id: rawExpert.id,
                        attributes: {
                            ...rawExpert.attributes,
                            abilities,
                            templates,
                            expert_llm_models,
                            experts: experts || [],
                            profile_picture: picture
                            //apps: this.mergeApps(rawExpert.attributes.apps, composioApps),
                        },
                    })
                } else {
                    return ExpertFactory.createExpert({
                        id: rawExpert.id,
                        attributes: {
                            ...rawExpert.attributes,
                            abilities,
                            templates,
                            expert_llm_models,
                            experts: experts || [],
                            //apps: this.mergeApps(rawExpert.attributes.apps, composioApps),
                        },
                    })
                }


            });

            // Resolve all promises in parallel
            return await Promise.all(experts);
        } catch (error) {
            console.error('Error fetching experts:', error);
            return { success: false, error: error.message };
        }
    }

    static async fetchAppsForExpert(userId, retries = 3) {
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                const response = await fetch(`${process.env.VUE_APP_MAIN_API_URL}/api/v0/entity_apps/${userId}`);
                if (!response.ok) throw new Error(`Error fetching apps: ${response.statusText}`);
                const appsData = await response.json();
                return appsData.apps || [];
            } catch (error) {
                console.warn(`Attempt ${attempt} failed for fetching apps for userId ${userId}.`);
                if (attempt === retries) return [];
            }
        }
    }

    static mergeApps(existingApps = {}, composioApps = []) {
        const composioAppsObject = composioApps.reduce((acc, app) => {
            acc[app.name] = {
                private: app.private || false,
                tool_type: app.tool_type,
                name: app.name,
                description: app.description,
                id: app.name,
            };
            return acc;
        }, {});

        return { ...existingApps, ...composioAppsObject };
    }

    static async fetchExpertById(id) {
        try {
            // Fetch single expert data with populated fields
            const rawExpert = await datacenterService.fetchObjects('experts', `/${id}/?populate=*`);
            console.log("fetchingSingleExpertByID", rawExpert);

            const attributes = rawExpert;

            // Map related objects to their respective factories
            const abilities = attributes?.abilities?.map((ability) => AbilityFactory.createAbility(ability)) || [];
            const expert_llm_models = attributes?.expert_llm_models?.map((model) => ExpertLLMFactory.createExpertLLM(model)) || [];
            console.log("GET ME THE MODEL ATTRIBUTES TO SEE", expert_llm_models)
            const relatedExperts = attributes?.experts?.map((expert) => ExpertFactory.createExpert(expert)) || [];
            const templates = attributes?.templates?.map((template) => TemplateFactory.createTemplate(template)) || [];
            const profilePicture = attributes?.profile_picture
                ? PictureFactory.createPicture(attributes.profile_picture)
                : null;

            // Create and return the expert object
            return await ExpertFactory.createExpert({
                id: rawExpert.id,
                attributes: {
                    ...attributes,
                    abilities,
                    templates,
                    expert_llm_models,
                    experts: relatedExperts,
                    profile_picture: profilePicture,
                },
            });
        } catch (error) {
            console.error('Error fetching expert:', error);
            return { success: false, error: error.message };
        }
    }

    static async saveExpert(expertData) {
        try {
            console.log("SaveExpert", expertData)
            if (expertData.id) {

                // Update existing expert using DatacenterService
                const expert = await datacenterService.updateObject('experts', expertData.id + "?populate=name,description,function_description,function_name,system_message,profession,createdAt,publishedAt,updatedAt,profile_picture,abilities,templates,expert_llm_models,experts,apps,training_sessions,locale,localizations,&publicationState=preview", { attributes: expertData.attributes });
                console.log("Pass to the Factory", expert)
                const expertRaw = expert.data;
                const attributes = expertRaw.attributes;
                console.log("Pass attributes", attributes)
                console.log("Pass to the Factory", attributes)

                // Map related objects to their respective factories
                const abilities = attributes?.abilities?.data?.map((ability) => AbilityFactory.createAbility(ability)) || [];
                const expert_llm_models = attributes?.expert_llm_models?.data?.map((model) => ExpertLLMFactory.createExpertLLM(model)) || [];
                console.log("GET ME THE MODEL ATTRIBUTES TO SEE", expert_llm_models)
                const relatedExperts = attributes?.experts?.data?.map((expert) => ExpertFactory.createExpert(expert)) || [];
                const templates = attributes?.templates?.data?.map((template) => TemplateFactory.createTemplate(template)) || [];
                const profilePicture = attributes?.profile_picture
                    ? PictureFactory.createPicture(attributes.profile_picture)
                    : null;


                return await ExpertFactory.createExpert({
                    id: expertRaw.id,
                    attributes: {
                        ...attributes,
                        abilities,
                        templates,
                        expert_llm_models,
                        experts: relatedExperts,
                        profile_picture: profilePicture,
                    },
                })
            } else {
                // Create new expert using DatacenterService
                console.log("LOG BEFORE CREATION EXPERT", expertData);

                const expert = await datacenterService.createObject('experts', expertData);
                const structure = {id: expert.id, attributes: expert};
                return ExpertFactory.createExpert(structure);
            }
        } catch (error) {
            console.error('Error saving expert:', error);
            return { success: false, error: error.message };
        }
    }

    static async createExpert(expertData) {
        const expert = await datacenterService.updateObject('experts', expertData.id, { attributes: expertData });
        return ExpertFactory.createExpert(expert)
    }

    static async updateExpert(id, updatedAttributes) {
        const expert = await datacenterService.updateObject('experts', id, { attributes: updatedAttributes });
        return ExpertFactory.createExpert(expert)
    }

    static async deleteExpert(id) {
        try {
            const response = await datacenterService.deleteObject('experts', id);
            return { success: true, data: response };

        } catch (error) {
            console.error('Error deleting expert:', error);
            return { success: false, error: error.message };
        }
    }

    static async cloneExpert(id) {
        try {
            const expert = await this.fetchExpertById(id);
            if (expert.success) {
                return await expert.clone();
            } else {
                return expert;
            }
        } catch (error) {
            console.error('Error cloning expert:', error);
            return { success: false, error: error.message };
        }
    }

    static async addProfilePicture(file, id){
        const pictureData = await datacenterService.uploadFile(file, {
            ref: 'api::expert.expert',
            refId: id,
            field: 'profile_picture',
        });
        console.log("pictureData: ",pictureData)
        const picture = PictureFactory.createPicture({attributes: pictureData[0]});
        console.log("EXPERTREPO; PICTURE",picture);
        return picture;
    }
}

<template>
  <div class="chat-input">
    <material-textarea
        ref="messageInput"
        id="message"
        v-model="message"
        @input="handleInput"
        placeholder="Type a message..."
        name="message"
        class="message-input chat-message-input flex-grow-1"
        @keydown="handleKeydown"
        :disabled="isLoading"
    />
    <div class="align-items-center">
      <div class="col-auto">
        <ChatTopBar
            @toggle-conversations-modal="handleToggleConversationsModal"
            @toggle-abilites-modal="handleToggleAbilitiesModal"
            @toggle-models-modal="handleToggleModelsModal"
            @toggle-settings-modal="handleToggleSettingsModal"
            @toggle-memories-modal="handleToggleMemoriesModal"
            @toggle-connectors-modal="handleToggleConnectorsModal"
            @start-new-chat="startNewChat"
            @toggle-add-file-modal="handleToggleAddFile"
        />
      </div>
      <div class="col-auto">
        <material-button
            class="action-btn w-100 h-50px"
            variant="gradient"
            color="primary"
            size="md"
            @click="sendMessage"
            :disabled="isLoading"
        >
          <i class="fa fa-paper-plane"></i>
        </material-button>
      </div>
    </div>
  </div>
</template>


<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import {mapActions, mapMutations, mapState} from 'vuex';
import ChatTopBar from "@/views/components/ChatTopBar.vue";
const { VUE_APP_MAIN_API_URL } = process.env;
import {MainApiService} from "@/classes/services/MainApiService";
export const mainApiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

export default {
  name: 'ChatInput',
  data() {
    return {
      message: '',
      showConversationsModal: false,
      id: '',
      socket: null, // WebSocket instance
      audioBuffer: [],     // Temporary buffer for audio chunks
      audioPlayer: null,

    };
  },
  components: {
    ChatTopBar,
    MaterialButton,
    MaterialTextarea,
  },
  computed: {
    ...mapState([
      'chatMessages',
      'incomingMessage',
      'statistics',
      'incomingToolEvents',
      'user',
      'isLoading',
      'toolActivation' ,
      'author',
      'chatDocumentUrls',
      'currentChat'
    ]),
    ...mapState('experts', ['experts','selectedExpert', 'selectedAbility', 'selectedModel', 'selectedTemplateText',])
  },
  watch: {
    selectedTemplateText(newVal) {
      this.$refs.messageInput.$el.querySelector('textarea').value = newVal;
    },
  },
  async mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    if (this.selectedExpert.attributes.version === null || this.selectedExpert.attributes.version === "legacy") {
      console.log("We need to update to the newsest version: ",this.selectedExpert.attributes.version)
      const modifiedPayload = {
        name: "Indexer_of_" + this.selectedExpert.id,
        embedding_model: "openai/text-embedding-3-small",
        retriever_provider: "elastic-local",
        url: process.env.VUE_APP_ELASTICSEARCH_URL,
        user_id: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id,
        //user_name: "elastic",
        //user_password: "changeme",
        graph_id: "indexer"
      };
      const responseData = await mainApiService.call(
          "create_memory_assistant",
          "POST",
          modifiedPayload,
      );
      console.log(responseData)
      const modifiedPayload2 = {
        name: "Retriever_of_" + this.selectedExpert.id,
        embedding_model: "openai/text-embedding-3-small",
        retriever_provider: "elastic-local",
        query_model: "openai/gpt-4o-2024-08-06",
        query_system_prompt: "you are a memory assistant, search in the documents for memories",
        response_model: "openai/gpt-4o-2024-08-06",
        response_system_prompt: "you are a memory assistant, search in the documents for memories",
        url: process.env.VUE_APP_ELASTICSEARCH_URL,
        user_id: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id,
        //user_name: process.env.VUE_APP_ELASTICSEARCH_USERNAME,
        //user_password: process.env.VUE_APP_ELASTICSEARCH_PASSWORD,
        graph_id: "retrieval_graph"
      };
      const responseData2 = await mainApiService.call(
          "create_memory_assistant",
          "POST",
          modifiedPayload2,
      );
      const updatedApps = {
        memory: {
          tool_type: "memory",
          indexer: responseData.assistant_id,
          retriever: responseData2.assistant_id,
        }

      }
      console.log(responseData2)
      console.log("this.selectedExpert: ",this.selectedExpert)
      await this.selectedExpert.update(
          {
            attributes: {
              ...this.selectedExpert.attributes,
              version: "synapse",
              apps: {
                ...this.selectedExpert.attributes.apps, // Existing apps
                ...updatedApps, // New or updated apps
              }
            }
          }
      )
      await this.selectedExpert.save();
      console.log("this.selectedExpert after update: ",this.selectedExpert)

    } else {
      console.log("we already updated to the newest version: ",this.selectedExpert.attributes.version)

    }
  },
  methods: {
    ...mapMutations(['setSelectedTemplateText', 'setIncomingMessage', 'pushIncomingToolEvent', 'wipeIncomingToolEvent']),
    ...mapActions(['saveMessage', 'setIsLoading', 'setIsErrorInRequest', 'updateUnsavedChanges', 'updateStatistics', 'createNewChat']),
    ...mapActions('experts', ['setSelectedModel']),
    handleBeforeUnload(e) {
      if (this.$store.state.unsavedChanges) {
        // Most browsers no longer allow custom messages here
        e.preventDefault();
        e.returnValue = '';
      }
    },
    closeWebSocket() {
      if (this.socket) {
        this.socket.close(); // Close the WebSocket connection
        console.log('WebSocket connection closed explicitly');
      }
    },
    async initializeWebSocket() {
      this.socket = new WebSocket(`wss://${VUE_APP_MAIN_API_URL.replace('https://','').replace('http://','')}/api/v0/tool-events`);

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received message:', data); // Log the entire received data for debugging
        this.pushIncomingToolEvent(event.data)
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
        if (event.code !== 1000) { // 1000 indicates a normal closure
          console.error('WebSocket closed unexpectedly:', event);
        };
      };
    },

    handleToolEvent(payload) {
      // Handle the incoming tool event here
      console.log('Tool event received:', payload);
      // You can update your store or UI based on the tool event
    },
    updateUserMetadata(metadataKey, newValue) {
      // Dispatch the Vuex action
      this.$store.dispatch('updateUserMetadata', { metadataKey, newValue });
    },
    handleToggleConversationsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-conversations-modal');
    },
    handleToggleAbilitiesModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-abilites-modal');
    },
    handleToggleModelsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-models-modal');
    },
    handleToggleSettingsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-settings-modal');
    },
    handleToggleMemoriesModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-memories-modal');
    },
    handleToggleConnectorsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-connectors-modal');
    },
    handleToggleAddFile() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-add-file-modal');
    },
    startNewChat(chatname){
      this.$emit('start-new-chat', chatname);
    },
    handleBackButton() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('back-button');
    },
    async sendMessage() {
      //const player = new AudioStreamPlayer();
      console.log("send MESSAGE")

      await this.initializeWebSocket();
      console.log("AFTER ESTABLISH WEBSOCKET CONNECTION")
      const croppedMessage = this.message.slice(0, 50);
      // Start a new chat only if chatMessages array is empty
      if (this.chatMessages.length === 0) {
        console.log("CREATE NEW CHAT")

        await this.createNewChat(croppedMessage);
      }
      console.log("AFTER CREATE NEW CHAT")

      this.$store.dispatch('setIsErrorInRequest', false);
      this.$store.dispatch('setIsLoading', true);
      this.$emit('scroll-down');
      this.updateUnsavedChanges(true); // Dispatch action to Vuex

      const newUserMessage = {
        id: Date.now(), // Simple unique ID for demonstration (consider a more robust method)
        sender: 'user',
        senderId: this.user.sub,
        text: this.message,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }),
      };
      console.log("SAVE MESSAGE BEFORE")
      await this.saveMessage(newUserMessage);
      console.log("SAVE MESSAGE AFTER")

      this.$emit('send-message', newUserMessage);

      const privateSession = this.$store.state.chatSettings.private_session;
      const streaming = this.$store.state.chatSettings.streaming;
      let redisUrl;

      if (privateSession && this.user.apps && this.user.apps.upstash_redis_private) {
        redisUrl = this.user.apps.upstash_redis_private.url;
      } else if (!privateSession && this.selectedExpert && this.selectedExpert.attributes.apps && this.selectedExpert.attributes.apps.upstash_redis_private) {
        redisUrl = this.selectedExpert.attributes.apps.upstash_redis_private.url;
      } else {
        //display the user that this is a public session
        redisUrl = "redis://node136679-b-bot.appengine.flow.ch:11000";
      }
      console.log("this.selectedExpert.attributes.experts: ",this.selectedExpert.attributes.experts)
      let expertIds = [];
      if (this.selectedExpert.attributes.experts) {
        expertIds = this.selectedExpert.attributes.experts.map(expert => expert.id);
      }

      console.log("this.expertIds",expertIds)
      if(this.selectedModel == null){
        console.log("selectedModel was null", this.selectedExpert.attributes.expert_llm_models[0])
        await this.setSelectedModel(this.selectedExpert.attributes.expert_llm_models[0])
      }
      // Filter the team members from the experts
      const filteredExperts = this.experts.filter(expert => expertIds.includes(expert.id));
      console.log("TEAM", filteredExperts)
      console.log("filteredExperts",filteredExperts)
      console.log("CURRENTCHAT: ",this.currentChat)
      const payload = {
        id: this.selectedExpert.id,
        version: this.selectedExpert.attributes.verion,
        s_id: this.user.id,
        user_id: this.user.sub,
        input: this.message,
        provider: this.selectedModel.provider,
        temperature: this.$store.state.chatSettings.temperature,
        top_p: this.$store.state.chatSettings.top_p,
        character_prompt: this.selectedExpert.attributes.system_message,
        ability_prompt: this.selectedAbility && this.selectedAbility.attributes ? this.selectedAbility.attributes.text || '' : '',
        instructions: this.$store.state.chatSettings.instructions,
        //this will be deprecated soon
        model: this.selectedModel.value ? this.selectedModel.value : this.selectedModel.attributes.identifier,
        multiplier: this.selectedModel.multiplier,
        redis_url: redisUrl,
        session_id: "session-" + this.user.sub + this.currentChat,
        conversation_history: [],
        team: filteredExperts.map(expert => ({
          id: expert.id,
          ...expert.attributes
        })),
        apps: privateSession ? this.user.hub_user_metadata.apps : this.selectedExpert.attributes.apps,
        tool_config: {
          tavily_max_results: 5
        },
        tool_activation: this.toolActivation,
        document_urls: this.chatDocumentUrls,
      };


      try {

        const response = await fetch(`${VUE_APP_MAIN_API_URL}/api/v0/${streaming ? "stream" : "invoke" }`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          this.$store.dispatch('setIsLoading', false);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if(!streaming){
          console.log("not streaming session")
          const responseData = await response.json();

          const newAIMessage = {
            id: Date.now(), // Simple unique ID for demonstration (consider a more robust method)
            sender: 'assistant', // Update this as needed, e.g., dynamically determine the sender
            senderId: this.selectedExpert.id,
            text: responseData.output.replaceAll("\\(", "$")//
                .replaceAll("\\)", "$")//
                .replaceAll("\\[", "$$")//
                .replaceAll("\\]", "$$"),
            thoughts: responseData.intermediate_steps,
            timestamp: new Date().toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true
            }),
          };
          this.$emit('scroll-down');
          console.log(newAIMessage)
          await this.saveMessage(newAIMessage);
          // Emit the new message to be displayed
          this.$emit('send-message', newAIMessage);

        }
        else {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();

          let resultText = ''; // Optional, if you want to keep the whole result
          let stream = true;

          while (stream) {
            const { done, value } = await reader.read();
            if (done) break;

            // Decode the chunk and process it in real-time
            const chunk = decoder.decode(value, { stream: true });
            resultText += chunk; // Optional, for collecting the whole response
            // Create and emit new AI message with the current chunk
            this.setIncomingMessage(resultText);

          }
          // Finalize and process the full text
          const thoughts = await this.convertEvents(this.incomingToolEvents);

          if (resultText) {
            const newAIMessage = {
              id: Date.now(),
              sender: 'assistant',
              senderId: this.selectedExpert.id,
              thoughts: thoughts,
              text: resultText,
              timestamp: new Date().toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
              }),
            };
            this.$emit('scroll-down');
            console.log(newAIMessage);
            await this.saveMessage(newAIMessage);
            this.$emit('send-message', newAIMessage);
            this.setIncomingMessage("");
            this.wipeIncomingToolEvent();
          }

        }


        this.$store.dispatch('setIsLoading', false);
        this.message = ''; // Clear message input after sending
        this.$refs.messageInput.resetText(); // Call resetText on MaterialTextarea component
      } catch (error) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setIsErrorInRequest', true);
        console.error("Error sending message:", error);
      }
      this.closeWebSocket()
    },
    base64ToBlob(base64, mimeType) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
      }

      return new Blob(byteArrays, { type: mimeType });
    },
    async convertEvents(events) {
      const result = [];
      const eventPairs = {}; // Temporary storage for pairs by run_id
      console.log("events: ",events)
      for (const event of events) {
        const parsedEvent = JSON.parse(event);
        const runId = parsedEvent.event.run_id;
        let toolCallId = runId;

        // If it's a tool_start event
        if (parsedEvent.event_type === "tool_start") {
          const toolInfo = parsedEvent.tool_info;
          // Initialize a new pair if it doesn't exist
          eventPairs[runId] = eventPairs[runId] || {};

          // Store tool_start details in the pair
          eventPairs[runId].tool_start = {
            tool: toolInfo.name,
            tool_input: toolInfo.inputs,
            run_id: runId,
            log: `Invoking: \`${toolInfo.name}\` with \`${JSON.stringify(toolInfo.inputs)}\``,
            type: "AgentActionMessageLog",
            message_log: [
              {
                content: "",
                additional_kwargs: {
                  tool_calls: [
                    {
                      id: toolCallId,
                      function: {
                        arguments: JSON.stringify(toolInfo.inputs),
                        name: toolInfo.name,
                      },
                      type: "function"
                    }
                  ],
                },
                id: runId,
                example: false,
                tool_calls: [
                  {
                    name: toolInfo.name,
                    args: toolInfo.inputs,
                    id: toolCallId,
                    type: "tool_call"
                  }
                ],
                invalid_tool_calls: []
              }
            ],
            tool_call_id: toolCallId
          };
        }

        // If it's a tool_end event
        else if (parsedEvent.event_type === "tool_end") {
          const outputData = parsedEvent.tool_info.output;
          // Initialize a new pair if it doesn't exist
          eventPairs[runId] = eventPairs[runId] || {};

          // Store tool_end details in the pair
          eventPairs[runId].tool_end = {
            output: outputData,
            run_id: runId,
            tool_call_id: toolCallId
          };
        }
      }

      // Push each paired start and end event into result as a separate entry
      for (const runId in eventPairs) {
        if (eventPairs[runId].tool_start && eventPairs[runId].tool_end) {
          result.push({
            0: eventPairs[runId].tool_start,
            1: eventPairs[runId].tool_end
          });
        }
      }

      return result;
    },
    handleInput(event) {
      if (event.target && typeof event.target.value !== 'undefined') {
        this.message = event.target.value;
      } else {
        this.message = ''; // or any other fallback action
      }
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && event.shiftKey && !this.isLoading) {
        event.preventDefault();
        this.sendMessage();
      }
    },
  },
};
/*
class AudioStreamPlayer {
  constructor() {
    this.mediaSource = new MediaSource();
    this.sourceBuffer = null;
    this.audioElement = new Audio();
    this.audioElement.src = URL.createObjectURL(this.mediaSource);
    this.audioElement.onended = () => {
      console.log("Audio finished.");
    };
    this.chunksQueue = []; // Queue for holding the chunks
    this.isBuffering = false; // Buffer state flag
    this.currentChunk = 0; // Current chunk index

    // Wait for MediaSource to be open
    this.mediaSource.addEventListener("sourceopen", () => {
      this.sourceBuffer = this.mediaSource.addSourceBuffer("audio/mpeg");
      this.sourceBuffer.mode = "sequence"; // Append in sequence
    });
  }

  // Convert base64 audio to ArrayBuffer
  base64ToArrayBuffer(base64) {
    try {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    } catch (error) {
      console.error("Error decoding Base64:", error);
      return null;
    }
  }

  // Method to enqueue audio chunks
  enqueueAudio(base64Audio) {
    const audioData = this.base64ToArrayBuffer(base64Audio);
    if (!audioData) return;

    // Add the chunk to the queue
    this.chunksQueue.push(audioData);

    // If no chunk is playing, start playing the first one
    if (!this.isBuffering && this.chunksQueue.length === 1) {
      this.playNextChunk();
    }
  }

  // Method to play the audio
  play() {
    if (this.chunksQueue.length > 0) {
      // Start playing if there are chunks
      this.audioElement.play();
    } else {
      console.log("No chunks to play.");
    }
  }

  // Method to handle sequential chunk buffering
  playNextChunk() {
    if (this.chunksQueue.length === 0) {
      console.log("No more chunks to play.");
      return;
    }

    // Buffer the current chunk
    this.isBuffering = true;
    const audioData = this.chunksQueue[this.currentChunk];

    // Append the chunk if buffer is not updating
    if (!this.sourceBuffer.updating) {
      this.sourceBuffer.appendBuffer(audioData);
    } else {
      setTimeout(() => {
        this.playNextChunk(); // Retry if the buffer is still updating
      }, 50);
      return;
    }

    // Once the chunk is buffered, play the next chunk when the current one ends
    this.sourceBuffer.addEventListener("updateend", () => {
      this.currentChunk++;
      if (this.currentChunk < this.chunksQueue.length) {
        this.playNextChunk(); // Continue with the next chunk
      } else {
        console.log("All chunks appended.");
        this.isBuffering = false; // No more chunks to buffer
      }
    });
  }
}
*/
</script>

<style scoped>


.chat-input {
  display: flex;
  align-items: start; /* Keep items aligned to the bottom */
  gap: 0; /* Space between elements */
  padding: 0; /* Space inside the border */
  border-radius: 8px; /* Optional: Adds rounded corners */
  min-height: 33px; /* Adjust as needed */
}

.action-btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  position: sticky;
}

.h-50px{
  height: 50px;
}

/* Ensuring the textarea can grow in height while typing */
textarea.form-control {
  font-size: 16px;
  border: none; /* Remove the border */
  height: auto; /* Auto-adjust height */
  overflow-y: hidden; /* Hide vertical scrollbar */
}
.message-input{
  border:none;
}

/* Adjust the send button position */
.material-button {
  white-space: nowrap; /* Ensure button text doesn't wrap */
}

.chat-input .material-textarea textarea.form-control {
  border: none !important; /* Remove the border */
}
#message {
  border: none !important; /* Remove the border */
  border-radius: 0 !important; /* Remove the border radius */
  border-bottom: 1px solid #ccc !important; /* Add a bottom border */
  padding: 10px 0 !important; /* Add padding to the bottom */
  margin-bottom: 0 !important; /* Remove the margin */
  box-shadow: none !important; /* Remove the shadow */
  resize: none !important; /* Disable resizing */
  height: auto !important; /* Auto-adjust height */
  overflow-y: hidden !important; /* Hide vertical scrollbar */
}
</style>

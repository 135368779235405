<template>
  <div v-if="!onlyViewMode" :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-between card-header-top">
    <h5 class="modal-title text-white">
      Select a Model
    </h5>
    <DocumentationLink
        popoverTitle="Model Versions"
        class="z-index-5 documentation-link"
        description="Learn more about the Model Versions of an Expert"
        docLink="https://docs.b-bot.space/essentials/training#choose-the-model"
    />
  </div>
  <div class="container mt-4 mb-5">
    <div class="row justify-content-center mt-3 card">
      <div class="network-graph-container mt-4">
        <v-network-graph
            :nodes="nodes"
            :edges="edges"
            :layouts="layouts"
            :paths="paths"
            :configs="configs"
            :event-handlers="eventHandlers"
            :selected-nodes="selectedNodes"
        />
      </div>
    </div>
    <div v-if="selectedModel" class="model-info row card p-0">
      <div class="card-header">
        <h3>Model Information</h3>
      </div>
      <div class="card-body">
        <p><strong>Name:</strong> {{ selectedModel.attributes.name }}</p>
        <h5>Training Session</h5>
        <p><strong>Name:</strong> {{ selectedModel.attributes.training_sessions?.data[0]?.attributes?.name || "Base Model" }}</p>
        <p><strong>Description:</strong> {{ selectedModel.attributes.training_sessions?.data[0]?.attributes?.description || "Here we go again" }}</p>
        <!-- Add more fields as necessary -->
      </div>

      <button v-if="!onlyViewMode" class="btn btn-primary mb-0" @click="confirmSelection">Select Model</button>
    </div>
  </div>
</template>


<script>
import * as vNG from 'v-network-graph';
import {mapState, mapActions} from 'vuex';
import DocumentationLink from "./DocumentationLink.vue";

export default {
  components: {
    DocumentationLink,
    vNetworkGraph: vNG.VNetworkGraph,
  },
  props: {
    modelValue: Boolean,
    onlyViewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '',
      selectedNodes: this.selectedModel ? [`node${this.selectedModel.id}`] : [],
      selectedModelInfo: null,

      configs: vNG.defineConfigs({
        node: {
          selectable: true,
          normal: {
            type: "circle",
            radius: 20,
            strokeWidth: 0,
            strokeColor: (this.isDarkMode ? "#787878" :"#7c7c7c" ),
            strokeDasharray: "0",
            color: (this.isDarkMode ? "#838383" :"#7e7e7e" ),
          },
          hover: {
            color: (this.isDarkMode ? "#7c7c7c" :"#7c7c7c" ),
          },
          label: {
            visible: true,
            fontSize: 14,
            background: {
              visible: true,
              color: (this.isDarkMode ? "#ffffff" :"#ffffff" ),
              padding: {
                vertical: 1,
                horizontal: 1,
              },
              borderRadius: 4,
            },
          },
        },
        edge: {
          gap: 40,
          normal: {
            color: (this.isDarkMode ? "#878787" :"#8e8e8e" ),
          },
          selected: {
            color: (this.isDarkMode ? "#656565" :"#676767" ), // red color for selected edges
          },
          type: "curve",
        },
        path: {
          visible: false,
          curveInNode: true,
          normal: {
            width: 10,
          },
        },
      }),
      eventHandlers: {
        "node:click": (event) => {
          this.displayModelInfo(event.node);
        },
      }
    };
  },
  mounted() {
    this.updateSelectedNode(this.selectedModel);
  },
  watch: {
    selectedModel(newModel) {
      this.updateSelectedNode(newModel);
    },
  },
  computed: {
    ...mapState(['isDarkMode', 'color']),

    ...mapState('experts',['selectedExpert', 'models', 'selectedModel']),
    nodes() {
      const nodes = {};
      let currentX = 0;  // Horizontal position starter for new strands
      const yIncrement = 100;  // Vertical spacing between models
      const xSpacing = 200;    // Horizontal spacing for split models

      // Get model IDs from selectedExpert
      const expertModelIds = this.selectedExpert.attributes.expert_llm_models.map(model => model.id);
      // Filter models based on selectedExpert
      const expertModels = this.models.filter(model => expertModelIds.includes(model.id));

      // Sort models to ensure proper chaining from base to advanced
      const sortedModels = expertModels.sort((a, b) => {
        const aPrev = a.attributes.previous_models.length;
        const bPrev = b.attributes.previous_models.length;
        return aPrev - bPrev;
      });

      // Create nodes, positioning start nodes in a new line and descendants vertically aligned above their predecessors
      sortedModels.forEach((model) => {
        const nodeId = `node${model.id}`;
        const isSelected = (this.selectedModel && this.selectedModel.id === model.id);

        if (model.attributes.previous_models.length === 0) {
          // This model starts a new line
          nodes[nodeId] = {
            name: model.attributes.name,
            x: currentX,
            y: 0,
            selected: isSelected,
          };

          currentX += xSpacing;  // Increment horizontal space for the next new line
        } else {
          // This model is a descendant and should be placed above its predecessor
          const prevModelId = model.attributes?.previous_models[0]?.id;
          const prevNode = nodes[`node${prevModelId}`];
          let xPos = prevNode.x;
          let yPos = prevNode.y + yIncrement;

          // Check if there is already a node at this Y position to avoid overlap
          Object.values(nodes).forEach(existingNode => {
            if (existingNode.y === yPos && existingNode.x === xPos) {
              xPos += xSpacing; // Move to the right if overlap detected
            }
          });

          nodes[nodeId] = {
            name: model.attributes.name,
            x: xPos,
            y: yPos,
            selected: isSelected,

          };
        }
      });

      return nodes;
    },
    edges() {
      const edges = {};

      this.models.forEach(model => {
        if (model.attributes.previous_models.length > 0) {
          const sourceNodeId = `node${model.attributes.previous_models[0].id}`;
          const targetNodeId = `node${model.id}`;
          const edgeId = `edge${model.id}`;
          edges[edgeId] = {
            source: sourceNodeId,
            target: targetNodeId,
          };
        }
      });

      return edges;
    },
    layouts() {
      const layouts = { nodes: {} };
      Object.entries(this.nodes).forEach(([nodeId, node]) => {
        layouts.nodes[nodeId] = { x: node.x, y: node.y };
      });
      return layouts;
    },
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    ...mapActions('experts', ['fetchModels','setSelectedModel']),
    displayModelInfo(node) {

      if (!node) {
        return;
      }
      const nodeId = node;

      const modelId = parseInt(nodeId.replace('node', ''), 10);
      const model = this.models.find(model => model.id === modelId);
      console.log(model?.attributes?.training_sessions?.data?.[0]?.attributes.name);

      this.setSelectedModel(model);
      if (model) {
        console.log("UPDATE INFO", model.attributes?.training_sessions?.data?.[0]?.attributes?.name)
        this.selectedModelInfo = {
          name: model.attributes?.name ?? 'First Model',
          trainingSessionName: model.attributes?.training_sessions?.data?.[0]?.attributes?.name ?? 'A new Journey Starts Here',
          description: model.attributes?.training_sessions?.data?.[0]?.attributes?.description ?? 'Let us start the journey together! 🚀',
        };
        //this.$emit('model-info-displayed');
      }
    },

    updateSelectedNode(newModel) {
      if (newModel && newModel.id) {
        const newSelectedNodeId = `node${newModel.id}`;
        this.selectedNodes = [newSelectedNodeId];
      }
    },

    confirmSelection() {
      this.$emit('model-selected');
    },
  },
};
</script>


<style scoped>
.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.conversations-list {
  list-style: none;
  padding: 0;
}

.conversation-btn {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem !important;
}

.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}

.active .card {
  border: 3px solid green;
}

.card-header {
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.network-graph-container {
  width: 100%;
  height: 400px; /* Adjust as needed */
}

.model-info {
  margin-top: 20px;
  padding: 15px;
}

</style>

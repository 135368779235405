<template>
  <VueFinalModal v-model="isVisible"
                 class="flex justify-center items-center confirm-modal overflow-scroll"
                 content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
                 :hide-overlay="true"
                 overlay-transition="vfm-fade"
                 content-transition="vfm-fade"
  >
    <template #default>
      <div v-if="isLoading" class="loading-overlay">
        <vue3-lottie ref="lottie"
                     :animationData="animationData"
                     height="60vh"
                     width="100%"
        />
      </div>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Edit your Expert"
            class="z-index-5 documentation-link"
            description="Learn how to Edit to your Expert"
            docLink="https://docs.b-bot.space/essentials/edit-expert"
        />
        <h5 class="modal-title text-white mx-5">{{ isEditMode ? 'Edit Expert' : 'Create Expert' }}</h5>

        <button type="button" class="close-btn btn text-white" @click="closeModal">
          <i class="material-icons-round opacity-10 fs-5">close</i>
        </button>
      </div>
      <div class="modal-scrollable-content p-0">
        <div class="steps-indicator d-flex justify-content-center align-items-center">
          <span
              v-for="step in 8"
              :key="step"
              class="d-flex align-items-center justify-content-center"
              :disabled="currentStep !== step"
              :class="{'badge badge-circle badge-success': currentStep === step, 'badge badge-circle badge-dark': currentStep !== step}"
              @click="currentStep = step"
              style="margin: 0 4px;"
          >
            {{ step }}
          </span>
        </div>
        <transition name="slide-fade" mode="out-in">
          <div :key="currentStep">
            <!-- Business Card -->
            <div class="p-3" v-if="currentStep === 1">
              <h1 class="text-center">
                Basic Information
                <DocumentationLink
                    popoverTitle="Basic Information"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Basic information of an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#basic-information"
                />
              </h1>

              <div class="mb-3" v-if="!isEditMode">
                <material-input
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    size="lg"
                    v-model="selectedExpert.attributes.name"
                />
                <small class="form-text text-muted w-100">Add a name for your Virtual Expert</small>

              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="name"
                    size="lg"
                    :disabled="true"
                    v-model="selectedExpert.attributes.name"
                />
                <small class="form-text text-muted w-100">The name of your Virtual Expert</small>
              </div>

              <div class="mb-3" v-if="!isEditMode">
                <material-input
                    id="name"
                    type="text"
                    label="Profession"
                    name="profession"
                    size="lg"
                    v-model="selectedExpert.attributes.profession"
                />
                <small class="form-text text-muted w-100">Add a profession for your Virtual Expert</small>

              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="profession"
                    size="lg"
                    :disabled="true"
                    v-model="selectedExpert.attributes.profession"
                />
                <small class="form-text text-muted w-100">The profession of your Virtual Expert</small>
              </div>

              <div class="mb-3" v-if="!isEditMode">
                <material-textarea
                    v-model="selectedExpert.attributes.description"
                    placeholder="Description"
                    class="form-control"
                    id="description"
                    rows="3"
                    :max-chars="250"
                ></material-textarea>
                <small class="form-text text-muted w-100">Add a description for your Virtual Expert</small>
              </div>
              <div class="mb-3" v-else>
                <material-input
                    id="name"
                    type="text"
                    name="profession"
                    size="lg"
                    :disabled="true"
                    v-model=" selectedExpert.attributes.description"
                />
                <small class="form-text text-muted w-100">The description of your Virtual Expert</small>
              </div>

            </div>
            <div v-if="currentStep === 2">
              <h1 class="text-center">
                Profile Picture
                <DocumentationLink
                    popoverTitle="Profile Picture"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Profile Picture of an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#change-or-add-the-profile-picture"
                />
              </h1>

              <div class="card shadow-sm mb-4">
                <div class="card-body">
                  <div class="text-center">
                    <div class="position-relative d-inline-block mb-3">
                      <MaterialAvatar
                          :img="selectedExpert.attributes?.profile_picture?.attributes?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                          alt="Profile Picture"
                          size="xl"
                          shadow="sm"
                          :circular="true"
                      />
                      <label for="profilePictureUpload" class="btn btn-sm btn-primary position-absolute bottom-75 start-100 translate-middle-x camera-spaces">
                        <i class="fas fa-camera camera-fontsize"></i>
                      </label>
                    </div>
                    <input type="file" id="profilePictureUpload" @change="handleProfilePictureUpload" class="d-none" accept="image/*" />
                    <p class="text-muted small mt-2">For best results, upload a square image (1:1 aspect ratio)</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Abilities -->
            <div v-if="currentStep === 3">
              <h1 class="text-center">
                Abilities
                <DocumentationLink
                    popoverTitle="Edit add or remove abilities"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Abilities of an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#edit-add-or-remove-abilities"
                />
              </h1>

              <div class="card-body ">
                <!-- Check if there are abilities -->
                <div v-if="selectedExpert.attributes.abilities?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(ability, index) in selectedExpert.attributes.abilities" :key="index">
                      <div class="card p-3" v-if="!ability.isEditing">
                        <p>{{ ability.attributes.text }}</p>
                        <button @click="ability.isEditing = true" class="btn btn-sm btn-secondary mr-button mb-0 ml-0 mt-3">Edit</button>
                        <button @click="deleteAbility(index)" class="btn btn-sm btn-danger mb-0 mt-3">Delete</button>

                      </div>
                      <div class="card p-3" v-else>
                        <input v-model="ability.attributes.text" class="form-control mb-2" />
                        <button @click="handleSaveAbility(ability)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateAbility" class="btn btn-info">Generate new Ability</button>

                </div>
                <!-- Show explanation and generate button if no abilities -->
                <div v-else class="text-center">
                  <p>No abilities have been added yet. Abilities help to describe what your expert can do. Click the button below to generate initial abilities.</p>
                  <button @click="generateAbility" class="btn bg-gradient-info">Generate Initial Ability</button>
                </div>
              </div>
              <div class="container p-0 text-center">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Abilities!</h1>

                    <h2 style="color: white">Create Abilities for your Expert</h2>
                    <p>
                      You can steer the Experts behaviours in different situations by providing abilities. Abilities are predefined actions that the expert can take. Click the button below to generate initial abilities.
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toAbilitiesDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <!-- Templates -->
            <div v-if="currentStep === 4">
              <h1 class="text-center">
                Templates
                <DocumentationLink
                    popoverTitle="Edit add or remove templates"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Templates of an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#add-templates"
                />
              </h1>

              <div class="card-body">
                <!-- Check if there are templates -->
                <div v-if="selectedExpert.attributes.templates?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(template, index) in selectedExpert.attributes.templates" :key="index">
                      <div class="card p-3" v-if="!template.isEditing">
                        <div>{{ template.attributes.template_text }}</div>
                        <button @click="template.isEditing = true" class="btn btn-sm bg-gradient-secondary mr-button">Edit</button>
                        <button @click="deleteTemplate(index)" class="btn btn-sm bg-gradient-danger">Delete</button>
                      </div>
                      <div class="card p-3" v-else>
                        <material-textarea v-model="template.attributes.template_text" class="form-control mb-2"  id="textarea-template"/>
                        <button @click="saveTemplate(template, index)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Add new Template</button>
                </div>
                <!-- Show explanation and generate button if no templates -->
                <div v-else class="text-center">
                  <p>No templates have been added yet. Templates are predefined formats for tasks and communications. Click the button below to generate initial templates.</p>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Generate Initial Template</button>
                </div>
              </div>
              <div class="container p-0 text-center">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Templates!</h1>

                    <h2 style="color: white">Create Templates for your users so they know what to ask</h2>
                    <p>
                      Give some examples to your users so they know how to use your expert. soon you will be able to connect an ability to the templates!
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toTemplateDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <div v-if="currentStep === 5">
              <h1 class="text-center">
                Models
                <DocumentationLink
                    popoverTitle="Model Migrations"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Model Migrations for an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#model-migration"
                />
              </h1>

              <ExpertChooseModel :edit-mode="true" @to-next="currentStep ++" />
              <ModelVersions :onlyViewMode="true" />
            </div>

            <div v-if="currentStep === 6">
              <h1 class="text-center">
                App Connections
                <DocumentationLink
                    popoverTitle="App Connections"
                    class="z-index-5 documentation-link"
                    description="Learn more about the App Connections of an Expert"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#app-connections"
                />
              </h1>

              <p class="text-center">Review Expert App Connections and delete them here</p>
              <ExpertApps @to-next="currentStep ++" :expertApps="selectedExpert.attributes?.apps" @delete-app="deleteApp" @update-apps="updateExpertApps" />
            </div>

            <div v-if="currentStep === 7">
              <h1 class="text-center">
                Team
                <DocumentationLink
                    popoverTitle="Team Feature"
                    class="z-index-5 documentation-link"
                    description="Learn more about the Team feature"
                    docLink="https://docs.b-bot.space/essentials/edit-expert#team"
                />
              </h1>
              <ExpertTeam :experts="experts" :expertTeam="selectedExpert.attributes?.experts" @update:expertTeam="updateExpertTeam"/>
            </div>

            <div v-if="currentStep === 8">
              <ExpertCV :expertApps="selectedExpert.attributes?.apps" :expertData="selectedExpert" @update-apps="updateExpertApps"/>

            </div>

          </div>
        </transition>


        <div class="row">
          <div class="col-md-8 mx-auto">
            <!-- Add this section for validation errors -->
            <div v-if="validationErrors.length" class="mt-4">
              <material-alert
                  v-for="(error, index) in validationErrors"
                  :key="index"
                  class="font-weight-light"
                  color="danger"
                  dismissible
                  @dismissed="clearValidationErrors"
              >
                <span class="text-md">{{ error.path }}</span>
                <span class="text-sm">{{ error.message }}</span>
              </material-alert>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="navigation-buttons-container">
          <div class="navigation-buttons d-flex justify-content-between">
            <button
                v-if="currentStep !== 1"
                class="btn btn-secondary flex-fill me-2"
                @click="currentStep--"
            >
              Previous
            </button>

            <button v-if="currentStep === 8" type="submit" class="btn flex-fill bg-gradient-success shadow-success" @click="handleSubmit">
              {{ isEditMode ? 'Update' : 'Create' }}
            </button>
            <button
                class="btn btn-secondary flex-fill"
                @click="handleNextStep"
                v-if="currentStep < 8"
            >
              {{ currentStep === 1 && !isEditMode || currentStep === 5 && !isEditMode ? 'Save and Proceed' : 'Next' }}
            </button>
          </div>
        </div>



      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import {VueFinalModal} from "vue-final-modal";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import ExpertCV from "@/views/components/ExpertCV.vue";
import ExpertChooseModel from "@/views/components/ExpertChooseModel.vue";
import {mapActions, mapState} from "vuex";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import ExpertApps from "@/views/components/ExpertApps.vue";
import ExpertTeam from "@/views/components/ExpertTeam.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue"; // Import the TrainingInfo component
import ModelVersions from "@/views/components/ModelVersions.vue";
import DocumentationLink from "./DocumentationLink.vue";
import {MainApiService} from "@/classes/services/MainApiService";

export default {
  components: {
    DocumentationLink,
    ModelVersions,
    MaterialTextarea,
    ExpertChooseModel,
    MaterialAvatar,
    VueFinalModal,
    MaterialAlert,
    ExpertCV,
    ExpertApps,
    ExpertTeam,
    MaterialInput
  },
  props: {
    isEditMode: Boolean,
    initialExpert: Object,
  },
  data() {
    return {
      animationData: loadingChat,
      isVisible: false,
      isLoading: false,
      currentStep: 1,
      expertData: this.initializeExpertData(),
      transitionName: 'slide-fade',
      validationErrors: [],
      snackbar: null,
    };
  },
  computed: {
    ...mapState("experts", [
      'selectedExpert',
      'experts',
      'selectedModelForAgentCreation',
      'selectedProviderForAgentCreation'
    ]),
    ...mapState(['color']),
  },
  methods: {
    ...mapActions("experts", [
      'fetchExperts',
      'createExpert',
      'addAbility',
      'removeAbility',
      'updateAbility',
      'setSelectedExpert'
    ]),
    updateExpertApps(updatedApps) {
      console.log("UPDATE APP EXPERTS", updatedApps);
      // Merge the existing apps with updatedApps to avoid overwriting
      this.selectedExpert.attributes.apps = {
        ...this.selectedExpert.attributes.apps, // Existing apps
        ...updatedApps, // New or updated apps
      };
      this.selectedExpert.update(
          {
            attributes: {
              ...this.selectedExpert.attributes,
              apps: {
                ...this.selectedExpert.attributes.apps, // Existing apps
                ...updatedApps, // New or updated apps
              }
            }
          }
      );

      this.selectedExpert.save();
    },
    async updateExpertTeam(newTeam) {
      console.log(this.selectedExpert, newTeam)
      this.selectedExpert.update(
          {
            attributes: {
              ...this.selectedExpert.attributes,
              experts: newTeam
            }
          }
      )
      this.selectedExpert.save();
    },
    initializeExpertData() {
      if (this.initialExpert) {
        return JSON.parse(JSON.stringify(this.initialExpert)); // Deep copy if initialExpert exists
      } else {
        // Default structure for a new expert
        return {
          attributes: {
            profile_picture: { data: { attributes: { formats: { small: { url: '' } } } } },
            name: '',
            description: '',
            profession: '',
            functionName: '',
            functionDescription: '',
            systemMessage: '',
            templates: { data: [] },
            abilities: { data: [] },
          },
        };
      }
    },
    async handleProfilePictureUpload(event) {
      this.isLoading = true;
      const file = event.target.files[0];
      console.log("selectedExpert: ", this.selectedExpert)
      if (file) {
        try {
          await this.selectedExpert.addProfilePicture(file);
          console.log("SELECTED EXPERT NOW AFTER UPLOADING IMAGE", this.selectedExpert)
          // Handle additional logic for the response if needed
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
      this.isLoading = false;
    },
    handleNextStep() {
      this.currentStep++;
    },
    async extractMessageContent(response, fallback) {
      console.log("THIS WAS THE RESPONSE", response)
      if (response && response.completion?.choices?.[0]?.message?.content) {
        console.log("response.completion.choices[0].message.content.substring(0, 255)", response.completion.choices[0].message.content.substring(0, 255))
        return response.completion.choices[0].message.content.substring(0, 255); // Truncate to 255 characters
      }
      return fallback;
    },
    async generateTemplate() {

      console.log("this.selectedExpert?.attributes?.templates: ", this.selectedExpert?.attributes?.templates)
      const apiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

      const functionNameResponse = await apiService.generateMessage(
          'questions',
          `generate the Question content of: ${this.selectedExpert.attributes.description}
          | these are the questions already generated: ${this.selectedExpert?.attributes?.templates?.map(ability => ability.attributes.template_text).join(', ')}`
      );
      let messageContent = await this.extractMessageContent(
          functionNameResponse,
          `Please Edit this template`
      );


      const newTemplate = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        template_name: 'Template', // The name or title of the template.
        template_text: messageContent,
        isEditing: true, // Indicates whether this ability is currently being edited.
        experts: this.selectedExpert.id
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.selectedExpert.attributes.templates) {
        this.selectedExpert.attributes.templates = [];
      }

      if (!Array.isArray(this.selectedExpert.attributes.templates)) {
        this.selectedExpert.attributes.templates = [];
      }
      console.log(newTemplate)
      await this.selectedExpert.addTemplate(newTemplate);

    },
    async generateAbility() {
      console.log("this.selectedExpert?.attributes?.abilities: ", this.selectedExpert?.attributes?.abilities)
      const apiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

      const functionNameResponse = await apiService.generateMessage(
          'ability',
          `generate the Ability content of: ${this.selectedExpert.attributes.description}
          | these are the abilities already generated: ${this.selectedExpert?.attributes?.abilities?.map(ability => ability.attributes.text).join(', ')}`
      );
      let messageContent = await this.extractMessageContent(
          functionNameResponse,
          `Please Edit this ability`
      );

      const newAbility = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        name: 'Ability', // The name or title of the ability.
        text: messageContent, // A description of what the ability entails.
        isEditing: true, // Indicates whether this ability is currently being edited.
        experts: this.selectedExpert.id
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.selectedExpert.attributes.abilities) {
        this.selectedExpert.attributes.abilities = [];
      }

      if (!Array.isArray(this.expertData.attributes.abilities)) {
        this.selectedExpert.attributes.abilities = [];
      }

      console.log("newAbility",newAbility)
      this.selectedExpert.addAbility(newAbility)
      console.log(newAbility)
    },
    openModal() {
      this.currentStep = 1;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    async handleSubmit(with_close = true) {
      console.log("handle Submit")
      this.selectedExpert.save()
      if (with_close){
        this.closeModal();
      }
    },
    async handleSaveAbility(ability) {
      console.log("handleSaveAbility",ability)
      await ability.update(ability)
      await ability.save();
    },
    async deleteAbility(abilityIndex) {
      if (!confirm("Are you sure you want to delete this ability?")) {
        return;
      }

      const ability = this.selectedExpert.attributes.abilities[abilityIndex];
      await this.selectedExpert.removeAbility(ability)
      //await this.expertData.removeAbility(ability);
    },
    async saveTemplate(template) {
      template.isEditing = false;
      console.log(template)
      template.update(template)
      template.save()
    },
    async deleteTemplate(templateIndex) {
      // Optional: Confirm with the user before deletion
      if (!confirm("Are you sure you want to delete this template?")) {
        return;
      }

      const template = this.selectedExpert.attributes.templates[templateIndex];
      await this.selectedExpert.removeTemplate(template)
    },
    clearValidationErrors() {
      this.validationErrors = [];
    },
    async deleteApp(appKey) {

      // Ensure apps exists and is an object before deleting
      if (this.expertData.attributes.apps && typeof this.expertData.attributes.apps === 'object') {
        delete this.expertData.attributes.apps[appKey];
      } else {
        console.warn('Apps structure is not as expected');
      }

      // Save the changes
      await this.handleSubmit(false);

    },
    async toTemplateDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
    },
    async toAbilitiesDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
    },

  },
  watch: {
    currentStep(newVal, oldVal) {
      this.transitionName = newVal > oldVal ? 'slide-fade' : 'slide-fade-reverse';
    },
    initialExpert: {
      handler() {
        this.expertData = this.initializeExpertData(); // Reinitialize expertData when initialExpert changes
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>


<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}

.business-card {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  margin-right: 15px;
  border-radius: 50%;
}

.card-title {
  margin: 0;
  color: #333333;
  font-weight: bold;
}


.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.camera-spaces{
  margin: 0;
  padding: 0 6px;
}
.camera-fontsize{
  font-size: 0.6rem;
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}
</style>

import {ExpertRepository} from '@/classes/expert/ExpertRepository';
import store from "@/store";

export default class ExpertService {
    // Main Management Service declaration
    static async getAllExperts(){
        console.log("getAllExperts")
        return await ExpertRepository.fetchExperts();
    }

    static async getExpert(id){
        console.log("GetExpert")
        return await ExpertRepository.fetchExpertById(id);
    }


    static async saveExpert(expert) {
        const newExpert = await ExpertRepository.saveExpert(expert);
        console.log("newExpert",newExpert);
        if(expert.id){
            console.log("expert.id",expert.id);
            return await store.dispatch('experts/updateExpert', newExpert)
        } else {
            return await store.dispatch('experts/createExpert', newExpert);
        }

    }

    static async deleteExpert(expert) {
        await store.dispatch('experts/deleteExpert', expert.id);
        return await ExpertRepository.deleteExpert(expert.id);
    }

    static async cloneExpert(expert) {
        return await expert.clone();
    }

    static async updateExpertAttributes(expert) {
        return await expert.updateAttributes(expert.attributes);
    }

    static async addProfilePicture(expert, file){
        return await ExpertRepository.addProfilePicture(file, expert.id);
    }

    static async addExpertLLMModel(expert, llmModel){
        return await ExpertRepository.addExpertLLMModel(expert, llmModel);
    }
}
<template>
  <div class="container mt-4 mb-5 p-0">
    <div v-if="isLoading" class="loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>
    <h1 class="card p-2 text-center mb-4">
      Situation Selection
      <DocumentationLink
          popoverTitle="Situation Selection"
          class="z-index-5 documentation-link"
          description="Learn more about the Selection of Situations"
          docLink="https://docs.b-bot.space/essentials/training#enrich-your-training-data"
      />
    </h1>
    <div class="d-flex">
      <button @click="currentStep = 'synthetics'" :class="'flex-fill btn ' + (currentStep === 'synthetics' ? 'btn-success' : 'btn-secondary' )">Synthetic Conversations</button>
      <button @click="currentStep = 'f2td'" :class="'flex-fill d-none btn ' + (currentStep === 'f2td' ? 'btn-success' : 'btn-secondary' )">F2TD</button>
      <button @click="currentStep = 'favorites'" :class="'flex-fill btn ' + (currentStep === 'favorites' ? 'btn-success' : 'btn-secondary' )">Favorites</button>
      <button @click="currentStep = 'sessions'" :class="'flex-fill btn ' + (currentStep === 'sessions' ? 'btn-success' : 'btn-secondary' )">Sessions</button>
      <button @click="currentStep = 'import'" :class="'flex-fill btn ' + (currentStep === 'import' ? 'btn-success' : 'btn-secondary' )">Import</button>
    </div>
    <div v-if="currentStep === 'synthetics'">
      <h2 class="card p-2 text-center mb-4">
        Synthetic Conversations
        <DocumentationLink
            popoverTitle="Synthetic Conversations"
            class="z-index-5 documentation-link"
            description="Learn more about Synthetic Conversations"
            docLink="https://docs.b-bot.space/essentials/training#synthetic-conversations"
        />
      </h2>

      <SyntheticConversation
          :generated-cards="generatedCards"
          @add-card="addCard"
          @remove-card="removeCard"
          @wipe-generated-cards="wipeGeneratedCards"
      />
    </div>
    <div v-if="currentStep === 'f2td'">
      <h2 class="card p-2 text-center mb-4">Generate from F2TD</h2>
      <div class="f2td-section mb-5">
        <div class="accordion mb-0" id="settingsAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="settingsHeading">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#settingsCollapse" aria-expanded="false" aria-controls="settingsCollapse">
                Situation Generator Settings
              </button>
            </h2>
            <div id="settingsCollapse" class="accordion-collapse collapse show" aria-labelledby="settingsHeading" data-bs-parent="#settingsAccordion">
              <div class="accordion-body">
                <div class="mb-3">
                  <label for="maxQaTokens" class="form-label">Max Situation Tokens</label>
                  <input type="number" v-model.number="settings.max_qa_tokens" class="form-control" id="maxQaTokens">
                </div>
                <div class="mb-3">
                  <label for="maxFactTokens" class="form-label">Max Fact Tokens</label>
                  <input type="number" v-model.number="settings.max_fact_tokens" class="form-control" id="maxFactTokens">
                </div>
                <div class="mb-3">
                  <label for="numFactsPerChunk" class="form-label">Number of Facts per Chunk</label>
                  <input type="number" v-model.number="settings.num_facts_per_chunk" class="form-control" id="numFactsPerChunk">
                </div>
                <div class="mb-3">
                  <label for="numQasPerFact" class="form-label">Number of Situations per Fact</label>
                  <input type="number" v-model.number="settings.num_qas_per_fact" class="form-control" id="numQasPerFact">
                </div>
                <div class="mb-3">
                  <label for="concurrencyLimit" class="form-label">Concurrency Limit</label>
                  <input type="number" v-model.number="settings.concurrency_limit" class="form-control" id="concurrencyLimit">
                </div>
                <div class="mb-3">
                  <label class="form-label">Choose API Endpoint:</label>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="processText" value="process_text" v-model="selectedApi">
                    <label class="form-check-label" for="processText">
                      Process Text
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="processTextAgent" value="process_text_agent" v-model="selectedApi">
                    <label class="form-check-label" for="processTextAgent">
                      Process Text Agent
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-md-editor
            v-model="inputText"
            mode="edit"
            left-toolbar="undo redo clear"
            right-toolbar="fullscreen"
            id="Inputf2td"
            class="mb-3">
        </v-md-editor>
        <button @click="generateCards" class="btn btn-primary mb-5 mt-5 w-100">Generate Situations</button>

        <div v-if="generatedCards.length">
          <h3 class="card p-2 text-center mb-4">Generated Situations</h3>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12" v-for="card in generatedCards" :key="card.id">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Generated Situation {{ card.id }}</h5>
                  <div v-for="(message, index) in card.attributes?.set || []" :key="index" class="message">
                    <p><strong>{{ message.role }}:</strong>
                      <v-md-preview class="message-text" :text="message.content"></v-md-preview>
                    </p>
                  </div>
                  <button @click="toggleCardSelection(card)" class="btn" :class="card.selected ? 'btn-success' : 'btn-secondary'">
                    {{ card.selected ? 'Deselect' : 'Select' }}
                  </button>
                  <button @click="saveCardToFavorites(card)" class="btn btn-success mt-2">Save to Favorites</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-if="currentStep === 'favorites'">
      <h2 class="card p-2 text-center mb-4">
        Saved Situations
        <DocumentationLink
            popoverTitle="Saved Situations"
            class="z-index-5 documentation-link"
            description="Learn more about Saved Situations"
            docLink="https://docs.b-bot.space/essentials/training#favorites"
        />
      </h2>
      <div class="row mb-5">

        <div class="col-lg-12 col-md-12 col-12 mb-3" v-for="card in cards" :key="card.id">
          <div class="card">
            <div class="card-body p-0">

              <div :class="'card-header bg-gradient-' + (card.selected ? 'success' : 'info') + ' text-white d-flex justify-content-between align-items-center mb-5'">
                <!-- Title aligned to the left -->
                <h4 class="mb-0 text-white">Situation {{ card.id }}: {{card?.attributes?.name}}</h4>

                <!-- Tags aligned to the right -->
                <h6 v-if="card?.attributes?.tags" class="mb-0 text-white">
                  <span v-for="tag in card?.attributes?.tags" :key="tag.name" class="badge badge-sm bg-gradient-primary m-1">
                    {{ tag.value }}
                  </span>
                </h6>
                <material-button
                    class="btn-app mt-1"
                    variant="gradient"
                    :color="(card.selected ? 'success' : 'info')"
                    size="md"
                    @click="$emit('card-selected', card)"
                >
                  {{ (card.selected ? 'Deselect' : 'Select') }}
                </material-button>
              </div>
              <chat-message
                  v-for="(message, index) in card.attributes?.set"
                  :key="index"
                  :id="index"
                  :role="message.role"
                  :senderId="message.senderId"
                  :sender="message.sender"
                  :senderName="message.role"
                  :text="message.content"
                  :thoughts="message.thoughts"
                  :timestamp="message.timestamp"
                  :qaMarkingActivated="false"
                  :possibleToEdit="false"
                  @update:text="message.content = $event"
                  profilePictureUrl="https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 'sessions'">
      <h2 class="card p-2 text-center mb-4">
        Training Sessions
        <DocumentationLink
            popoverTitle="Training Sessions"
            class="z-index-5 documentation-link"
            description="Learn more about use of Situations from previous Training Sessions"
            docLink="https://docs.b-bot.space/essentials/training#sessions"
        />
      </h2>
      <div class="accordion" id="trainingSessionsAccordion">
        <div class="accordion-item" v-for="(sessionData, sessionIndex) in trainingSessions" :key="sessionIndex">
          <h2 class="accordion-header" :id="'heading' + sessionIndex">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + sessionIndex" aria-expanded="false" :aria-controls="'collapse' + sessionIndex">
              {{sessionData.attributes?.name || "Training Session: " + (sessionIndex + 1) }}
            </button>
          </h2>
          <div :id="'collapse' + sessionIndex" class="accordion-collapse collapse" :aria-labelledby="'heading' + sessionIndex" data-bs-parent="#trainingSessionsAccordion">

            <div class="accordion-body">
              <p v-if="sessionData.attributes?.description">{{sessionData.attributes?.description}}</p>
              <!-- Button to select/deselect all sets of messages in the current training session -->
              <button @click="toggleSelectAllSetsInSession(sessionData, sessionIndex)" class="btn mb-2 w-100" :class="areAllSetsInSessionSelected(sessionData) ? 'btn-success' : 'btn-secondary'">
                {{ areAllSetsInSessionSelected(sessionData) ? 'Deselect All Sets' : 'Select All Sets' }}
              </button>

              <!-- Iterate through each set of messages within the session -->
              <div v-for="(messageSet, setIndex) in sessionData.attributes?.training_data?.session || []" :key="setIndex" class="session-messages card mb-3 mt-3">
                <!-- Button to select/deselect the entire set of messages -->
                <div :class="'card-header bg-gradient-' + (areAllMessagesInSetSelected(messageSet) ? 'success' : 'info') + ' text-white d-flex justify-content-between align-items-center mb-5'">
                  <!-- Title aligned to the left -->
                  <h4 class="mb-0 text-white">Situation {{ setIndex }}</h4>
                  <material-button
                      class="btn-app mt-1"
                      variant="gradient"
                      :color="(areAllMessagesInSetSelected(messageSet) ? 'success' : 'info')"
                      size="md"
                      @click="toggleSelectAllMessagesInSet(messageSet, sessionIndex, setIndex)"
                  >
                    {{ (areAllMessagesInSetSelected(messageSet) ? 'Deselect' : 'Select') }}
                  </material-button>
                </div>

                <chat-message
                    v-for="(message, index) in messageSet.messages || []"
                    :key="index"
                    :id="index"
                    :role="message.role"
                    :senderId="message.senderId"
                    :sender="message.sender"
                    :senderName="message.role"
                    :text="message.content"
                    :thoughts="message.thoughts"
                    :timestamp="message.timestamp"
                    profilePictureUrl="https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg"
                />


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="currentStep === 'import'">
      <h2 class="card p-2 text-center mb-4">
        Import Training Data from ChatGPT (BETA)
        <DocumentationLink
            popoverTitle="Import"
            class="z-index-5 documentation-link"
            description="Learn more about Importing Training Data"
            docLink="https://docs.b-bot.space/essentials/training#import"
        />
      </h2>
      <div class="file-input-container mb-3 card col-3">
        <label for="file-upload" class="file-upload">
          <span><img class="w-100" src="@/assets/img/illustrations/folder-dynamic-gradient.png"> Datei laden</span>
        </label>
        <input type="file" id="file-upload" @change="handleFileUpload" />
      </div>
      <div v-if="uploadedCards.length">
        <h3>Uploaded Conversations</h3>
        <!-- Pagination controls -->
        <div class="pagination-controls mb-3">
          <button class="btn btn-primary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button class="btn btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
        <div class="row mb-5">
          <div class="col-lg-12 col-md-12 col-12" v-for="card in paginatedCards" :key="card.id">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Conversation {{ card.id }}</h5>
                <div v-for="(message, index) in card.attributes?.set || []" :key="index" class="message">
                  <p><strong>{{ message.role }}:</strong>
                    <v-md-preview class="message-text" :text="message.content"></v-md-preview>
                  </p>
                </div>
                <button @click="toggleCardSelection(card)" class="btn" :class="card.selected ? 'btn-success' : 'btn-secondary'">
                  {{ card.selected ? 'Deselect' : 'Select' }}
                </button>
                <button @click="saveCardToFavorites(card)" class="btn btn-success mt-2">Save to Favorites</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

import MaterialButton from "@/components/MaterialButton.vue";
import {mapActions, mapState} from "vuex";
import SyntheticConversation from "./SyntheticConversations.vue";
import ChatMessage from "./ChatMessage.vue";
import DocumentationLink from "./DocumentationLink.vue";

const { VUE_APP_MAIN_API_URL } = process.env;

export default {
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  components: {
    DocumentationLink,
    MaterialButton,
    ChatMessage,
    SyntheticConversation
  },
  data() {
    return {
      animationData: loadingChat,
      isLoading: false,
      currentStep: 'synthetics',
      inputText: '',
      settings: {
        max_qa_tokens: 800,
        max_fact_tokens: 200,
        num_facts_per_chunk: 1,
        num_qas_per_fact: 1,
        concurrency_limit: 10,
      },
      uploadedCards: [],
      selectedApi: 'process_text',
      currentPage: 1,
      cardsPerPage: 50,
      nextId: 1,
    };
  },
  computed: {
    ...mapState('training', ['generatedCards', 'trainingSessions', 'traininsSessionQaPairs']),
    paginatedCards() {
      const start = (this.currentPage - 1) * this.cardsPerPage;
      const end = start + this.cardsPerPage;
      return this.uploadedCards.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.uploadedCards.length / this.cardsPerPage);
    },
  },
  methods: {
    ...mapActions('training', ["setGeneratedCards", "saveQAPairs", "fetchTrainingSessions"]),
    generateUniqueId() {
      return `id_${this.nextId++}`; // Generate unique ID based on the counter
    },
    wipeGeneratedCards() {
      console.log("set Generated Cards")
      this.setGeneratedCards([]);
    },
    addCard(card) {
      this.generatedCards.push(card);
    },
    removeCard(card) {
      this.generatedCards = this.generatedCards.filter((c) => c !== card);
    },
    async handleFileUpload(event) {
      this.isLoading = true;


      const file = event.target.files[0];
      if (!file) {
        this.isLoading = false;

        alert('No file selected.');
        return;
      }


      if (file.type !== 'application/json') {
        this.isLoading = false;

        alert('Please upload a valid JSON file.');
        return;
      }

      try {
        const fileContent = await file.text();

        const jsonData = JSON.parse(fileContent);

        this.processUploadedData(jsonData);
      } catch (error) {
        this.isLoading = false;

        console.error('Error reading or parsing JSON file:', error);
        alert('Failed to read or parse JSON file.');
      }
    },
    async processUploadedData(dataArray) {
      this.isLoading = true;

      const cards = [];
      const chunkSize = 500; // Process in chunks of 1000 to prevent blocking

      for (let i = 0; i < dataArray.length; i += chunkSize) {
        const chunk = dataArray.slice(i, i + chunkSize);

        chunk.forEach(data => {
          if (!data.mapping) {
            console.error('data.mapping is undefined');
            alert('Invalid JSON structure: missing "mapping" key.');
            this.isLoading = false;
            return;
          }

          for (const key in data.mapping) {
            if (!Object.prototype.hasOwnProperty.call(data.mapping, key)) continue;

            const conversation = data.mapping[key];
            if (conversation.level && conversation.level !== 'desired_level') {
              continue; // Skip entries that do not match the desired level
            }

            const messages = this.extractMessages(conversation, data.mapping);
            // Remove initial system messages
            const filteredMessages = messages.filter((message, index) => !(message.role === 'system' && index < 2));

            cards.push({
              id: key,
              attributes: {
               set: filteredMessages,
              },
              selected: false,
            });
          }
        });

        this.uploadedCards = [...this.uploadedCards, ...cards];
        const combinedQAPairs = [...this.generatedCards, ...this.uploadedCards];
        await this.setGeneratedCards(combinedQAPairs);
        await this.$nextTick(); // Allow the UI to update between chunks
      }

      this.isLoading = false;
    },
    extractMessages(conversation, mapping) {
      const messages = [];
      if (conversation.message && conversation.message.content) {
        const contentParts = conversation.message.content.parts || [];
        contentParts.forEach((part) => {
          messages.push({
            role: conversation.message.author.role,
            content: part,
          });
        });
      }

      // Recursively extract messages from child conversations
      (conversation.children || []).forEach(childId => {
        const childConversation = mapping[childId];
        if (childConversation) {
          messages.push(...this.extractMessages(childConversation, mapping));
        }
      });

      return messages;
    },
    async generateCards() {
      this.isLoading = true;

      if (this.inputText.trim() === '') {
        alert('Please enter some text to generate cards.');
        return;
      }

      const apiUrl = this.selectedApi === 'process_text' ? VUE_APP_MAIN_API_URL + '/api/v0/process_text' : VUE_APP_MAIN_API_URL + '/api/v0/process_text_agent';

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: this.inputText,
            temperature: 0.7,
            character_prompt: "you are the B-Bot hub Question answer set generator",
            ability_prompt: "",
            model: "gpt-4",
            session_id: "test-session-1",
            user: {
              sub: "user123"
            },
            tool_activation: {
              tavily_search: true,
              wolfram_alpha: true,
              document_retriever: false,
              notion_connector: false
            },
            ...this.settings // Include other settings dynamically
          }),
        });

        const data = await response.json();

        let newQAPairs = [];

        if (data.qa_completions) {
          if (Array.isArray(data.qa_completions)) {
            // For process_text endpoint where qa_completions is an array of strings
            newQAPairs = data.qa_completions.flatMap(item => {
              try {
                // Attempt to parse each item individually
                const parsedItems = item.split('\n').filter(Boolean).map(JSON.parse);
                return parsedItems;
              } catch (jsonError) {
                console.error('Error parsing qa_completions item:', item, jsonError);
                throw new Error('Invalid JSON in qa_completions');
              }
            });
          } else if (typeof data.qa_completions === 'object') {
            // For process_text_agent endpoint where qa_completions is an object
            const { question, answer } = data.qa_completions;
            newQAPairs.push({ question, answer });
          }
        } else {
          this.isLoading = false;

          throw new Error('Invalid API response format');
        }

        // Combine the new QA pairs with the existing ones
        const combinedQAPairs = [...this.generatedCards, ...newQAPairs];

        // Set the generated cards with the combined list
        await this.setGeneratedCards(this.processQAPairs(combinedQAPairs));
        this.isLoading = false;

      } catch (error) {
        this.isLoading = false;

        console.error('Error generating cards:', error);
        alert('Failed to generate cards. Please try again.');
      }
    },
    processQAPairs(qaPairs) {
      return qaPairs.map((qaPair, index) => ({
        id: index + 1,
        attributes: {
          set: [
            { role: 'user', content: qaPair.question },
            { role: 'assistant', content: qaPair.answer },
          ],
        },
        selected: false,
      }));
    },
    toggleCardSelection(card) {
      card.selected = !card.selected;
    },
    saveCardToFavorites(card) {
      card.set = card.messages;
      this.$emit('save-qa-pairs', [...this.cards, card]);
      this.generatedCards = this.generatedCards.filter(c => c.id !== card.id);
      // add function to save on Datacenter trough the vuex
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    toggleSelectAllSetsInSession(sessionData, sessionIndex) {
      // Check if all sets are currently selected
      const allSelected = this.areAllSetsInSessionSelected(sessionData);

      sessionData.attributes?.training_data?.session.forEach((messageSet, setIndex) => {
        if (!messageSet) return; // Defensive check for undefined messageSet

        // Toggle the selected state of the message set
        messageSet.selected = !allSelected;

        // Toggle selection for all messages within the set
        if (messageSet.messages) {
          messageSet.messages.forEach(message => {
            message.selected = !allSelected;
          });
        }

        // Restructure the messageSet for saving with index-based IDs
        const structuredSet = {
          id: `session${sessionIndex}_set${setIndex}`, // Generate unique ID based on indexes
          attributes: {
            set: messageSet.messages ? messageSet.messages.map((message, messageIndex) => ({
              id: `session${sessionIndex}_set${setIndex}_message${messageIndex}`, // Unique ID for each message
              content: message.content || '',
              role: message.role || '',
              timestamp: message.timestamp || '',
              thoughts: message.thoughts || []
            })) : [],
            createdAt: sessionData.attributes?.createdAt || '',
            updatedAt: sessionData.attributes?.updatedAt || '',
            locale: sessionData.attributes?.locale || ''
          },
          selected: messageSet.selected
        };

        // Add or remove the set from traininsSessionQaPairs based on selection state
        if (messageSet.selected) {
          // Add to traininsSessionQaPairs if not already present
          if (!this.traininsSessionQaPairs.find(set => set.id === structuredSet.id)) {
            this.traininsSessionQaPairs.push(structuredSet);
          }
        } else {
          // Remove from traininsSessionQaPairs if deselected
          this.traininsSessionQaPairs = this.traininsSessionQaPairs.filter(set => set.id !== structuredSet.id);
        }
      });
    },

    areAllSetsInSessionSelected(sessionData) {
      return sessionData.attributes?.training_data?.session?.every(messageSet =>
          messageSet.selected
      );
    },

    toggleSelectAllMessagesInSet(messageSet, sessionIndex, setIndex) {
      if (!messageSet) return;

      // Toggle selection for all messages in the specific set
      const allSelected = this.areAllMessagesInSetSelected(messageSet);
      messageSet.selected = !allSelected;
      if (messageSet.messages) {
        messageSet.messages.forEach(message => {
          message.selected = !allSelected;
        });
      }

      // Restructure the messageSet for saving with index-based IDs
      const structuredSet = {
        id: `session${sessionIndex}_set${setIndex}`, // Unique ID for each set
        attributes: {
          set: messageSet.messages ? messageSet.messages.map((message, messageIndex) => ({
            id: `session${sessionIndex}_set${setIndex}_message${messageIndex}`, // Unique ID for each message
            content: message.content || '',
            role: message.role || '',
            timestamp: message.timestamp || '',
            thoughts: Array.isArray(message.thoughts) ? message.thoughts : []
          })) : [],
          createdAt: messageSet.attributes?.createdAt || '',
          updatedAt: messageSet.attributes?.updatedAt || '',
          locale: messageSet.attributes?.locale || ''
        },
        selected: messageSet.selected
      };

      // Update traininsSessionQaPairs
      this.traininsSessionQaPairs = this.traininsSessionQaPairs.filter(set => set.id !== structuredSet.id);

      if (messageSet.selected) {
        // Add to traininsSessionQaPairs if not already present
        this.traininsSessionQaPairs.push(structuredSet);
      }
    },
    // Check if all messages in a set are selected
    areAllMessagesInSetSelected(messageSet) {
      return messageSet.messages && messageSet.messages.length > 0 ? messageSet.messages.every(message => message.selected) : false;
    }
  },
  async mounted() {
    this.traininsSessionQaPairs = [];
    const trainingSessions = await this.fetchTrainingSessions();
    console.log("FETCH TRAINING SESSIONS: ", trainingSessions)
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 16px; /* Adjust gap between cards */
}

.card-body {
  display: flex;
  flex-direction: column;
}

.message p {
  margin: 0.5rem 0;
}

.btn {
  margin-top: 1rem;
  align-self: flex-end;
}

.f2td-section {
  margin-bottom: 24px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.file-input-container input[type="file"] {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  cusror: pointer;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls button {
  margin: 0 10px;
}

input#file-upload[type="file"] {
  display: none;
}
.file-upload {
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}
</style>

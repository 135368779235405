import store from "@/store";
import AbilityService from "@/classes/services/AbilityService";
import {AbilityFactory} from "@/classes/expert/ability/AbilityFactory";
export class Ability {
    constructor({id = null, attributes}, isEditing = false) {
        this.id = id;
        this.attributes = attributes;
        this.isEditing = isEditing;
    }

    // Method to update ability attributes
    update({ attributes }) {
        console.log("attributes: ", attributes);
        if (attributes) {
            this.attributes.name = attributes.name;
            this.attributes.text = attributes.text;
            this.attributes.experts = attributes.experts;
        } else {
            console.error("Invalid attributes passed to update:", attributes);
        }
    }

    // Save method to persist the ability data (create or update)
    async save() {
        try {
            if (this.id) {
                const abilityModel = await AbilityService.saveAbility(this);
                return await store.dispatch('experts/updateAbility', abilityModel);
            } else {
                const abilityModel = await AbilityService.saveAbility({
                    name: this.attributes.name,
                    text: this.attributes.text,
                    experts: this.attributes.experts,
                });
                return await store.dispatch('experts/addAbility', abilityModel);
            }
        } catch (error) {
            console.error("Error saving ability:", error);
            return { success: false, error: error.message }; // Return error information
        }
    }

    // Method to clone an ability and save it to the repository / will be done later
    async clone() {
        try {
            const name = this.attributes.name;
            const text = this.attributes.text;
            const clonedAbility = new AbilityFactory.createAbility({name, text});

            // Save the cloned ability to the repository
            const result = await clonedAbility.save();
            if (result.success) {
                return clonedAbility; // Return the successfully saved clone
            } else {
                throw new Error(result.error);
            }
        } catch (error) {
            console.error("Error cloning ability:", error);
            return { success: false, error: error.message };
        }
    }
}

export default Ability;

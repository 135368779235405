<template>
  <div class="welcome-message">
    <h3 class="typing">Hello i am {{selectedExpert?.attributes?.name}}</h3>
    <p class="typing2">How can i help you?</p>
    <button v-for="(template, index) in expertTemplates" :key="index" class="btn btn-sm mb-0 btn-outline-primary btn-md float-left m-2" @click="selectTemplate(template.attributes.template_text)">{{ template.attributes.template_text }}</button>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: 'WelcomeMessage',
  computed: {
    ...mapState('experts', ['selectedExpert']),
    expertTemplates() {
      console.log("getExpertTemplates", this.selectedExpert);
      // Check if the selectedExpert and its templates are defined
      if (this.selectedExpert && this.selectedExpert.attributes.templates && this.selectedExpert.attributes.templates.length > 0) {
        return this.selectedExpert.attributes.templates;
      }
      return []; // Return an empty array if no templates are available or no expert is selected
    }
  },
  methods: {
    ...mapMutations(['setSelectedTemplateText']),
    selectTemplate(templateText) {
      this.setSelectedTemplateText(templateText);
    },
  },
};
</script>

<style scoped>
.welcome-message {
  padding: 20px;
  text-align: center;
}

.welcome-message ul {
  list-style-type: none;
  padding: 0;
}
.typing, .typing2 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 1rem auto; /* Centers the text block */
  letter-spacing: .15em; /* Adjust as needed */
  display: block; /* Ensures they are stacked vertically */
  width: 0; /* Start with no width to enable typing effect */
}

/* Typing animations */
.typing {
  animation:
      typing 1.5s steps(40, end) forwards, /* Adds "forwards" to keep final state */
      blink-caret .75s step-end infinite;
}

.typing2 {
  animation:
      typing 1.5s steps(40, end) 1.5s forwards, /* Delayed animation for sequence */
      blink-caret .75s step-end infinite 3s; /* Blinking starts after typing finishes */
}

/* Typing effect for both */
@keyframes typing {
  from { width: 0; } /* Typing starts from 0 width */
  to { width: 100%; } /* Typing ends at full width of text */
}

/* Blink effect */
@keyframes blink-caret {
  from, to { border-color: transparent; } /* Caret disappears */
  50% { border-color: orange; } /* Caret is visible */
}

</style>

import store from "@/store";
import TemplateService from "@/classes/services/TemplateService";
import {TemplateFactory} from "@/classes/expert/template/TemplateFactory";
export class Template {
    constructor({id = null, attributes}, isEditing = false) {
        this.id = id;
        this.attributes = attributes;
        this.isEditing = isEditing;
    }

    // Method to update ability attributes
    update({ attributes }) {
        console.log("attributes: ", attributes);
        if (attributes) {

            this.attributes.template_name = attributes.template_name;
            this.attributes.template_text = attributes.template_text;
            this.attributes.experts = attributes.experts;
        } else {
            console.error("Invalid attributes passed to update:", attributes);
        }
    }

    // Save method to persist the ability data (create or update)
    async save() {
        try {
            if (this.id) {
                const templateModel = await TemplateService.saveTemplate(this);
                return await store.dispatch('experts/updateTemplate', templateModel);
            } else {
                const templateModel = await TemplateService.saveTemplate({
                    template_name: this.attributes.template_name,
                    template_text: this.attributes.template_text,
                    experts: this.attributes.experts,
                });
                return await store.dispatch('experts/addTemplate', templateModel);
            }
        } catch (error) {
            console.error("Error saving ability:", error);
            return { success: false, error: error.message }; // Return error information
        }
    }

    // Method to clone an ability and save it to the repository / will be done later
    async clone() {
        try {
            const name = this.attributes.template_name;
            const text = this.attributes.template_text;
            const clonedTemplate = new TemplateFactory.createTemplate({name, text});

            // Save the cloned ability to the repository
            const result = await clonedTemplate.save();
            if (result.success) {
                return clonedTemplate; // Return the successfully saved clone
            } else {
                throw new Error(result.error);
            }
        } catch (error) {
            console.error("Error cloning ability:", error);
            return { success: false, error: error.message };
        }
    }
}

export default Template;

<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Domain Knowledge</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div v-if="isLoading" class="loading-overlay">
        <vue3-lottie ref="lottie"
                     :animationData="animationData"
                     height="60vh"
                     width="100%"
        />
      </div>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Chat Settings"
            class="z-index-5"
            description="Learn more about Chat Settings."
            docLink="https://docs.b-bot.space/essentials/chat#the-settings-window"
        />
        <h5 class="modal-title text-white mx-5">Domain Knowledge</h5>
      </div>
      <div class="col-md-12 mt-3">
        <h2>{{knowledgeApp.knowledge_name}}</h2>
        <p>{{knowledgeApp.knowledge_description}}</p>
        <div class="file-input-container mt-3 mb-3 card col-3">
          <label for="file-upload" class="file-upload">
            <span><img class="w-100" src="@/assets/img/illustrations/folder-dynamic-gradient.png"> Datei laden</span>
          </label>
          <input type="file" id="file-upload" @change="handleFileUpload" />
        </div>
        <div class="file-list">
          <h3>Uploaded Files:</h3>
          <ul>
            <li v-for="file in uploadedFiles.files" :key="file.id">
              <a :href="file.signedUrl" target="_blank">{{ file.fileName }}</a>
              <button @click="deleteFile(file.key)" class="btn btn-outline-danger">
                delete
              </button>
            </li>
          </ul>
        </div>
        <h3>What your Expert sees: </h3>
        <div v-if="documents.hits.hits" id="accordionExample">
          <div v-for="(doc, index) in documents.hits.hits" :key="index" class="accordion-item">
            <h2 class="accordion-header" :id="'heading' + index">
              <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + index"
                  aria-expanded="true"
                  :aria-controls="'collapse' + index"
              >
                <h5 class="m-0">{{ doc._source.text.slice(0, 20) }}...</h5>
              </button>
            </h2>
            <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                :class="{ show: index === 0 }"
                :aria-labelledby="'heading' + index"
                data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <v-md-preview :text="doc._source.text"></v-md-preview>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p>No documents found.</p>
        </div>
      </div>

      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import { mapState } from 'vuex';
import DocumentationLink from './DocumentationLink.vue';
import {mainApiService} from "@/store/training";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

export default {
  components: {
    DocumentationLink,
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
    knowledgeApp: Object,
  },
  data() {
    return {
      animationData: loadingChat,
      searchQuery: '',
      documents: [],
      uploadedFiles: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['user', 'color']),
    ...mapState('experts', ['selectedExpert']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  watch: {
    knowledgeApp: {
      immediate: true, // Trigger immediately on first render
      handler(newValue) {
        if (newValue) {
          this.fetchMemories();
          this.listFiles()
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
    },
    async deleteFile(fileKey){
      console.log(fileKey)
      const response = await fetch('/.netlify/functions/deleteFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileKey: fileKey
        }),
      });
      console.log( await response.json() )
      await this.listFiles()
      await this.flushKnowledge()
      const files = this.uploadedFiles.files.map(file => file.signedUrl);

      await this.processDocuments(files)
    },
    async flushKnowledge() {
      this.isLoading = true;

      const entryIds = this.documents.hits.hits.map(hit => hit._id);

      // Log to check the structure of the payload before sending
      console.log("Entry IDs Payload:", JSON.stringify({ entry_ids: entryIds }));

      const requestPayload = {
        entry_ids: entryIds,
      };

      await mainApiService.call(
          `delete_entries`,
          "POST",
          requestPayload,
      ).then((response) => {
        console.log(response);  // Log the response
        this.isLoading = false;

      }).catch((error) => {
        console.error("Error while deleting entries:", error);  // Handle errors
        this.isLoading = false;

      });
    },
    async listFiles(){
      console.log(this.user.sub)
      this.isLoading = true;
      const response = await fetch('/.netlify/functions/getAllFiles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id + "_knowledge_" + this.knowledgeApp.knowledge_name,  // Pass user ID or relevant user info in the body
        }),
      });
      this.uploadedFiles = await response.json();

      console.log("listFiles: ",this.uploadedFiles)
      this.isLoading = false;

    },

    async handleFileUpload(event) {
      this.isLoading = true;

      const file = event.target.files[0];
      if (!file) {
        this.isLoading = false;
        alert('No file selected.');
        return;
      }

      const reader = new FileReader();

      // Using a closure to create a stable reference for the reader's onload
      reader.onload = async (event) => {
        const base64File = event.target.result.split(',')[1]; // Get base64 part

        try {
          // Upload the file to your backend and receive the pre-signed URL
          const response = await fetch('/.netlify/functions/uploadFile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: base64File,
              fileName: file.name,
              userId: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id + "_knowledge_" + this.knowledgeApp.knowledge_name,  // Pass user ID or relevant user info in the body

            }),
          });

          const data = await response.json();
          if (response.ok) {
            this.downloadUrl = data.downloadUrl;
            this.processDocuments([this.downloadUrl]);
            console.log(this.selectedExpert)
            //add the file to the documents of the app

          } else {
            alert(`Upload failed: ${data.message}`);
          }
        } catch (error) {
          alert('Error uploading file');
          console.error(error);
        } finally {
          console.log("")
        }
      };

      reader.onerror = () => {
        this.isLoading = false;
        alert('Error reading file');
      };

      reader.readAsDataURL(file);
    },
    async processDocuments(urls) {
      console.log(urls)
      this.isLoading = true;

      const requestPayload = {
        documents: urls,
        assistant_id: this.knowledgeApp.indexer
      }
      await mainApiService.call(
          `process_documents`,
          "POST",
          requestPayload
      ).then((response) => {
        console.log(response)
        this.fetchMemories()
        this.listFiles()
        this.isLoading = false;

      })
    },
    async fetchMemories() {
      console.log('Fetching documents for:', this.knowledgeApp);
      this.isLoading = true;

      await mainApiService.call(
          `get_memories?user_ids=${this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id + "_knowledge_" + this.knowledgeApp.knowledge_name}`,
          "GET",
      ).then((response) => {
        this.documents = response; // Store documents in the data property
        this.isLoading = false;

      })
          .catch((error) => {
            this.isLoading = false;

            console.error('Error fetching documents:', error);
            this.documents = { hits: { hits: [] } }; // Fallback value

          });
    },
  },
};
</script>


<style scoped>

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}


.file-input-container input[type="file"] {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  cusror: pointer;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls button {
  margin: 0 10px;
}

input#file-upload[type="file"] {
  display: none;
}
.file-upload {
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
}

</style>

import {DatacenterService} from "@/classes/services/DatacenterService";
import {AbilityFactory} from "@/classes/expert/ability/AbilityFactory";

export const datacenterService = new DatacenterService();

export class AbilityRepository {
    static async fetchAbilities() {
        try {
            const rawAbilities = await datacenterService.fetchObjects('abilities');
            return rawAbilities.data.map(
                (rawAbility) =>
                    AbilityFactory.createAbility(rawAbility)
            );
        } catch (error) {
            console.error("Error fetching abilities:", error);
            return { success: false, error: error.message };
        }
    }

    static async fetchAbilityById(id) {
        try {
            const abilityData = await datacenterService.fetchObjects('abilities',"/"+id);
            return AbilityFactory.createAbility(abilityData);
        } catch (error) {
            console.error("Error fetching ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async createAbility(abilityData) {
        try {
            console.log("LOG BEFORE CREATION ABILITY", abilityData);
            const savedAbility = await datacenterService.createObject('abilities', abilityData);
            console.log("savedAbility in CREATE ABILITY",savedAbility)
            const createAbility = AbilityFactory.createAbility({id: savedAbility.id, attributes: savedAbility});
            console.log("createAbility: ", createAbility);
            return createAbility;
        } catch (error) {
            console.error("Error creating ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async updateAbility(ability) {
        try {
            console.log("UPDATING ABILITY:", ability);

            const updatedAbility = await datacenterService.updateObject('abilities', ability.id, ability);
            console.log("updatedAbility",updatedAbility.data);
            return AbilityFactory.createAbility(updatedAbility.data)
        } catch (error) {
            console.error("Error updating ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async deleteAbility(id) {
        try {
            const response = await datacenterService.deleteObject('abilities', id);
            return { success: true, data: response };
        } catch (error) {
            console.error("Error deleting ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async cloneAbility(id) {
        try {
            const ability = await this.fetchAbilityById(id);
            if (ability.success === false) return ability;

            const clonedAbility = await ability.clone(); // Clone logic is in the model
            return { success: true, data: clonedAbility };
        } catch (error) {
            console.error("Error cloning ability:", error);
            return { success: false, error: error.message };
        }
    }
}

import {DatacenterService} from "@/classes/services/DatacenterService";
import {ExpertLLMFactory} from "@/classes/expert/expert_llm/ExpertLLMFactory";

export const datacenterService = new DatacenterService();

export class ExpertLLMRepository {
    static async fetchExpertLLMs() {
        try {
            const rawLLMs = await datacenterService.fetchObjects('llm-models', '?populate=previous_models,training_sessions');
            return rawLLMs.data.map(
                (rawLLM) =>
                {
                    const previous_models = (rawLLM.attributes.previous_models.data || []).map((previous_model) =>
                        ExpertLLMFactory.createExpertLLM(previous_model)
                    );
                    return ExpertLLMFactory.createExpertLLM({
                        id: rawLLM.id,
                        attributes: {
                            ...rawLLM.attributes,
                            previous_models
                        },
                    })
                }
            );
        } catch (error) {
            console.error("Error fetching expert llms:", error);
            return { success: false, error: error.message };
        }
    }

    static async fetchExpertLLMById(id) {
        try {
            const expertLLMData = await datacenterService.fetchObjects('llm-models',"/"+id);
            return ExpertLLMFactory.createExpertLLM(expertLLMData);
        } catch (error) {
            console.error("Error fetching ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async createExpertLLM(expertLLMData) {
        try {
            console.log("LOG BEFORE CREATION expertLLM", expertLLMData);
            const savedExpertLLM = await datacenterService.createObject('llm-models', expertLLMData);
            console.log("createExpertLLM in CREATE ExpertLLM",savedExpertLLM)
            const createExpertLLM = ExpertLLMFactory.createExpertLLM({id: savedExpertLLM.data.id, attributes: savedExpertLLM.data.attributes});
            console.log("createExpertLLM: ", createExpertLLM);
            return createExpertLLM;
        } catch (error) {
            console.error("Error creating expert llm:", error);
            return { success: false, error: error.message };
        }
    }

    static async updateExpertLLM(expertLLM) {
        try {
            console.log("UPDATING expertLLM:", expertLLM);

            const updatedExpertLLM = await datacenterService.updateObject('llm-models', expertLLM.id, expertLLM);
            console.log("updatedExpertLLM: ",updatedExpertLLM.data);
            return ExpertLLMFactory.createExpertLLM(updatedExpertLLM.data)
        } catch (error) {
            console.error("Error updating expertLLM:", error);
            return { success: false, error: error.message };
        }
    }

    static async deleteExpertLLM(id) {
        try {
            const response = await datacenterService.deleteObject('llm-models', id);
            return { success: true, data: response };
        } catch (error) {
            console.error("Error deleting expertLLM:", error);
            return { success: false, error: error.message };
        }
    }

    static async cloneAbility(id) {
        try {
            const ability = await this.fetchAbilityById(id);
            if (ability.success === false) return ability;

            const clonedAbility = await ability.clone(); // Clone logic is in the model
            return { success: true, data: clonedAbility };
        } catch (error) {
            console.error("Error cloning ability:", error);
            return { success: false, error: error.message };
        }
    }
}

import { ExpertLLMRepository } from "@/classes/expert/expert_llm/ExpertLLMRepository";
import store from "@/store";

export default class ExpertLLMService {
    // Fetch all abilities
    static async getAllExpertLLMs() {
        return await ExpertLLMRepository.fetchExpertLLMs();
    }

    // Save (create or update) an ability
    static async saveExpertLLM(expertLLM) {
        console.log("SAVE Expert Model: ", expertLLM);
        if(!expertLLM.id){
            const newExpertLLM = await ExpertLLMRepository.createExpertLLM(expertLLM);
            console.log("AFTER SAVING THE MODEL WE WANT TO CHECK IF IT HAS AN ID BEFORE WE ADD IT TO THE STORE: ", newExpertLLM)
            return await store.dispatch('training/createModel', newExpertLLM);
        } else {
            const newExpertLLM =await ExpertLLMRepository.updateExpertLLM(expertLLM);
            return await store.dispatch('training/updateModel', newExpertLLM);

        }
    }

    // Delete an ability by ID
    static async deleteExpertLLM(id) {
        return await ExpertLLMRepository.deleteExpertLLM(id);
    }

    // Clone an ability
    static async cloneExpertLLM(ability) {
        return await ability.clone(); // Delegates the logic to the model's `clone` method
    }

    // Update ability attributes
    static async updateExpertLLMAttributes(ability) {
        return await ExpertLLMRepository.updateExpertLLM(ability);
    }

    // Fetch a specific ability by ID
    static async getExpertLLMById(id) {
        return await ExpertLLMRepository.fetchExpertLLMById(id);
    }
}

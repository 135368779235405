<!-- AnimatedToolEvents.vue -->
<template>
  <div class="tool-events-container">
    <transition-group
        name="tool-event"
        tag="div"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
    >
      <div
          v-for="(event, index) in visibleEvents"
          :key="index"
          class="tool-event text-center p-3"
      >
        <div class="event card bg-white shadow-md rounded-lg p-4">
          <div class="d-flex align-items-center gap-2">
            <div class="tool-icon bg-primary rounded-circle p-2">
              <i class="fas fa-bolt text-white"></i>
            </div>
            <div class="flex-grow-1 text-start">
              <strong class="d-block">{{ event.tool_info.name }}</strong>
              <span class="text-muted">{{ event.tool_info.inputs }}</span>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'AnimatedToolEvents',

  props: {
    events: {
      type: Array,
      required: true
    },
    incomingMessage: {
      type: String,
      required: true,
      default: ''
    }
  },

  data() {
    return {
      visibleEvents: [],
      hadMessage: false
    }
  },

  watch: {
    events: {
      immediate: true,
      handler(newEvents) {
        if (newEvents.length > 0 && !this.hadMessage) {
          this.visibleEvents = newEvents;
        }
      }
    },

    incomingMessage: {
      handler(newMessage) {
        if (newMessage) {
          this.hadMessage = true;
        } else if (this.hadMessage && !newMessage) {
          // Only clear when transitioning from having a message to no message
          this.clearEvents();
          this.hadMessage = false;
        }
      }
    }
  },

  methods: {
    clearEvents() {
      setTimeout(() => {
        this.visibleEvents = [];
      }, 500); // Match with CSS transition duration
    },

    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateX(100%)';
    },

    enter(el, done) {
      el.offsetHeight; // Force reflow
      el.style.opacity = 1;
      el.style.transform = 'translateX(0)';
      el.addEventListener('transitionend', done);
    },

    leave(el, done) {
      el.style.opacity = 0;
      el.style.transform = 'translateX(-100%)';
      el.addEventListener('transitionend', done);
    }
  }
}
</script>

<style scoped>
.tool-events-container {
  position: relative;
  width: 100%;
}

.tool-event {
  position: relative;
  transition: all 0.5s ease;
}

.tool-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Enter and leave animations */
.tool-event-enter-active,
.tool-event-leave-active {
  transition: all 0.5s ease;
}

.tool-event-enter-from {
  opacity: 0;
  transform: translateX(100%);
}

.tool-event-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

/* Ensure transitions are smooth */
.tool-event-move {
  transition: transform 0.5s ease;
}
</style>
<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="news"
            navText="News"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">feed</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="chat"
            navText="Chat"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">chat</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="dashboard"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="knowledge"
          navText="Knowledge"
        >
          <template v-slot:icon>
            <i class="material-symbols-outlined opacity-10">network_intelligence</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item d-none">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="workflows"
          navText="Workflows"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">schema</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item d-none">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="experts"
          navText="Experts"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">support_agent</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"

          collapseRef="training"
          navText="Training"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">model_training</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="apps"
          navText="Apps"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">apps</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-collapse
            url="#"
            :aria-controls="''"
            v-bind:collapse="false"
            collapseRef="billing"
            navText="Billing"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="profile"
          navText="Profile"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
    <div class="sidenav-footer w-100">
      <div class="mx-3">
        <a
          class="btn mt-2 w-100"
          :class="`bg-gradient-${this.$store.state.color}`"
          href="https://b-bot.ch"
          >Upgrade</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import {mapState} from "vuex";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  computed: {
    ...mapState(['abo'])
  },
  components: {
    SidenavCollapse,
  },
  methods: {

  },
  mounted() {
    console.log("DETER the abo in nav",this.abo)
    console.log("DETER the metadata", this.abo?.activeSubscription?.metadata)
  }
};
</script>

import TrainingSessionService from "@/classes/services/TrainingSessionService";
import {MainApiService} from "@/classes/services/MainApiService";
export const mainApiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

export default {
    namespaced: true,
    state: {
        trainingSessions: [],
        selectedTrainingSession: null,
        currentStatusMessage: "Start Training",
        shouldAnimate: false,
        showCancel: false,
        statusCheckInterval: null,
        jobId: null,
        qaPairs: [],
        statistics: [],
        traininsSessionQaPairs: [],
        generatedCards: [],
        generatedAnswers: [],
        trainingInProgress: false,
        trainingSuccess: false,
    },
    mutations: {
        SET_TRAINING_IN_PROGRESS(state, status) {
            state.trainingInProgress = status;
        },
        SET_TRAINING_SUCCESS(state, status) {
            state.trainingSuccess = status;
        },
        SET_STATUS_MESSAGE(state, message) {
            state.currentStatusMessage = message;
        },
        SET_SHOULD_ANIMATE(state, value) {
            state.shouldAnimate = value;
        },
        SET_SHOW_CANCEL(state, value) {
            state.showCancel = value;
        },
        SET_STATUS_INTERVAL(state, intervalId) {
            state.statusCheckInterval = intervalId;
        },
        CLEAR_STATUS_INTERVAL(state) {
            if (state.statusCheckInterval) {
                clearInterval(state.statusCheckInterval);
                state.statusCheckInterval = null;
            }
        },
        SET_JOB_ID(state, id) {
            state.jobId = id;
        },
        CLEAR_JOB_ID(state) {
            state.jobId = null;
        },
        SET_GENERATED_CARDS(state, cards) {
            state.generatedCards = cards;
        },
        ADD_TRAINING_SESSION(state, session) {
            state.trainingSessions.push(session);
        },
        UPDATE_TRAINING_SESSION(state, updatedTrainingSession) {
            console.log("UpdatetrainingSession: ", updatedTrainingSession);
            const index = state.trainingSessions.findIndex(trainingSession => trainingSession.id === updatedTrainingSession.id);
            if (index !== -1) {
                state.trainingSessions.splice(index, 1, updatedTrainingSession);
            }
            state.selectedTrainingSession = updatedTrainingSession;
        },
        DELETE_TRAINING_SESSIONS(state, sessionId) {
            const index = state.trainingSessions.findIndex(session => session.id === sessionId);
            if (index !== -1) {
                state.trainingSessions.splice(index, 1);
            }
        },
        SET_TRAINING_SESSIONS(state, sessions) {
            state.trainingSessions = sessions;
        },
        SAVE_TRAINING_SESSIONS(state, sessions) {
            state.trainingSessions = sessions; // Assuming you have this state property
        },
        SET_SELECTED_TRAINING_SESSION(state, session) {
            state.selectedTrainingSession = session;
        },
        ADD_QA_PAIR(state, qaPair) {
            // Assuming `qaPairs` is an array within your state to store QA pairs
            state.qaPairs.push(qaPair);
        },
        UPDATE_QA_PAIR_IN_STATE(state, { qaId, qaPairs }) {
            const index = state.qaPairs.findIndex(pair => pair.id === qaId);
            if (index !== -1) {
                // Assuming each QA pair in your state has a unique ID
                state.qaPairs[index].messages = qaPairs;
            }
        },
        WIPE_QA_PAIR_IN_STATE(state){
            state.qaPairs = [];
        },
        DELETE_QA_PAIR_FROM_STATE(state, qaId) {
            const index = state.qaPairs.findIndex(pair => pair.id === qaId);
            if (index !== -1) {
                state.qaPairs.splice(index, 1);
            }
        },
        TOGGLE_QA_PAIR_SELECTION(state, qaId) {
            const index = state.qaPairs.findIndex(qaPair => qaPair.id === qaId);
            if (index !== -1) {
                state.qaPairs[index].selected = !state.qaPairs[index].selected;
            }
        },
        CLEAR_SELECTED_TRAINING_SESSION_QA_PAIRS(state) {
            state.traininsSessionQaPairs = [];
        },
    },
    actions: {
        trainingSuccess({ commit }, status) {
            commit('SET_TRAINING_SUCCESS', status);
        },
        setJobId({ commit }, jobId){
            console.log("Setting the JOB ID:", jobId)
            commit('SET_JOB_ID', jobId);
        },
        setStatusMessage({ commit }, status) {
            commit('SET_STATUS_MESSAGE', status);
        },
        startTrainingInProgress({ commit }) {
            commit('SET_TRAINING_IN_PROGRESS', true);
        },
        stopTrainingInProgress({ commit }) {
            commit('SET_TRAINING_IN_PROGRESS', false);
        },
        setGeneratedCards({ commit }, cards) {
            commit('SET_GENERATED_CARDS', cards);
        },
        setShowCancel({ commit }, showCancel) {
          commit('SET_SHOW_CANCEL', showCancel);
        },
        startStatusPolling({ dispatch, commit, state }, { fineTuningId, provider }) {
            console.log("START STATUS POLLING", fineTuningId, provider);
            if (state.statusCheckInterval) {
                console.warn("Status polling is already active. Skipping new interval.");
                return;
            }

            let time = 10000;
            commit('CLEAR_STATUS_INTERVAL'); // Clear any existing interval
            const intervalId = setInterval(async () => {
                const data = await dispatch('checkTrainingSessionStatus', { fineTuningId, provider });
                if (data && data.status) {
                    let status = data.status.status;

                    // Normalize status for "tuneai" provider
                    if (provider === "tuneai") {
                        if (status === 'ACTIVE') status = 'running';
                        else if (status === 'PROVISIONING') status = 'queued';
                        else if (status === 'COMPLETED') status = 'succeeded';
                    }

                    dispatch('updateUIBasedOnStatus', status);
                    dispatch('updateModelBasedOnStatus', { status, data, provider });

                    if (['succeeded', 'failed', 'cancelled'].includes(status)) {
                        commit('CLEAR_STATUS_INTERVAL');
                        dispatch('experts/fetchModels', null, { root: true });
                    }
                }
            }, time);

            commit('SET_STATUS_INTERVAL', intervalId);
        },
        // refactor notification (add a module for that one too)
        updateUIBasedOnStatus({ commit }, status) {

            let message = "";
            let animate = false;

            switch (status) {
                case 'ready':
                    message = "Start Training";
                    commit('SET_STATUS_MESSAGE', message);
                    commit('SET_SHOW_CANCEL', false);

                    animate = false;
                    break;
                case 'validating_files':
                    message = "Validate Training Data...";
                    commit('SET_SHOW_CANCEL', true);

                    commit('SET_STATUS_MESSAGE', message);

                    animate = true;
                    break;
                case 'queued':
                    message = "Process in Queue...";
                    commit('SET_SHOW_CANCEL', true);

                    animate = true;
                    commit('SET_STATUS_MESSAGE', message);

                    break;
                case 'pending':
                    message = "Process in Queue...";
                    commit('SET_SHOW_CANCEL', true);

                    animate = true;
                    commit('SET_STATUS_MESSAGE', message);

                    break;
                case 'cancelled':
                    message = "Cancelled... Restart?";
                    commit('SET_SHOW_CANCEL', false);
                    commit('CLEAR_STATUS_INTERVAL');
                    commit('SET_STATUS_MESSAGE', message);

                    break;
                case 'running':
                    message = "Training in progress...";
                    animate = true;
                    commit('SET_SHOW_CANCEL', true);

                    commit('SET_STATUS_MESSAGE', message);

                    break;
                case 'deployment_in_progress':
                    message = "Deployment in progress...";
                    animate = true;
                    commit('SET_STATUS_MESSAGE', message);
                    commit('SET_SHOW_CANCEL', true);

                    break;
                case 'succeeded':
                    message = "Training Completed";
                    commit('CLEAR_STATUS_INTERVAL');
                    commit('SET_STATUS_MESSAGE', message);
                    commit('SET_TRAINING_SUCCESS', true);
                    commit('SET_TRAINING_IN_PROGRESS', false);
                    commit('SET_SHOW_CANCEL', false);

                    break;
                case 'failed':
                    message = "Training Failed";
                    commit('CLEAR_STATUS_INTERVAL');
                    commit('SET_STATUS_MESSAGE', message);
                    commit('SET_TRAINING_SUCCESS', false);
                    commit('SET_TRAINING_IN_PROGRESS', false);
                    commit('SET_SHOW_CANCEL', false);

                    break;
                default:
                    message = "Unknown Status";
                    commit('SET_TRAINING_IN_PROGRESS', false);
                    commit('CLEAR_STATUS_INTERVAL');
                    commit('SET_SHOW_CANCEL', false);

                    break;
            }

            /*
            Add new Notification System for more control and reliability
            const existingNotification = state.notifications.find(
                (notification) => notification.type === 'training_status'
            );

            if (existingNotification) {
                const updatedNotification = {
                    ...existingNotification,
                    title: message,
                    link: '/training#train',
                    time: new Date().toLocaleTimeString(),
                };
                commit('updateNotification', updatedNotification);
            } else {
                // Add new notification if none exists
                const notification = {
                    id: new Date().getTime(), // Unique ID based on timestamp
                    type: 'training_status', // Unique type for training status notifications
                    title: message,
                    image: GymImage, // Optional: add an image if necessary
                    link: '/training#train', // Optional: add a link to the notification
                    time: new Date().toLocaleTimeString()
                };
                commit('addNotification', notification);
            }
            */
            commit('SET_STATUS_MESSAGE', message);
            commit('SET_SHOULD_ANIMATE', animate);
            //commit('setShowCancel', showCancel);
        },
        async updateModelBasedOnStatus({ dispatch, state, rootState }, { status, data, provider }) {
            console.log("state.selectedTrainingSession: ",state.selectedTrainingSession)
            if (status === 'succeeded') {
                let newModelData = {};
                if (provider === "tuneai") {
                    newModelData = {
                        name: "Gen_" + data.status.id,
                        identifier: "beyondbotai/"+data.status.name+"-model-" + data.status.id,
                        models_experts: rootState.experts.selectedExpert.id,
                        previous_models: rootState.experts.selectedModel.id,
                        training_sessions: state.selectedTrainingSession.id,
                        provider: "tuneai",
                        multiplier: state.selectedTrainingSession.attributes.mto.attributes.multiplier,
                    };
                } else {
                    newModelData = {
                        name: "Gen_" + data.status.created_at,
                        identifier: data.status.fine_tuned_model,
                        models_experts: rootState.experts.selectedExpert.id,
                        previous_models: rootState.experts.selectedModel.id,
                        training_sessions: state.selectedTrainingSession.id,
                        provider: state.selectedTrainingSession.attributes.mto.attributes.provider,
                        multiplier: state.selectedTrainingSession.attributes.mto.attributes.multiplier,
                    };
                }
                //const newModel = await ExpertLLMService.saveExpertLLM(newModelData)
                //console.log("this is the new Model we add to the Expert: ", newModel)
                console.log("we add the model to this expert:", rootState.experts.selectedExpert )
                await rootState.experts.selectedExpert.addModel(newModelData)
                //trying to add the new model to the training session so we can later fetch it based on the training session
                console.log("state.selectedTrainingSession: ", state.selectedTrainingSession)
                state.selectedTrainingSession.update({
                    attributes:{
                        ...state.selectedTrainingSession.attributes,
                        llm_models: rootState.experts.models[0].id,
                    }
                })
                state.selectedTrainingSession.save();
                await dispatch('updateTrainingSession' , state.selectedTrainingSession);

                await dispatch('stopTrainingInProgress');
                //await dispatch('experts/fetchExperts');
                //await dispatch('experts/fetchModels');
            }
        },
        async createModel({ dispatch }, model){
            try {
                await dispatch('experts/addModel', model, { root: true });
                await dispatch('experts/setSelectedModel', model, { root: true });
                return model;
            } catch (error) {
                console.error('Error creating expert:', error);
                return { success: false, error: { message: error.message } }; // Return error information
            }
        },
        async updateModel({ commit }, { model }){
            try {
                commit('UPDATE_MODEL', model);
                return model;

            } catch (error) {
                console.error('Error creating expert:', error);
                return { success: false, error: { message: error.message } }; // Return error information
            }
        },
        async fetchTrainingSessions({ commit }) {
            try {
                console.log("FETCH TRAINING SESSIONS")
                const sessions = await TrainingSessionService.getAllTrainingSessions();
                // Store only the first author, if exists
                if (sessions.length > 0) {
                    commit('SET_TRAINING_SESSIONS', sessions);
                }
                return sessions;

            } catch (error) {
                console.error('Fetching the author failed:', error);
            }
        },
        async getTrainingSession({ commit }, sessionId) {
            try {
                console.log("GET TRAINING SESSION SINGLE FROM ID", sessionId)
                const responseData = await TrainingSessionService.getTrainingSessionById(sessionId);
                commit('saveTrainingSession', responseData );
                return responseData;
            } catch (error) {
                console.error('Error deleting training session:', error);
            }
        },
        async addTrainingSession({ commit }, trainingSession) {
            try {

                commit('ADD_TRAINING_SESSION', trainingSession);
                commit('SET_SELECTED_TRAINING_SESSION', trainingSession)
                return trainingSession
            } catch (error) {
                console.error('Error adding training session:', error);
            }
        },
        async updateTrainingSession({commit}, trainingSession) {
            try {
                console.log("training.js UPDATE TRAINING SESSION:", trainingSession)
                commit('UPDATE_TRAINING_SESSION', trainingSession);
                return trainingSession;
            } catch (error) {
                console.error('Error updating expert:', error);
                throw error;
            }
        },
        async deleteTrainingSession({ commit }, sessionId) {
            try {
                // API-Aufruf, um die Session aus dem Backend zu löschen
                const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        callname: "training-sessions/" + sessionId,
                        action: "DELETE",
                    })
                });

                if (!response.ok) {
                    console.error('Error deleting training session');
                    return;
                }

                commit('deleteTrainingSession', sessionId);

            } catch (error) {
                console.error('Error deleting training session:', error);
            }
        },
        async saveTrainingSession({ commit }, session) {
            commit('saveTrainingSession', session);
        },
        async startTrainingSession({ state, rootState }, trainingPayload) {
            const characterPrompt = rootState.experts.selectedExpert.attributes.system_message;
            console.log("get me the selectedExpert object: rootState.experts.selectedExpert", rootState.experts.selectedExpert)
            let abilityPrompt = '';
            if (rootState.experts.selectedAbility) {
                abilityPrompt = rootState.experts.selectedAbility.attributes.text;
            } else {
                //console.log('No ability selected, skipping abilityPrompt');
            }
            console.log("state.selectedTrainingSession: ",state.selectedTrainingSession)
            const modifiedPayload = {
                model: state.selectedTrainingSession.attributes.mto.attributes.identifier,
                provider: state.selectedTrainingSession.attributes.mto.attributes.provider,
                ...trainingPayload,
                characterPrompt,
                ...(abilityPrompt && { abilityPrompt }),
            };

            try {
                const responseData = await mainApiService.call(
                    "trainllm",
                    "POST",
                    modifiedPayload,
                );

                if (responseData) {
                    // Use the fine_tuning_id from responseData as the session ID
                    //const sessionId = state.selectedTrainingSession.id;
                    // You may want to include more session data here based on your needs
                    const sessionData = {
                        status: {
                            id: responseData.fine_tuning_id,
                        }
                    };
                    console.log("START TRAINING SESSION SELECTED TRAINING SESSION: ", state.selectedTrainingSession)
                    // Call updateTrainingSession to save/update the session metadata
                    state.selectedTrainingSession.update({
                        attributes: {
                            ...state.selectedTrainingSession.attributes,
                            training_session_data: sessionData
                        }
                    });
                    state.selectedTrainingSession.save()
                    //await dispatch('updateTrainingSession', { sessionId, data: {training_session_data: sessionData} });
                    return sessionData;
                } else {
                    console.error('Failed to start training session');
                }
            } catch (error) {
                console.error('Error during training session start:', error);
            }
        },
        async stopTrainingSession({ state }){
            try {

                const id = state.jobId;
                const provider = state.selectedTrainingSession.attributes.mto.attributes.provider
                // Ensure the function is using 'GET' method instead of 'POST'
                const response = await mainApiService.call(`trainllm/cancel/${id}/${provider}`, 'GET')



                const data = await response;

                if (data && data.status) {
                    state.selectedTrainingSession.update({
                        attributes: {
                            ...state.selectedTrainingSession.attributes,
                            training_session_data: data
                        }
                    })
                    state.selectedTrainingSession.save()
                    //dispatch('updateTrainingSession', { sessionId: state.selectedTrainingSession.id, data: { training_session_data: data } });
                }

                return data; // Return the data for any further processing
            } catch (error) {
                console.error('Failed to check training session status:', error);
                throw error; // Rethrow or handle error as appropriate
            }
        },
        async checkTrainingSessionStatus({ state, rootState }, { fineTuningId, provider }) {
            try {
                console.log("GET SELECTED EXPERT OBJECT", rootState.experts.selectedExpert)
                console.log("SELECTED TRAINING SESSION in checkTrainingSessionStatus: ", state.selectedTrainingSession)
                const response = await mainApiService.call(`trainllm/status/${rootState.user.sub}/${state.selectedTrainingSession.attributes.experts[0].id}/${fineTuningId}/${provider}`, 'GET')


                const data = await response;

                if (data && data.status) {
                    state.selectedTrainingSession.update({
                        attributes: {
                            ...state.selectedTrainingSession.attributes,
                            training_session_data: data
                        }
                    })
                    state.selectedTrainingSession.save()
                }

                return data; // Return the data for any further processing
            } catch (error) {
                console.error('Failed to check training session status:', error);
                throw error; // Rethrow or handle error as appropriate
            }
        },
        async saveQAPairs({ commit, dispatch }, { title, tags, qaPairs }) {
            try {
                // Split the comma-separated tags string and transform it into the required format
                const transformedTags = tags.split(',').map(tag => {
                    const trimmedTag = tag.trim(); // Remove extra spaces around each tag
                    return { name: trimmedTag, value: trimmedTag };
                });

                // Convert QA pairs to the format expected by your backend
                const payload = {
                    name: title,
                    tags: transformedTags, // Use the transformed tags here
                    set: qaPairs
                };

                const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({
                        callname: "qas",
                        action: "POST",
                        payload: { data: payload }
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to save QA pairs');
                }

                await response.json();

                // Optionally, commit a mutation to update state with new QA pair data
                qaPairs.forEach(qaPair => commit('addQAPair', qaPair));
                dispatch("fetchQAPairs");
            } catch (error) {
                console.error('Error saving QA pairs:', error);
            }
        },
        async updateQAPair({ commit }, { qaId, qaPairs }) {
            try {
                const payload = { messages: qaPairs };
                const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({
                        callname: `qas/${qaId}`,
                        action: "PUT",
                        payload: payload
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to update QA pair');
                }

                await response.json();

                commit('updateQAPairInState', { qaId, qaPairs }); // Update local state
            } catch (error) {
                console.error('Error updating QA pair:', error);
            }
        },
        async deleteQAPair({ commit }, qaId) {
            try {
                const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',

                    body: JSON.stringify({
                        callname: `qas/${qaId}`,
                        action: "DELETE",
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to delete QA pair');
                }

                commit('deleteQAPairFromState', qaId); // Remove from local state
            } catch (error) {
                console.error('Error deleting QA pair:', error);
            }
        },
        async fetchQAPairs({ commit }) {
            try {
                const response = await fetch(`${window.location.origin}/.netlify/functions/datacenter`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({
                        callname: `qas`,
                        action: "GET",
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch QA pairs');
                }

                const responseData = await response.json();
                // Assuming responseData contains an array of QA pairs
                // Augment each QA pair with a selected attribute set to false
                const qaPairsWithSelected = responseData.data.map(qaPair => ({
                    ...qaPair,
                    selected: false // Add the selected attribute here
                }));
                commit('WIPE_QA_PAIR_IN_STATE');
                // Use the existing method to add each fetched and augmented QA pair to state
                qaPairsWithSelected.forEach(qaPair => commit('ADD_QA_PAIR', qaPair));
            } catch (error) {
                console.error('Error fetching QA pairs:', error);
            }
        },
        async toggleQAPairSelection({ commit }, qaId) {
            commit('TOGGLE_QA_PAIR_SELECTION', qaId);
        },
    },
    getters: {

    },
};

import ExpertLLMService from "@/classes/services/ExpertLLMService";
export class ExpertLLMModel {
    constructor({id = null, attributes}) {
        this.id = id;
        this.attributes = attributes;
    }

    // Method to update ability attributes
    update({ attributes }) {
        console.log("attributes: ", attributes);
        if (attributes) {
            this.attributes = attributes;
        } else {
            console.error("Invalid attributes passed to update:", attributes);
        }
    }

    // Save method to persist the ability data (create or update)

    async save() {
        try {
            if (this.id) {
                const abilityModel = await ExpertLLMService.saveExpertLLM(this);
                //return await store.dispatch('experts/updateAbility', abilityModel);
                return abilityModel
            } else {
                const abilityModel = await ExpertLLMService.saveExpertLLM({
                    name: this.attributes.name,
                    identifier: this.attributes.identifier,
                    models_experts: this.attributes.models_experts,
                    training_sessions: this.attributes.training_sessions,
                    llm_models: this.attributes.llm_models,
                    previous_models: this.attributes.previous_models || [],
                    provider: this.attributes.provider,
                    base_model_type: this.attributes.base_model_type,
                    ts_mtos: this.attributes.ts_mtos,
                    multiplier: this.attributes.multiplier,
                });
                //return await store.dispatch('experts/addAbility', abilityModel);
                return abilityModel;
            }
        } catch (error) {
            console.error("Error saving ability:", error);
            return { success: false, error: error.message };
        }
    }
}

export default ExpertLLMModel;

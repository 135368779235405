import {DatacenterService} from "@/classes/services/DatacenterService";
import {TrainingSessionFactory} from "@/classes/expert/expert_llm/training_session/TrainingSessionFactory";
import {ExpertLLMFactory} from "@/classes/expert/expert_llm/ExpertLLMFactory";
import {ExpertFactory} from "@/classes/expert/ExpertFactory";

export const datacenterService = new DatacenterService();

export class TrainingSessionRepository {
    static async fetchTrainingSessions() {
        try {
            const rawTrainingSessions = await datacenterService.fetchObjects('training-sessions', '?populate=mto,experts');
            return rawTrainingSessions.data.map(
                (rawTrainingSession) => {
                    const experts = rawTrainingSession.attributes.experts.data.map(
                        (expert) => ExpertFactory.createExpert(expert)
                    );
                    if(rawTrainingSession.attributes.mto.data != null) {
                        const mto = ExpertLLMFactory.createExpertLLM(rawTrainingSession.attributes.mto.data)
                        return TrainingSessionFactory.createTrainingSession({
                            id: rawTrainingSession.id,
                            attributes: {
                                ...rawTrainingSession.attributes,
                                mto,
                                experts
                            }
                        })
                    } else{
                        return TrainingSessionFactory.createTrainingSession({
                            id: rawTrainingSession.id,
                            attributes: {
                                ...rawTrainingSession.attributes,
                                experts
                            }
                        })
                    }

                }
            );
        } catch (error) {
            console.error("Error fetching training sessions:", error);
            return { success: false, error: error.message };
        }
    }

    static async fetchTrainingSessionsById(id) {
        try {
            const trainingSessionData = await datacenterService.fetchObjects('training-sessions',"/" + id + "?populate=mto,experts");

            const experts = trainingSessionData.attributes.experts.data.map(
                (expert) => ExpertFactory.createExpert(expert)
            );
            if(trainingSessionData.attributes.mto.data != null) {
                const mto = ExpertLLMFactory.createExpertLLM(trainingSessionData.attributes.mto.data)
                return TrainingSessionFactory.createTrainingSession({
                    id: trainingSessionData.id,
                    attributes: {
                        ...trainingSessionData.attributes,
                        mto,
                        experts
                    }
                })
            } else{
                return TrainingSessionFactory.createTrainingSession({
                    id: trainingSessionData.id,
                    attributes: {
                        ...trainingSessionData.attributes,
                        experts
                    }
                })
            }
        } catch (error) {
            console.error("Error fetching training sessions:", error);
            return { success: false, error: error.message };
        }
    }

    static async createTrainingSession(trainingSessionData) {
        try {
            const savedTrainingSession = await datacenterService.createObject('training-sessions', trainingSessionData);
            const retrievedTrainingSession = await datacenterService.fetchObjects('training-sessions',`/${savedTrainingSession.id}/?populate=*`);
            console.log("retrievedTrainingSession: ",retrievedTrainingSession)
            if(retrievedTrainingSession.mto) {
                const mto = ExpertLLMFactory.createExpertLLM(retrievedTrainingSession.mto)
                return TrainingSessionFactory.createTrainingSession({
                    id: retrievedTrainingSession.id,
                    attributes: {
                        ...retrievedTrainingSession,
                        mto
                    }
                })
            } else{
                return TrainingSessionFactory.createTrainingSession({
                    id: retrievedTrainingSession.id,
                    attributes: {
                        ...retrievedTrainingSession.attributes
                    }
                })
            }
        } catch (error) {
            console.error("Error creating training session:", error);
            return { success: false, error: error.message };
        }
    }

    static async updateTrainingSession(trainingSession) {
        try {
            console.log("UPDATING Training Session:", trainingSession);

            const updatedTrainingSession = await datacenterService.updateObject('training-sessions', trainingSession.id + "?populate=mto,experts", trainingSession);
            const experts = updatedTrainingSession.data.attributes.experts.data.map(
                (expert) => ExpertFactory.createExpert(expert)
            );
            if(updatedTrainingSession.data.attributes.mto.data != null) {
                const mto = ExpertLLMFactory.createExpertLLM(updatedTrainingSession.data.attributes.mto.data)

                return TrainingSessionFactory.createTrainingSession({
                    id: updatedTrainingSession.data.id,
                    attributes: {
                        ...updatedTrainingSession.data.attributes,
                        mto,
                        experts
                    }
                })
            } else{
                return TrainingSessionFactory.createTrainingSession({
                    id: updatedTrainingSession.data.id,
                    attributes: {
                        ...updatedTrainingSession.data.attributes,
                        experts
                    }
                })
            }
        } catch (error) {
            console.error("Error updating training Session:", error);
            return { success: false, error: error.message };
        }
    }

    static async deleteTrainingSession(id) {
        try {
            const response = await datacenterService.deleteObject('training-sessions', id);
            return { success: true, data: response };
        } catch (error) {
            console.error("Error deleting training Session:", error);
            return { success: false, error: error.message };
        }
    }

}

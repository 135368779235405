<script setup>
import { defineComponent } from 'vue';

defineComponent({
  components: {
  }
});
</script>

<template>
  <div>
    <h2>Workflow Editor Test</h2>

  </div>
</template>

<style scoped>
h2 {
  text-align: center;
  margin-top: 20px;
}
</style>

const { VUE_APP_DATACENTER_URL } = process.env;


export class ApiService {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }

    async request(endpoint, method = 'POST', body = null, headers = {}) {
        try {
            // Determine whether the payload is FormData
            const isFormData = body?.payload?.data instanceof FormData;
            console.log("isFormData", isFormData);

            // Customize headers and body based on payload type
            let requestHeaders = { ...headers };
            let requestBody = body;

            if (isFormData) {
                this.baseURL = VUE_APP_DATACENTER_URL + "/api/upload";
                delete requestHeaders['Content-Type'];

                // If it's FormData, use the FormData directly
                requestBody = body?.payload?.data;
            } else if (body) {
                // For non-FormData, use JSON
                requestHeaders['Content-Type'] = 'application/json';
                requestBody = JSON.stringify(body);
            }

            const response = await fetch(`${this.baseURL}${endpoint}`, {
                method,
                credentials: 'include',
                headers: requestHeaders,
                body: requestBody,
            });

            console.log("Raw response:", response);

            if (!response.ok) {
                const error = await response.json().catch(() => ({}));
                console.log(`Request failed with status ${response.status}:`, error);
                console.error(`Request failed with status ${response.status}:`, error);
                throw new Error(error.message || 'An error occurred during the request.');
            }

            const responseData = await response.json().catch(() => null);
            console.log("Parsed JSON Response:", responseData);

            return responseData;
        } catch (error) {
            console.error('API Request Error:', error.message);
            throw error;
        }
    }
}

<template>
  <div class="container mt-5">
    <!-- Welcoming Lottie Animation -->
    <Socials />
    <div class="animation-container d-none">
      <Vue3Lottie :animationData="welcomeAnimation" :height="300" :width="300" />

    </div>
    <div v-if="experts.length === 0" class="container p-0 text-center">
      <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-info p-0">
        <div class="col-12 col-md-6">
          <div class="alert-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <img
                class="w-75 me-3 mb-0"
                src="@/assets/img/illustrations/boy-dynamic-gradient.png"
                alt="logo"
          />
        </div>
        <div class="col-12 col-md-6">
          <h1 style="color: white">Welcome to the Hub {{user.name}}!</h1>

          <h2 style="color: white">Start with creating you first expert!</h2>
          <p>
            Step into a world where Virtual Experts—digital personas crafted with cutting-edge AI technology—stand ready to engage, guide, and enlighten. These experts simulate real-life interactions, leveraging artificial intelligence and machine learning to provide tailored advice and solutions, enhancing your experience with precision and innovation.
          </p>

        </div>
        <div class="d-flex justify-content-around p-0">
          <a href="https://b-bot-hub.notion.site/What-is-a-Virtual-Expert-Persona-Actor-c11b27e4b8974f9dacfde9ea0abca3ca?pvs=74" class="btn btn-primary w-100 m-0">
            More Information
          </a>
          <material-button
              variant="gradient"
              :color="color"
              fullWidth @click="redirectToExperts"
          >
            Go to Experts
          </material-button>
        </div>
      </div>

    </div>

    <!-- Update Announcements -->
    <div id="update-announcements" class="update-announcements mt-5">
      <h1 class="card p-2 text-center mb-4">Welcome back, {{ user.name }}!</h1>


      <div class="card">
        <h5 class="text-center mt-3">Version: CBV-3.2</h5>
        <div class="card-body">
          <p class="card-text">
            Ready to bring your virtual experts to life? Start creating personalized AI-driven Experts tailored to your needs and revolutionize how you interact with digital personas!
          </p>

          <material-button
              class="mt-3 mb-3"
              @click="redirectToExperts"
              variant="fill"
              :color="color"
              fullWidth
          >
            Go to Experts
          </material-button>
          <p class="card-text">
            Need assistance or have an idea to improve the platform? Explore our resources or cast your vote to help shape the future of B-Bot!
          </p>

          <material-button
              href="https://docs.b-bot.space"
              class="mb-3"
              variant="fill"
              :color="color"
              fullWidth
          >
            Documentation
          </material-button>

          <material-button
              href="https://bbothub.featurebase.app"
              class="mb-3"
              variant="fill"
              :color="color"
              fullWidth
          >
            Upvote for your Favorite Feature
          </material-button>
        </div>
      </div>
      <h2 class="card p-4 text-center mb-4 mt-4">🚀 What's New on B-Bot Hub! 🚀</h2>

      <timeline-list class="mt-3" title="Roadmap">
        <DocumentationLink
            class="float-end position-sticky z-index-5"
            description="Learn more about how to use this feature in our documentation."
            docLink="https://docs.b-bot.space"
        />
        <timeline-item
            color="success"
            :icon="{ component: 'start', class: 'text-white text-sm' }"
            title="CBV1"
            date-time="22 DEC 7:20 PM"
            description="The beggining, complete overhaul of the whole Platform"
        />
        <TimelineItem
            color="success"
            :icon="{ component: 'tune', class: 'text-white text-sm' }"
            title="CBV2"
            date-time="21 DEC 11 PM"
            description="Added Fine tuning and Experts App Connections"
        />
        <TimelineItem
            color="success"
            :icon="{ component: 'code', class: 'text-white text-sm' }"
            title="CBV3"
            date-time="21 DEC 9:34 PM"
            description="Added the Functionality to call the Api"
        />
        <TimelineItem
            color="success"
            :icon="{ component: 'code', class: 'text-white text-sm' }"
            title="CBV3.1"
            date-time="21 DEC 9:34 PM"
            description="Added more Apps and the Dashboard"
        />
        <TimelineItem
            color="success"
            :icon="{ component: 'code', class: 'text-white text-sm' }"
            title="CBV3.2"
            date-time="21 DEC 9:34 PM"
            description="Adding a new type of app and better UX"
        />
        <TimelineItem
            color="info"
            :icon="{ component: 'code', class: 'text-white text-sm' }"
            title="CBV3.3"
            line="true"
            date-time="21 DEC 9:34 PM"
            description="B-Bot ME and Coorperations"
        />

        <TimelineItem
            color="info"
            :icon="{ component: 'code', class: 'text-white text-sm' }"
            title="OBV1"
            date-time="21 DEC 9:34 PM"
            description="First Open Beta Update! We are officially going public"
        />
      </timeline-list>


    </div>

    <!-- Blog Articles -->
    <div id="blog-articles" class="blog-articles mt-5">
      <h2 class="card p-2 text-center mb-4">Blog Articles</h2>
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Design_ohne_Titel_17.png?v=1705508450&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Navigating the Auth0 Odyssey: Adding Social Logins to B-Bot's Arsenal</h5>
              <p class="card-text">The Epic Quest of Integrating Social Logins into B-Bot
                Hey there, fellow code warriors and digital explorers! Today, I'm pulling back the curtain on our epic quest to integrate Auth0 into the B-Bot Hub—this time, with a twist...</p>
            </div>
            <material-button
                @click="navigateUrl('https://b-bot.ch/blogs/developer-blog/navigating-the-auth0-odyssey-adding-social-logins-to-b-bots-arsenal')"
                class="mb-0"
                variant="fill"
                :color="color"
                fullWidth
            >
              Read More
            </material-button>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Let_s_9.png?v=1705594568&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Unveiling the News Page: Your Portal to Everything B-Bot</h5>
              <p class="card-text">Welcome to the News Page on the B-Bot Hub
                Hey there, fellow innovators and digital explorers! As part of our ongoing mission to enhance the B-Bot experience, we're excited to take you behind the scenes of our latest endeavor - the creation of the News Page on the B-Bot Hub...</p>
            </div>

            <material-button
                @click="navigateUrl('https://b-bot.ch/blogs/developer-blog/unveiling-the-news-page-your-portal-to-everything-b-bot')"
                class="mb-0"
                variant="fill"
                :color="color"
                fullWidth
            >
              Read More
            </material-button>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card" style="width: 100%;">
            <img src="https://b-bot.ch/cdn/shop/files/Let_s_11.png?v=1706039760&width=750" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Launching the B-Bot Development Blog: A Behind-the-Scenes Journey into the Hub's Creation</h5>
              <p class="card-text">Welcome to the B-Bot Development Blog
                We are thrilled to announce the launch of the B-Bot Development Blog, a new series dedicated to unveiling the intricacies and groundbreaking work behind the development of the B-Bot Hub...</p>
            </div>
            <material-button
                @click="navigateUrl('https://b-bot.ch/blogs/news/launching-the-b-bot-development-blog-a-behind-the-scenes-journey-into-the-hubs-creation')"
                class="mb-0"
                variant="fill"
                :color="color"
                fullWidth
            >
              Read More
            </material-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import welcomeAnimation from '@/assets/img/illustrations/welcomeAnimation.json';
import {mapMutations, mapState} from "vuex";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import DocumentationLink from "./components/DocumentationLink.vue";
import Socials from "./components/Socials.vue";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  components: {
    MaterialButton,
    DocumentationLink,
    TimelineList,
    TimelineItem,
    Socials
  },
  data() {
    return {
      welcomeAnimation,
    }
  },
  mounted() {
    this.showEveryDisplay();
  },
  computed: {
    ...mapState(['experts', 'color', 'user']),
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    navigateUrl(url) {
      console.log("Navigating to:", url); // Log the URL to make sure it's a string
      if (typeof url !== "string") {
        console.error("Invalid URL:", url);
        return;
      }

      if (url.startsWith("http")) {
        window.location.href = url; // External link
      } else {
        this.$router.push(url); // Internal Vue route
      }
    },
    redirectToExperts() {
      // Redirect to the /experts route
      this.$router.push('/experts');
    },
  },
};
</script>

<style>
/* Styling for your components */
.animation-container {
  display: flex;
  justify-content: center;
}
.update-announcements, .blog-articles {
  text-align: left;
}
.alert-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 1rem;
}
.alert{
  color: white !important;
}
</style>

import { AbilityRepository } from "@/classes/expert/ability/AbilityRepository";

export default class AbilityService {
    // Fetch all abilities
    static async getAllAbilities() {
        return await AbilityRepository.fetchAbilities();
    }

    // Save (create or update) an ability
    static async saveAbility(ability) {
        console.log("SAVE ABILITY: ", ability);
        if(!ability.id){
            return await AbilityRepository.createAbility(ability);
        } else {
            return await AbilityRepository.updateAbility(ability);
        }
    }

    // Delete an ability by ID
    static async deleteAbility(id) {
        return await AbilityRepository.deleteAbility(id);
    }

    // Clone an ability
    static async cloneAbility(ability) {
        return await ability.clone(); // Delegates the logic to the model's `clone` method
    }

    // Update ability attributes
    static async updateAbilityAttributes(ability) {
        return await AbilityRepository.updateAbility(ability);
    }

    // Fetch a specific ability by ID
    static async getAbilityById(id) {
        return await AbilityRepository.fetchAbilityById(id);
    }
}

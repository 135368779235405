import { ApiService } from './ApiService';

export class DatacenterService extends ApiService {
    constructor() {
        super('/.netlify/functions/datacenter');
    }

    /**
     * Fetch objects of a specific type.
     * @param {string} objectType - The type of object to fetch (e.g., "experts").
     * @param {string} params - Query parameters (e.g., "?populate=*&publicationState=preview").
     * @returns {Promise<Object>} - The fetched data.
     */
    async fetchObjects(objectType, params = '') {
        return this.request('', 'POST', {
            callname: `${objectType}${params}`,
            action: 'GET',
        });
    }

    /**
     * Create a new object.
     * @param {string} objectType - The type of object to create (e.g., "experts").
     * @param {Object} objectData - The data for the new object.
     * @returns {Promise<Object>} - The created object.
     */
    async createObject(objectType, objectData) {
        return await this.request('', 'POST', {
            callname: objectType,
            action: 'POST',
            payload: {data: objectData},
        });
    }

    /**
     * Update an existing object.
     * @param {string} objectType - The type of object to update.
     * @param {string} objectId - The ID of the object to update.
     * @param {Object} updatedData - The updated data for the object.
     * @returns {Promise<Object>} - The updated object.
     */
    async updateObject(objectType, objectId, updatedData) {
        let data;
        if(updatedData.attributes){
            data = updatedData.attributes;
        } else{
            data = updatedData
        }
        return this.request('', 'POST', {
            callname: `${objectType}/${objectId}`,
            action: 'PUT',
            payload: { data: data },
        });
    }

    /**
     * Delete an object.
     * @param {string} objectType - The type of object to delete.
     * @param {string} objectId - The ID of the object to delete.
     * @returns {Promise<void>} - Confirmation of deletion.
     */
    async deleteObject(objectType, objectId) {
        return this.request('', 'POST', {
            callname: `${objectType}/${objectId}`,
            action: 'DELETE',
        });
    }


    /**
     * Upload a file to the datacenter.
     * @param {File} file - The file to upload.
     * @param {Object} metaData - Metadata about the upload (e.g., ref, refId, field).
     * @returns {Promise<Object>} - The response data from the upload.
     */
    async uploadFile(file, metaData = {}) {
        const formData = new FormData();
        formData.append('files', file);
        const accessToken = await this.getCookie('datacenterAccessToken'); // Implement getCookie to extract cookie value

        // Append additional metadata
        if (metaData.ref) formData.append('ref', metaData.ref);
        if (metaData.refId) formData.append('refId', metaData.refId);
        if (metaData.field) formData.append('field', metaData.field);

        return this.request('', 'POST', {
            callname: `upload`,
            action: 'POST',
            payload: { data: formData },
        }, {
            'Authorization': `Bearer ${accessToken}`
        });
    }

    async getCookie(name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
}

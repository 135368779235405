<template>
  <div>


    <div class="container card p-2 text-center mb-4">
      <h2>Edit Situations</h2>
      <p>You have to add atleast 10 Situations with atleast 2 steps each</p>
      <button @click="$emit('add-card', 'top')" class="btn btn-primary mb-3 w-100">Add New Situation on Top</button>
    </div>
    <div v-for="(card, cardIndex) in cards" :key="cardIndex" class="card mb-5">
      <div class="card-header bg-gradient-success text-white">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="card-title mb-0 text-white">Situation {{ cardIndex + 1 }}</h5>
          <div class="btn-group">
            <button class="show-more" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="material-icons-round opacity-10 fs-5 text-white">more_vert</i>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click.prevent="duplicateCard(cardIndex)">Duplicate</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="deleteCard(cardIndex)">Delete</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body p-1">

        <div v-for="(message, messageIndex) in card.messages" :key="messageIndex">


          <div class="">


            <div class="accordion" id="thoughtsAccordion">
              <div v-for="(thought, thoughtIndex) in message.thoughts" :key="thoughtIndex" class="accordion-item">
                <h2 class="accordion-header d-flex align-items-center" :id="'heading' + cardIndex + '-' + messageIndex + '-' + thoughtIndex">
                  <material-button
                      @click="deleteThought(thought)"
                      class="btn btn-danger btn-sm m-0"
                      variant="gradient"
                      color="danger"
                  >
                    <i class="material-icons-round opacity-10 fs-5">delete</i>

                  </material-button>
                  <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + cardIndex + '-' + messageIndex + '-' + thoughtIndex"
                      :aria-expanded="thoughtIndex === 0 ? 'true' : 'false'"
                      :aria-controls="'collapse' + cardIndex + '-' + messageIndex + '-' + thoughtIndex"
                  >
                    Thought: {{ formatToolName(thought.tool) }}
                  </button>
                </h2>
                <div :id="'collapse' + cardIndex + '-' + messageIndex + '-' + thoughtIndex" class="accordion-collapse collapse" :class="{ show: thoughtIndex === 0 }" data-bs-parent="#thoughtsAccordion">
                  <div class="accordion-body p-0">
                    <div>
                      <h3 class="p-3 text-center">Inputs</h3>
                      <div v-if="thought.tool_input">
                        <div v-for="(value, key) in thought.tool_input" :key="key" class="mb-3">
                          <label class="form-label">{{ key }}</label>
                          <template v-if="value !== null && value !== undefined">
                            <v-md-editor
                                v-if="typeof value === 'string'"
                                mode="edit"
                                :key="`thought-input-${cardIndex}-${messageIndex}-${thoughtIndex}-${key}`"
                                left-toolbar="undo redo clear"
                                right-toolbar="fullscreen"
                                v-model="thought.tool_input[key]"
                                class="form-control"
                                rows="2"
                                @copy-code-success="handleCopyCodeSuccess"
                            ></v-md-editor>
                            <div v-else class="form-control">{{ value }}</div>
                          </template>
                          <p v-else class="text-muted">No value provided</p>
                        </div>
                      </div>
                      <div v-else>
                        <p class="text-center">No input data available.</p>
                      </div>
                      <h3 class="p-3 text-center">Outputs</h3>

                      <div v-if="thought?.tool_output?.length > 0">
                        <div v-for="(output) in thought.tool_output" :key="output.key">
                          <!-- Display the key as a label -->
                          <label class="form-label mt-2">{{ output.key }}</label>

                          <!-- Handle single string -->
                          <div v-if="output.type === 'string'">
                            <v-md-editor
                                mode="edit"
                                left-toolbar="undo redo clear"
                                right-toolbar="fullscreen"
                                v-model="output.content"
                                :key="`thought-output-${cardIndex}-${messageIndex}-${thoughtIndex}`"

                                class="form-control mt-2"
                                rows="2"
                                @copy-code-success="handleCopyCodeSuccess"
                            ></v-md-editor>
                          </div>

                          <!-- Handle object with potential URL -->
                          <div v-if="output.type === 'object'">
                            <v-md-editor
                                v-if="output.content"
                                mode="edit"
                                left-toolbar="undo redo clear"
                                right-toolbar="fullscreen"
                                v-model="output.content"
                                class="form-control mt-2"
                                rows="2"
                                @copy-code-success="handleCopyCodeSuccess"
                            ></v-md-editor>
                            <input
                                v-if="output.url"
                                type="text"
                                :value="output.url"
                                class="form-control mt-2"
                                disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p class="text-center">No output data available.</p>
                      </div>

                    </div>
                    <material-button @click="runTool(thought, thought.tool_input, thought.tool)" class="btn btn-primary w-100 mb-0">
                      <i
                          v-if="thought.isLoading"
                          class="fas fa-spinner text-secondary fa-spin white position-absolute"
                          style="color:white !important; left: 15px; top: 15px"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="action.tooltip"
                      ></i>
                      Run
                    </material-button>

                  </div>
                </div>
              </div>
            </div>
            <chat-message
                :key="cardIndex + '-' + messageIndex"
                :id="cardIndex + '-' + messageIndex"
                :role="message.role"
                :senderId="message.senderId"
                :sender="message.role"
                :senderName="message.role"
                :text="message.content"
                :thoughts="message.thoughts"
                :timestamp="message.timestamp"
                :qaMarkingActivated="false"
                :possibleToEdit="true"
                :isAbleToOpenActionModel="true"
                :isAbleToRemoveMessage="true"
                :isAbleToggleRole="true"
                :isAbleToMoveMessages="true"
                @open-action-modal="openActionModal(message, messageIndex)"
                @remove-message="removeMessage(card, messageIndex)"
                @toggle-role="toggleRole(message)"
                @move-message-up="moveMessage(card, messageIndex, 'up')"
                @move-message-down="moveMessage(card, messageIndex, 'down')"
                @update:text="message.content = $event"
                profilePictureUrl="https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg"
            />
          </div>

        </div>
        <div class="d-flex justify-content-between align-items-center mb-3 ">
          <button @click="addMessage(card, 'user')" class="btn btn-secondary btn-sm me-2 w-100">Add Human Message</button>
          <button @click="addMessage(card, 'assistant')" class="btn btn-secondary btn-sm w-100">Add AI Message</button>
        </div>
      </div>
    </div>
    <div class="container card p-2 text-center mb-4">
      <button @click="$emit('add-card', 'bottom')" class="btn btn-primary mt-3 w-100">Add New Situation on Bottom</button>
      <button
          ref="saveButton"
          @click="showSaveNotification()"
          :disabled="!isSaveEnabled"
          class="btn btn-success mt-3 w-100"
      >Proceed</button>
      <div class="accordion mt-3" id="trainingInstructionsAccordion">
        <!-- Why At Least 10 Cards -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="whyTenCardsHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#whyTenCardsCollapse" aria-expanded="false" aria-controls="whyTenCardsCollapse">
              Why At Least 10 Cards?
            </button>
          </h2>
          <div id="whyTenCardsCollapse" class="accordion-collapse collapse" aria-labelledby="whyTenCardsHeading">
            <div class="accordion-body">
              <p>The requirement for a minimum of 10 cards ensures a diverse range of scenarios and responses are covered during the training phase. This diversity is crucial for the AI to learn the nuances of different interactions, making it more versatile and capable of handling a wider array of user queries and actions effectively.</p>
            </div>
          </div>
        </div>

        <!-- How Cards Instruct the AI -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="howCardsInstructHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#howCardsInstructCollapse" aria-expanded="false" aria-controls="howCardsInstructCollapse">
              How Cards Instruct the AI
            </button>
          </h2>
          <div id="howCardsInstructCollapse" class="accordion-collapse collapse" aria-labelledby="howCardsInstructHeading">
            <div class="accordion-body">
              <p>Each card is designed to simulate a specific interaction or conversation sequence between the user and the AI. By carefully crafting these cards with varied dialogue and scenarios, you effectively guide the AI in understanding how it should respond to similar situations in real-world applications. This methodical approach aids in creating a more intuitive and contextually aware AI.</p>
            </div>
          </div>
        </div>

        <!-- Why This Training Method Makes Sense -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="whyTrainingMethodHeading">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#whyTrainingMethodCollapse" aria-expanded="false" aria-controls="whyTrainingMethodCollapse">
              Why This Training Method Makes Sense
            </button>
          </h2>
          <div id="whyTrainingMethodCollapse" class="accordion-collapse collapse" aria-labelledby="whyTrainingMethodHeading">
            <div class="accordion-body">
              <p>This training method mirrors the way humans learn from experience. Just as we understand and adapt to new situations by analyzing examples, the AI uses the scenarios depicted in the cards to learn how to act in comparable circumstances. This experiential learning process enables the AI to develop a more natural and human-like way of interacting, making it better suited for engaging with users in a realistic manner.</p>
            </div>
          </div>
        </div>


      </div>
    </div>

    <VueFinalModal
        v-model="showActionModal"
        classes="modal-container"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
        @before-close="onBeforeClose"
    >
        <div class="modal-header">
          <h5 class="modal-title">Select Action</h5>
          <button type="button" class="btn-close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div>
            <div v-for="(action, index) in filteredActions" :key="index">
              <a href="#" class="btn btn-primary w-100" @click.prevent="selectAction(action)">{{ action.name }}</a>
            </div>
          </div>
        </div>
    </VueFinalModal>

  </div>
</template>




<script>
import {mapActions, mapState} from "vuex";
import setTooltip from "@/assets/js/tooltip.js";
import {VueFinalModal} from "vue-final-modal";
import ChatMessage from "./ChatMessage.vue";
import MaterialButton from "../../components/MaterialButton.vue";

export default {
  components: {
    MaterialButton,
    ChatMessage,
    VueFinalModal,
  },
  data() {
    return {
      snackbar: null,
      notificationMessage: '',
      showActionModal: false,
      selectedMessage: null,
      selectedMessageIndex: null,
      availableActions: [
        { name: 'Notion Retriever', tool: 'notion_retriever', input: { query:"Informationen zu...", number: "343" } },
        { name: 'Tavily Search', tool: 'tavily_search_results_json', input: {query:""} },
        { name: 'Wolfram Alpha', tool: 'wolfram_alpha', input: {input:"4*4"} },
      ]
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapState('experts',['selectedExpert', 'experts']),
    filteredActions() {
      // Check if selectedExpert and its attributes are defined
      const apps = this.selectedExpert?.attributes?.apps || {};


      // Check if the selected expert has the notion_connector app
      const hasNotionConnector = apps.notion_connector;

      // Filter out notion_retriever from availableActions if notion_connector exists
      const filteredAvailableActions = this.availableActions.filter(action => {
        if (hasNotionConnector && action.tool === 'notion_retriever') {
          return false;
        }
        return true;
      });

      // Create expert team actions
      const expertTeamActions = this.selectedExpert?.attributes?.experts?.data?.map(member => {
        const sanitizedProfession = this.sanitizeString(member.attributes.profession);
        return {
          name: member.attributes.name,
          tool: `Consultation_of_Expert_${sanitizedProfession}_${member.id}`, // Use the sanitized profession
          input: { query: "Wie macht man... ?" } // Use the correct input property from the team member
        };
      }) || [];


      // Add the assistant apps the user added; the app should have an attribute name tool_type, which should be === 'openai_assistant'
      const assistantApps = Object.values(apps)
          .filter(app => app.tool_type === 'openai_asst')
          .map(app => ({
            name: app.name,
            tool: app.name,
            input: { query: "Wie macht man... ?" } // Example input; you can customize this as needed
          }));


      // Return the combined list of actions
      return [...filteredAvailableActions, ...expertTeamActions, ...assistantApps];
    },
    userProfilePic() {
      // Hier den Pfad zum Benutzeravatar einfügen oder logisch abrufen
      return this.user.profilePicture || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg';
    },
    expertProfilePic() {
      const profilePicture = this.selectedExpert?.attributes?.profile_picture?.attributes?.formats?.thumbnail?.url;
      return profilePicture
          ? profilePicture
          : 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg';
    },
    isSaveEnabled() {
      return this.cards.length >= 10 && this.cards.every(card => card.messages.length >= 2);
    },
  },
  props: {
    cards: Array,
  },
  mounted() {
    setTooltip()
  },
  methods: {
    ...mapActions(['callApiFunction']),

    async runTool(thought, toolInput, toolName) {
      thought.isLoading = true;
      console.log("this.selectedExpert",this.selectedExpert)
      const expertIds = this.selectedExpert.attributes.experts.map(expert => expert.id);
      const filteredExperts = this.experts.filter(expert => expertIds.includes(expert.id));

      const payload = {
        tool_name: toolName.replace(/\s+/g, ''),
        tool_input: JSON.parse(JSON.stringify(toolInput)), // Deep clone
        user_id: this.user.sub,
        exp_id: this.selectedExpert.id.toString(),
        team: filteredExperts.map(expert => ({
          id: expert.id,
          ...JSON.parse(JSON.stringify(expert.attributes)) // Deep clone
        })),
        apps: JSON.parse(JSON.stringify(this.selectedExpert.attributes.apps)) || {}, // Deep clone
        tool_activation: {
          "tavily_search": true,
          "wolfram_alpha": true,
          "document_retriever": false,
          "notion_connector": true
        },
        private: true
      };

      try {
        const response = await this.callApiFunction({ functionName: 'tool_use', payload });
        if (response && response.result) {
          let result = response.result;

          // Handle response based on the tool name and expected format
          if (toolName.startsWith("Consultation") && typeof result === 'object' && !Array.isArray(result)) {
            result = { ...result }; // Create a new object
            delete result.input;
            delete result.chat_history;
          }

          let newToolOutput;

          if (Array.isArray(result)) {
            newToolOutput = result.map((item, index) => ({
              type: 'object',
              key: `output${index + 1}`,
              content: item.content,
              url: item.url
            }));
          } else if (typeof result === 'string') {
            newToolOutput = [{
              type: 'string',
              content: result
            }];
          } else if (typeof result === 'object') {
            newToolOutput = Object.entries(result).map(([key, value]) => ({
              type: 'object',
              key: key,
              content: typeof value === 'string' ? value : JSON.stringify(value),
              url: typeof value === 'string' && value.startsWith('http') ? value : null
            }));
          } else {
            console.warn('Unexpected result format.');
            newToolOutput = [];
          }

          // In Vue 3, direct assignment is reactive
          thought.tool_output = newToolOutput;
        } else {
          console.warn('No valid response data found.');
          thought.tool_output = [];
        }
      } catch (error) {
        console.error('Failed to execute tool:', error);
        thought.tool_output = [];
      } finally {
        thought.isLoading = false; // Set loading state back to false
      }

    },
    sanitizeString(str, maxLength = 30) {
      // Replace spaces with underscores and remove non-alphanumeric characters except underscores
      const sanitized = str
          .replace(/ /g, '') // Replace spaces with underscores
          .replace(/[^a-zA-Z0-9_]/g, ''); // Remove non-alphanumeric characters except underscores

      // Limit the length of the string
      return sanitized.length > maxLength ? sanitized.substring(0, maxLength) : sanitized;
    },
    formatToolName(toolName) {
      if (!toolName) return '';
      // Split the string by underscores and capitalize each segment
      return toolName.split('_').map((word, index) => {
        // Optionally, you can keep some words in lowercase if needed
        if (word.toLowerCase() === 'json' && index !== 0) {
          return word.toUpperCase();  // Example to handle 'json' specifically
        }
        // Capitalize the first letter of each word
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' '); // Join them back into a single string with spaces
    },
    determineProperty(input) {
      if (!input || typeof input !== 'object') {
        console.error("Input is not an object:", input);
        return null; // Early exit if input is not an object
      }

      // Extract the first key from the input object that is not undefined or null
      const keys = Object.keys(input);
      const validKey = keys.find(key => input[key] !== undefined && input[key] !== null);
      if (validKey) {
        return validKey;
      }

      console.warn("No valid properties found in input");
      return null; // Return null if no suitable property is found
    },
    addCard() {
      this.$emit('add-card');
    },
    addMessage(card, role) {
      card.messages.push({ role: role, content: 'New message' });
    },
    openActionModal(message, index) {
      this.selectedMessage = message;
      this.selectedMessageIndex = index;
      this.showActionModal = true;
    },
    selectAction(action) {
      if (this.selectedMessage && this.selectedMessageIndex !== null) {
        if (!Array.isArray(this.selectedMessage.thoughts)) {
          this.selectedMessage.thoughts = [];
        }
        this.selectedMessage.thoughts.push({
          tool: action.tool,
          tool_input: JSON.parse(JSON.stringify(action.input)), // Deep clone
          tool_output: { output: "It is..." },
        });
        this.showActionModal = false;
      }
    },
    onBeforeClose() {
      this.showActionModal = false;
    },
    removeMessage(card, index) {
      card.messages.splice(index, 1);
    },
    toggleRole(message) {
      message.role = message.role === 'user' ? 'assistant' : 'user';
    },

    copyContent(content) {
      navigator.clipboard.writeText(content).then(() => {
        alert('Content copied to clipboard');
      });
    },
    duplicateCard(cardIndex) {
      this.$emit('duplicate-card', cardIndex);
    },
    deleteCard(cardIndex) {
      this.$emit('delete-card', cardIndex);
    },
    moveMessage(card, index, direction) {
      if (direction === 'up' && index > 0) {
        const messageToMove = card.messages.splice(index, 1)[0];
        card.messages.splice(index - 1, 0, messageToMove);
      } else if (direction === 'down' && index < card.messages.length - 1) {
        const messageToMove = card.messages.splice(index, 1)[0];
        card.messages.splice(index + 1, 0, messageToMove);
      }
    },
    showSaveNotification() {
      this.notificationMessage = this.isSaveEnabled ? 'Ready to save!' : `Add ${10 - this.cards.length > 0 ? 10 - this.cards.length + ' more cards' : 'more messages to your cards'} to proceed.`;
      this.snackbar = 'save';
      this.$emit('save-cards')
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    handleCopyCodeSuccess(code) {
      console.log(code);
    },
    deleteThought(thought) {
      // Find the card and message that contains the thought
      for (let card of this.cards) {
        for (let message of card.messages) {
          // Check if message.thoughts is defined and is an array
          if (Array.isArray(message.thoughts)) {
            const thoughtIndex = message.thoughts.indexOf(thought);
            if (thoughtIndex !== -1) {
              // Remove the thought from the message's thoughts
              message.thoughts.splice(thoughtIndex, 1);
              return; // Exit once the thought is deleted
            }
          } else {
            console.warn('message.thoughts is not an array:', message.thoughts);
          }
        }
      }
    }
  },
};
</script>



<style scoped>
.avatar{
  height: 0px !important;
}
.card-body {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-top: 1rem;
}

.btn-sm {
  margin-top: 1rem;
}

.btn-secondary {
  margin-right: 0.5rem; /* Sorgt für Abstand zwischen den Schaltflächen */
}

.chat-message {
  display: flex;
  width: 100%; /* Stellt sicher, dass die Chat-Nachrichten die volle Breite einnehmen */
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-content {
  flex-grow: 1; /* Ermöglicht der Nachricht, den verfügbaren Platz zu nutzen */
  margin-left: 10px;
  position: relative; /* Für Positionierung des Dropdown-Menüs */
}

.form-control {
  width: 100%; /* Stellt sicher, dass das Textfeld die volle Breite innerhalb der Nachricht einnimmt */
  border: none;
  box-shadow: none;
  background-color: #f8f9fa; /* Hintergrundfarbe des Textfelds */
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-group {
  margin-left: 10px; /* Abstand zwischen Nachrichteninhalt und Aktionsbutton */
}
.message-sender {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.show-more{
  background: transparent;
  outline: none;
  border: none;
}
</style>

<template>
  <div class="alert alert-success mt-5" role="alert">
    <h4 class="alert-heading">Well done!</h4>
    <p>Aww yeah, you successfully created {{(bbotMe ? "your B-Bot ME" : "another Virtual Expert." )}}.
      Click on the Create button to create finish the process.
    </p>
    <hr>
    <p class="mb-0">More Informations about Virtual Experts, visit our Documentation.</p>
  </div>
</template>

<script>


export default {
  props: {
    bbotMe:{
      type: Boolean,
      required: true,
    }
  },
  methods: {

  },
};
</script>

<style scoped>

</style>

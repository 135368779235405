<template>
  <div class="py-4 container-fluid pt-0">
    <div class="d-flex justify-content-center align-items-center mb-3 d-none">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/chart-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Monitor Experts</h2>
      <DocumentationLink
          popoverTitle="Dashboard"
          class="z-index-5"
          description="Learn more about the Dashboard"
          docLink="https://docs.b-bot.space/essentials/dashboard"
      />
    </div>
    <div class="row mb-5 mt-0">
      <div class="col-12 mt-0 ">
        <div class="card overflow-hidden">
          <div class="">
            <div
                class="icon icon-lg icon-shape shadow text-center border-radius-5px mt-n4 position-absolute d-none"
                style="top: 0; left: 15px;"
                :class="`bg-gradient-success shadow-success`"
            >
              <i
                  class="material-icons opacity-10"
                  aria-hidden="true"
              >chat</i
              >
            </div>
          </div>
          <p class="p-3">Total token budget:
            {{ user.hub_user_metadata.token_budget === -1 ? '∞' : (user.hub_user_metadata.token_budget || 0) }}
          </p>
          <p class="p-3">Tokens used this month: {{ user.hub_user_metadata.total_used_tokens || 0}}</p>

          <!-- Check if the token budget is not -1 before showing the progress and percentage -->
          <p class="p-3" v-if="user.hub_user_metadata.token_budget !== -1">
            You have used: {{ (100 / user.hub_user_metadata.token_budget * user.hub_user_metadata.total_used_tokens).toFixed(2) }}%
          </p>

          <material-progress
              v-if="user.hub_user_metadata.token_budget !== -1"
              color="success"
              variant="gradient"
              :percentage="(100 / user.hub_user_metadata.token_budget * user.hub_user_metadata.total_used_tokens)"
          />

        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card
              :title="{ text: 'Total Tokens used', value: user.hub_user_metadata.total_used_tokens || 0 }"
              detail=""
              :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Trained Tokens', value: user?.hub_user_metadata?.trained_used_tokens || 0 }"
              detail=""
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Messages', value: totalMessages || 0 }"
              detail=""
              :icon="{
                name: 'person',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Chat Tokens', value: user?.hub_user_metadata?.chat_used_tokens || 0 }"
              detail=""
              :icon="{
                name: 'chat',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div
              v-for="(expert, index) in user.hub_user_metadata?.experts || {}"
              :key="index"
              class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4"
          >
            <mini-statistics-card
                :title="{ text: getExpertFromIdinView(index)?.attributes?.name || 'Deleted' +' Expert', value: getExpertLevel(expert, levels) }"
                :detail="getExpertDetail(expert)"
                :background-url="getExpertFromIdinView(index)?.attributes?.profile_picture?.attributes?.formats?.small?.url"
                :icon="{
                name: (getExpertFromIdinView(index)?.attributes?.name ? 'star' : 'delete'),
                color: 'text-white',
                background: (getExpertFromIdinView(index)?.attributes?.name ? 'success' : 'danger'),
            }"
            />

            <!-- Progress bar for expert's next level -->
            <div class="mt-0 mb-5 card">
              <p class="text-center">Remaining Tokens: {{ tokensNeededForNextLevel(expert) }}</p>
              <material-progress
                  color="info"
                  variant="gradient"
                  :percentage="getProgressPercentage(expert)"
              />
              <span class="text-center">{{ getProgressPercentage(expert).toFixed(2) }}%</span>
            </div>
          </div>
        </div>
        <div class="row mt-4 d-none">
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card
              title="Experts Views"
              subtitle="Performance of your Experts"
              update="2 days ago"
            >
              <reports-bar-chart
                :chart="{
                  labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                  datasets: {
                    label: 'Tokens',
                    data: [5000, 2000, 1000, 2200, 5000, 1000, 4000],
                  },
                }"
              />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 col-md-6 mt-4">
            <chart-holder-card
              title="Daily Tokens"
              subtitle="(<span class='font-weight-bolder'>+15%</span>) increase in today token use."
              update="updated 4 min ago"
              color="success"
            >
              <reports-line-chart
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: {
                    label: 'Tokens',
                    data: [500, 400, 3000, 3200, 5000, 3500, 2000, 2300, 5000],
                  },
                }"
              />
            </chart-holder-card>
          </div>
          <div class="col-lg-4 mt-4">
            <chart-holder-card
              title="Completed Trainings"
              subtitle="Completed Trainings in Hub"
              update="just updated"
              color="dark"
            >
              <reports-line-chart
                id="tasks-chart"
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: {
                    label: 'Tokens',
                    data: [500, 400, 3000, 2200, 5000, 2500, 4000, 2300, 5000],
                  },
                }"
              />
            </chart-holder-card>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-none">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <project-card
          title="Projects"
          description="<i class='fa fa-check text-info' aria-hidden='true'></i> <span class='font-weight-bold ms-1'>30 done</span> this month"
          :headers="['Companies', 'Members', 'Budget', 'Progress']"
          :projects="[
            {
              logo: logoXD,
              title: 'Material XD Material XD Version',
              members: [team1, team2, team3, team4],
              budget: '$14,000',
              progress: { percentage: 60, color: 'info' },
            },
            {
              logo: logoAtlassian,
              title: 'Add Progress Track',
              members: [team2, team4],
              budget: '$3,000',
              progress: { percentage: 10, color: 'info' },
            },
            {
              logo: logoSlack,
              title: 'Fix Platform Errors',
              members: [team3, team1],
              budget: 'Not set',
              progress: { percentage: 100, color: 'success' },
            },
            {
              logo: logoSpotify,
              title: 'Launch our Mobile App',
              members: [team4, team3, team4, team1],
              budget: '$20,500',
              progress: { percentage: 100, color: 'success' },
            },
            {
              logo: logoJira,
              title: 'Add the New Pricing Page',
              members: [team4],
              budget: '$500',
              progress: { percentage: 25, color: 'info' },
            },
            {
              logo: logoJira,
              title: 'Redesign New Online Shop',
              members: [team1, team4],
              budget: '$2,000',
              progress: { percentage: 40, color: 'info' },
            },
          ]"
        />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            :icon="{
              component: 'notifications',
              class: 'text-success',
            }"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            :icon="{
              component: 'code',
              class: 'text-danger',
            }"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            :icon="{
              component: 'shopping_cart',
              class: 'text-info',
            }"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            :icon="{
              component: 'credit_card',
              class: 'text-warning',
            }"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            :icon="{
              component: 'vpn_key',
              class: 'text-primary',
            }"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
            class="pb-1"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>
<script>

import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "@/examples/Charts/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import ProjectCard from "./components/ProjectCard.vue";
import TimelineList from "@/examples/Cards/TimelineList.vue";
import TimelineItem from "@/examples/Cards/TimelineItem.vue";
import logoXD from "@/assets/img/small-logos/logo-xd.svg";
import logoAtlassian from "@/assets/img/small-logos/logo-atlassian.svg";
import logoSlack from "@/assets/img/small-logos/logo-slack.svg";
import logoSpotify from "@/assets/img/small-logos/logo-spotify.svg";
import logoJira from "@/assets/img/small-logos/logo-jira.svg";
import logoInvision from "@/assets/img/small-logos/logo-invision.svg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {mapActions, mapMutations, mapState} from "vuex";
import MaterialProgress from "@/components/MaterialProgress.vue";
import DocumentationLink from "./components/DocumentationLink.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      logoXD,
      team1,
      team2,
      team3,
      team4,
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      thisMonthStatistics: null,
      levels: []
    };
  },
  components: {
    DocumentationLink,
    MaterialProgress,
    ChartHolderCard,
    ReportsBarChart,
    ReportsLineChart,
    MiniStatisticsCard,
    ProjectCard,
    TimelineList,
    TimelineItem,
  },
  computed: {
    ...mapState(["statistics", "trainingSessions", "user", "userChats", "experts"]),
    totalMessages() {
      return this.userChats.reduce((sum, chat) => {
        const messageCount = Object.keys(chat.messages || {}).length;
        return sum + messageCount;
      }, 0);
    },
  },
  created() {
    console.log(this.experts)
    this.generateLevels();
  },
  methods: {
    ...mapMutations(["showEveryDisplay"]),
    ...mapActions(["fetchStatistics", "getUser"]),
    getExpertFromIdinView(id) {
      const expertId = Number(id); // Ensure the input ID is a number

      const expert = this.experts.experts.find(expert => {

        // Ensure expert.id is a number for a valid comparison
        const expertIdNum = Number(expert.id);

        return expertIdNum === expertId; // Compare as numbers
      });

      return {id: expert?.id, attributes: expert?.attributes }; // Return the found expert or undefined
    },
    getExpertDetail(expert) {
      const chatTokens = expert.chat_used_tokens || 0;
      const trainedTokens = expert.trained_used_tokens || 0;
      const totalTokens = expert.total_used_tokens || 0;

      return `
      <div>
        <span class='text-success text-sm font-weight-bolder'>${totalTokens}</span> total tokens used<br>
        <span class='text-info text-sm'>${chatTokens}</span> chat tokens used<br>
        <span class='text-warning text-sm'>${trainedTokens}</span> trained tokens used
      </div>
    `;
    },

    getExpertLevel(expert, levels) {
      const totalUsedTokens = expert.total_used_tokens || 0;

      // Find the highest level that the expert has reached or surpassed
      for (let i = levels.length - 1; i >= 0; i--) {
        if (totalUsedTokens >= levels[i].totalTokens) {
          return levels[i].level;
        }
      }

      return 1; // Default to level 1 if no match is found
    },

    tokensNeededForNextLevel(expert) {
      const currentLevel = this.getExpertLevel(expert, this.levels);
      const nextLevelIndex = this.levels.findIndex(level => level.level === currentLevel + 1);


      // Validate if the next level exists
      if (nextLevelIndex === -1) {
        return 0;
      }

      const nextLevel = this.levels[nextLevelIndex];
      const totalUsedTokens = expert.total_used_tokens || 0;
      const tokensRequiredForNextLevel = nextLevel.totalTokens;


      // Calculate tokens needed to reach the next level
      const tokensNeeded = Math.max(0, tokensRequiredForNextLevel - totalUsedTokens);

      return tokensNeeded;
    },

    getProgressPercentage(expert) {
      const currentLevel = this.getExpertLevel(expert, this.levels);
      const currentLevelIndex = this.levels.findIndex(level => level.level === currentLevel);
      const nextLevelIndex = currentLevelIndex + 1;

      if (nextLevelIndex >= this.levels.length) {
        return 100; // Max level reached
      }

      const totalUsedTokens = expert.total_used_tokens || 0;
      const currentLevelTokens = this.levels[currentLevelIndex].totalTokens;
      const nextLevelTokens = this.levels[nextLevelIndex].totalTokens;

      if (totalUsedTokens >= nextLevelTokens) {
        return 100; // Already reached or surpassed next level
      }

      const progressRange = nextLevelTokens - currentLevelTokens;
      const progressMade = totalUsedTokens - currentLevelTokens;

      return Math.min(100, Math.max(0, (progressMade / progressRange) * 100));
    },

    generateLevels() {
      const levels = [];
      let chatTokens = 1000; // Starting threshold for chat tokens
      let trainedTokens = 500; // Starting threshold for trained tokens
      let totalTokens = 1500; // Starting threshold for total tokens

      for (let i = 1; i <= 50; i++) {
        levels.push({
          level: i,
          chatTokens: Math.floor(chatTokens),
          trainedTokens: Math.floor(trainedTokens),
          totalTokens: Math.floor(totalTokens)
        });

        // Increase difficulty exponentially
        chatTokens *= 1.2;
        trainedTokens *= 1.3;
        totalTokens = chatTokens + trainedTokens;
      }

      this.levels = levels;
    },

  },
  mounted() {
    console.log(this.user)
    this.$store.state.showNavbar = true; // Hide the navbar when the component is mounted
    this.showEveryDisplay();
    this.getUser();
  }
};
</script>

<style scoped>
.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
</style>
import { TemplateRepository } from "@/classes/expert/template/TemplateRepository";

export default class TemplateService {
    // Fetch all abilities
    static async getAllTemplates() {
        return await TemplateRepository.fetchTemplates();
    }

    // Save (create or update) an ability
    static async saveTemplate(template) {
        console.log("SAVE TEMPLATE: ", template);
        if(!template.id){
            return await TemplateRepository.createTemplate(template);
        } else {
            return await TemplateRepository.updateTemplate(template);
        }
    }

    // Delete an ability by ID
    static async deleteTemplate(id) {
        return await TemplateRepository.deleteTemplate(id);
    }

    // Clone an ability
    static async cloneTemplate(template) {
        return await template.clone(); // Delegates the logic to the model's `clone` method
    }

    // Update ability attributes
    static async updateTemplateAttributes(template) {
        return await TemplateRepository.updateTemplate(template);
    }

    // Fetch a specific ability by ID
    static async getTemplateById(id) {
        return await TemplateRepository.fetchTemplateById(id);
    }
}

import {DatacenterService} from "@/classes/services/DatacenterService";
import TemplateModel from "@/classes/expert/template/TemplateModel";
import {TemplateFactory} from "@/classes/expert/template/TemplateFactory";

export const datacenterService = new DatacenterService();

export class TemplateRepository {
    static async fetchTemplates() {
        try {
            const rawTemplates = await datacenterService.fetchObjects('templates');
            return rawTemplates.data.map(
                (rawTemplate) =>
                    TemplateFactory.createTemplate(rawTemplate)
            );
        } catch (error) {
            console.error("Error fetching Templates:", error);
            return { success: false, error: error.message };
        }
    }

    static async fetchTemplateById(id) {
        try {
            const templateData = await datacenterService.fetchObjects('templates',"/"+id);
            return new TemplateModel(
                templateData.id,
                templateData.attributes
            );
        } catch (error) {
            console.error("Error fetching template:", error);
            return { success: false, error: error.message };
        }
    }

    static async createTemplate(templateData) {
        try {
            const savedTemplate = await datacenterService.createObject('templates', templateData);
            console.log(savedTemplate)
            const createdTemplate = TemplateFactory.createTemplate({id: savedTemplate.id, attributes: savedTemplate})
            console.log("createdTemplate", createdTemplate)
            return createdTemplate
        } catch (error) {
            console.error("Error creating template:", error);
            return { success: false, error: error.message };
        }
    }

    static async updateTemplate(template) {
        try {
            console.log("UPDATING template:", template);
            // Use DatacenterService to update the ability
            const updatedTemplate = await datacenterService.updateObject('templates', template.id, template);
            return TemplateFactory.createTemplate(updatedTemplate.data)
        } catch (error) {
            console.error("Error updating ability:", error);
            return { success: false, error: error.message };
        }
    }

    static async deleteTemplate(id) {
        try {
            const response = await datacenterService.deleteObject('templates', id);
            return { success: true, data: response };
        } catch (error) {
            console.error("Error deleting template:", error);
            return { success: false, error: error.message };
        }
    }

    static async cloneTemplate(id) {
        try {
            const template = await this.fetchTemplateById(id);
            if (template.success === false) return template;

            const clonedTemplate = await template.clone(); // Clone logic is in the model
            return { success: true, data: clonedTemplate };
        } catch (error) {
            console.error("Error cloning ability:", error);
            return { success: false, error: error.message };
        }
    }
}

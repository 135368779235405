<template>
  <Renderer class="renderer-container" ref="renderer" style="position: fixed; width: 100vw; height: 100vh" antialias resize shadow>
    <Camera :position="{ z: 10 }" />
    <Scene :background="backgroundColor">
      <PointLight :color="colorLight" :position="{ y: 50, z: 50 }" />
      <PointLight :color="colorLight" :intensity="1.5" :position="{ x: 0, y: 0, z: -50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: 0, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: -50, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: -25, z: 50 }"/>
      <PointLight :color="colorLight" :intensity="1" :position="{ x: 0, y: 25, z: 50 }"/>
      <GltfModel
          ref="model"
          src="https://cdn.shopify.com/3d/models/5fe48776ceb5844d/logo.glb"
      >
      </GltfModel>
    </Scene>
    <EffectComposer>
      <RenderPass />
      <FXAAPass />
      <TiltShiftPass :gradient-radius="100" :start="{ x: 0, y: 20 }" :end="{ x: 100, y: 50 }" />
    </EffectComposer>
  </Renderer>
  <Loading v-if="isBackgroundLoading" />
  <sidenav
      :custom_class="color"
      :class="[isRTL ? 'fixed-end' : 'fixed-start']"
      v-if="showSidenav"
  />
  <main
      class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <navbar
        :class="[isNavFixed ? navbarFixed : '']"
        :color="isAbsolute ? 'text-white opacity-8' : ''"
        :minNav="navbarMinimize"
        v-if="showNavbar"
    />
    <router-view />
    <configurator
        :toggle="toggleConfigurator"
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>

<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import Loading from "./views/components/Loading.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import { throttle } from 'lodash';
import {activateDarkMode, deactivateDarkMode} from "@/assets/js/dark-mode";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    Loading
  },
  data() {
    return {
      tiltRadius: 10000,
      tiltY: 100,
      colorLight: "#ffffff",
      backgroundColor: "#ffffff",
      isScrollListenerAdded: false,
    };
  },
  computed: {
    ...mapState([
      "isDarkMode",
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "user",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "isBackgroundLoading"
    ]),
    ...mapState('experts', ['experts', 'selectedExpert']),
  },
  mounted() {
    console.log(`




                                   @@@@@@@@@@@@
                                   @@@@@@@@@@@@@@@@@@
                                             @@@@@@@@@@@
                                                  @@@@@@@@
                                                     @@@@@@@@
                                   @@@@@@@@@@@@@@       @@@@@@@
                              @@@@@@@@@     @@@@@@@@@     @@@@@@
                           @@@@@@          @@@      @@@@@    @@@@
                         @@@@       @@@@@@@@@@@@@@@@@   @@@@  @@@@
                       @@@@     @@@@@                @@@@ @@@@@@@@@
                      @@@     @@@@            @@         @@@@@@@@@@@
                     @@      @@@        @@@@@@@@@@@@@@@    @@@@@@@@@@
                    @@      @@       @@@@              @@@@  @@@@@@@@
                   @@      @@       @@                    @@@@@@@@@@@
                  @@@     @@      @@                        @@@@@@@@@
                  @@     @@      @@        @@@@@@@@@@@@@@     @@@@@@@@
                  @@    @@      @@        @@@@@@@@@@@@@@@@@    @@@@@@
                  @@    @@      @@         @@@@@@@@@@@@@@@@@   @@@@@@
      @           @@    @@      @                       @@@@@  @@@@@@
      @           @@@    @@     @           @@@@@@@@@@@@@@@@   @@@@@@
       @           @@    @@     @@        @@@@@@@@@@@@@@@@@@@  @@@@@
        @          @@@    @@    @@@        @@@@@@@@@@@@@@@@@@@@@@@@
        @@          @@@   @@@    @@@                      @@@@@@@@
          @          @@@    @@@   @@@                     @@@@@@@
           @@          @@@   @@@@   @@@@                 @@@@@@@
            @@@         @@@@@   @@@@    @@@@@@     @@@@@@@@@@@
              @@@          @@@@@    @@@@@@@@@@@@@@@@@@@@@@@@@
                @@@@           @@@@@@@@@ @@@@@@@@@@@@@@@@@
                   @@@@@           @@@@@@@@@@@@@@@@@@@@@
                      @@@@@@@@              @@@@@@@@
                            @@@@@@@@@@@@@@@@@@@@


Welcome to the B-Bot Hub, if you need any Guidance on how to build Apps with B-Bot please visit https://docs.b-bot.space

                                                                           `);
    const model = this.$refs.model;
    const renderer = this.$refs.renderer;
    const rendererElement = renderer.$el || renderer;
    if (rendererElement) {
      rendererElement.previousElementSibling.style.position = 'fixed';
      renderer.three.setSize(window.innerWidth, window.innerHeight);
    }
    model.position.z = 0;
    model.position.y = 0;
    model.position.x = 0;
    model.rotation.y = 30;


    this.handleScrollThrottled = throttle(this.handleScroll, 27);

    renderer.onBeforeRender(() => {
      if (model) {
        const mainElement = document.querySelector('main');
        if (model && !this.isScrollListenerAdded) { // Only add if not already added
          mainElement.addEventListener('scroll', () => {
            this.handleScrollThrottled(model);
          });
        }
        // Remove existing event listener before adding a new one


        if (this.isDarkMode) {
          this.backgroundColor = "#000000";
          if (this.color === "dark") {
            this.colorLight = "#1b1b1b";
          } else if(this.color==="primary"){
            this.colorLight = "#220027";

          }else if(this.color==="info"){
            this.colorLight = "#000822";

          }else if(this.color==="success"){
            this.colorLight = "#062e00";

          }else if(this.color==="warning"){
            this.colorLight = "#3e3300";

          } else {
            this.colorLight = "#250000";
          }

        } else {
          this.backgroundColor = "#ffffff";
          if (this.color === "dark") {
            this.colorLight = "#e6e6e6";
          } else if(this.color==="primary"){
            this.colorLight = "#cbbfcd";

          }else if(this.color==="info"){
            this.colorLight = "#b8bbc6";

          }else if(this.color==="success"){
            this.colorLight = "#b9c8b7";

          }else if(this.color==="warning"){
            this.colorLight = "#c5c2b8";

          } else {
            this.colorLight = "#cab9b9";
          }
        }
        //mainElement.removeEventListener('scroll', this.handleScrollThrottled);

        this.isScrollListenerAdded = true; // Mark listener as added


      }
    });
    window.addEventListener('resize', this.onWindowResize);
    this.setRendererSize();
    if (this.isDarkMode) {
      activateDarkMode();
    } else {
      deactivateDarkMode();
    }

  },
  async created() {
    await this.fetchExperts();
    console.log("SelectedExpert On Startup:", this.selectedExpert)
    console.log("Experts On Startup:", this.experts)
    console.log("get User in Created", this.user)
  },
  methods: {
    ...mapActions('experts', ['fetchExperts']),
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    handleScroll(model) {
      if (model) {
        const mainElement = document.querySelector('main');
        const scrollY = mainElement.scrollTop;
        const rotationSpeed = 0.002;
        model.o3d.rotation.x = scrollY * rotationSpeed;
      }
    },
    onWindowResize() {
      this.setRendererSize();
    },
    setRendererSize() {
      const renderer = this.$refs.renderer;
      if (renderer) {
        renderer.three.setSize(window.innerWidth, window.innerHeight);
        // Removed rendererElement as it is not used
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    const mainElement = document.querySelector('main');
    if (mainElement && this.handleScrollThrottled) {
      mainElement.removeEventListener('scroll', this.handleScrollThrottled);
    }
  }
};

</script>

<style scoped>
.show {
  display: block !important;
}

.renderer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Ensure it's behind other content */
}
</style>

<template>
  <div v-if="isSelectionModeEnabled" class="selection-mode-controls p-3">
    <material-input
        id="title"
        class="mb-3"
        type="text"
        label="Title"
        name="title"
        size="lg"
        v-model="qaTitle"
    />
    <material-input
        id="tags"
        class="mb-3"
        type="tags"
        label="Tags"
        name="tags"
        size="lg"
        v-model="tags"
    />
    <button class="btn btn-primary w-50" @click="toggleSelectionMode(false)">Cancel Selection</button>
    <button class="btn btn-success w-50" @click="saveSelectedMessages">Save</button>
  </div>
  <div class="messages-container">
    <chat-message
        v-for="(message, index) in messagesWithProfilePictures"
        :key="message.timestamp"
        :id="index + '-message'"
        :role="message.role"
        :senderId="message.senderId"
        :sender="message.sender"
        :senderName="message.senderName"
        :text="message.text"
        :thoughts="message.thoughts"
        :timestamp="message.timestamp"
        :profilePictureUrl="message.profilePictureUrl"
        :isSelected="selectedMessages.includes(message.id)"
        :is-selection-mode="isSelectionModeEnabled"
        @start-qa-marking="handleStartQAMarking"
        @select-message="handleSelectMessage"
        :possibleToEdit="false"
    />

  </div>
</template>

<script>
import ChatMessage from './ChatMessage.vue';
import {mapActions, mapState} from "vuex";
import MaterialInput from "../../components/MaterialInput.vue";

export default {
  name: 'Messages',
  components: {
    MaterialInput,
    ChatMessage,
  },
  data() {
    return {
      selectedMessages: [],
      isSelectionModeEnabled: false,
      qaTitle: "",
      tags: ""
    };
  },
  watch: {
    incomingMessage(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Call the method directly instead of using computed
        this.handleIncomingMessage(newVal);
      }
    }
  },
  computed: {
    ...mapState(['user', 'incomingMessage']),
    ...mapState('experts',['selectedExpert']),
    messagesWithProfilePictures() {
      const messagesArray = this.messages ? Object.values(this.messages) : [];
      return messagesArray.map((message, index) => {
        let profilePictureUrl = 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'; // Default image
        message.role = message.sender;

        if (message.sender === "user") {
          message.senderName = this.user.name;
        }
        if (message.sender === "assistant") {
          message.senderName = this.selectedExpert.attributes.name;
        }
        if (message.sender === 'user' && this.user && this.user.picture) {
          profilePictureUrl = this.user.picture;
        } else if (message.sender !== 'user' && this.selectedExpert && this.selectedExpert.attributes.profile_picture?.attributes?.formats?.thumbnail?.url) {
          profilePictureUrl = this.selectedExpert.attributes.profile_picture.attributes.formats.thumbnail.url;
        }

        const id = `message-${index}`;
        return { ...message, profilePictureUrl, id };
      });
    }
  },
  props: {
    messages: Array, // Accept messages as a prop from ChatWindow
    incomingMessage: String,
  },
  methods: {
    ...mapActions('training',['saveQAPairs']),
    toggleSelectionMode(enable) {
      this.isSelectionModeEnabled = enable;
      if (!enable) {
        this.selectedMessages = []; // Clear selection when exiting mode
      }
    },
    handleMessageClick(message) {
      if (this.isSelectionModeEnabled) {
        this.handleSelectMessage(message);
      }
    },
    handleSelectMessage(message) {
      const index = this.selectedMessages.findIndex(msg => msg.id === message.id);

      if (index > -1) {
        this.selectedMessages.splice(index, 1); // Remove message if already selected
      } else {
        this.selectedMessages.push(message); // Add message if not already selected
      }

      // Sort the selectedMessages by their id
      this.selectedMessages.sort((a, b) => {
        const idA = parseInt(a.id.split('-')[1], 10);
        const idB = parseInt(b.id.split('-')[1], 10);
        return idA - idB;
      });
    },
    handleStartQAMarking() {
      this.selectedMessages.splice(0, this.selectedMessages.length);
      if (!this.isSelectionModeEnabled) {
        this.toggleSelectionMode(true); // Automatically enter selection mode if not already in it
      }
    },
    handleIncomingMessage(newMessage) {
      // Handle the new incoming message here
      console.log("New incoming message:", newMessage);
      // Additional logic can be added here if needed
    },
    saveSelectedMessages() {
      if (this.selectedMessages.length === 0) {
        console.error("No messages selected. Please select at least one message.");
        alert("Please select at least one message before saving.");
        return; // Stop execution if no messages are selected
      }

      // Sort selected messages by their original order
      const sortedSelectedMessages = this.selectedMessages.sort((a, b) => a.id - b.id);
      console.log("sortedSelectedMessages", sortedSelectedMessages);

      // Map over the sorted messages to structure them correctly
      const structuredMessages = sortedSelectedMessages.map(message => {
        let structuredMessage = {
          id: message.id,
          content: message.text,
          role: message.sender,
          timestamp: message.timestamp
        };

        // Check for thoughts
        if (Array.isArray(message.thoughts) && message.thoughts.length > 0) {
          console.log("message.thoughts", message.thoughts);

          structuredMessage.thoughts = message.thoughts.map(thoughtPair => {
            // Verify thoughtPair has the correct structure
            if (thoughtPair && typeof thoughtPair === 'object' && thoughtPair[0] && thoughtPair[1]) {
              const thought = thoughtPair[0];
              const thoughtOutput = thoughtPair[1];

              let structuredThought = {
                log: thought.log,
                tool: thought.tool,
                type: thought.type,
                tool_input: thought.tool_input,
                tool_call_id: thought.tool_call_id,
                tool_output: thoughtOutput
              };

              // Map over message_log if it exists
              if (Array.isArray(thought.message_log) && thought.message_log.length > 0) {
                structuredThought.message_log = thought.message_log.map(log => ({
                  type: log.type,
                  content: log.content,
                  example: log.example,
                  additional_kwargs: log.additional_kwargs
                }));
              }

              console.log("structuredThought:", structuredThought);
              return structuredThought;
            } else {
              console.warn("thoughtPair ist nicht wie erwartet:", thoughtPair);
              return null; // oder einen passenden Standardwert
            }
          }).filter(Boolean); // Entfernt `null`-Werte
        }

        return structuredMessage;
      });

      // Dispatch Vuex action 'saveQAPairs' with structuredMessages
      this.saveQAPairs({
        title: this.qaTitle,
        tags: this.tags,
        qaPairs: structuredMessages
      })
          .then(() => {
            // Reset or handle UI changes post save
            this.toggleSelectionMode(false);
          })
          .catch(error => {
            console.error("Error saving QA pairs:", error);
          });
    }
  },
};
</script>

<style>
.messages-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

/* Example styling for individual messages */
.chat-message {
  margin-bottom: 10px;
}
.selection-mode-controls {
  background: white;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 50;
}
</style>

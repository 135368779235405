import TrainingSessionService from "@/classes/services/TrainingSessionService";

export class TrainingSession {
    constructor({id = null, attributes}) {
        this.id = id;
        this.attributes = attributes;
    }

    // Method to update ability attributes
    update({ attributes }) {
        console.log("attributes: ", attributes);
        if (attributes) {
            this.attributes = attributes;
        } else {
            console.error("Invalid attributes passed to update:", attributes);
        }
    }

    // Save method to persist the ability data (create or update)
    async save() {
        return await TrainingSessionService.saveTrainingSession(this);
    }
}

export default TrainingSession;
